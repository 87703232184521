/**
 * Stichwort-Service
 * Attila Németh, UBG
 * 13.09.2018
 */
 
import { Injectable } from '@angular/core';

import { ContentaService } from './contenta.service';

@Injectable({
  providedIn: 'root'
})
export class StichwortService {

  constructor(private contenta: ContentaService) {
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getJsonApi('taxonomy_term/stichwort?sort=name').then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  add(stichwort: string) {
    let promise = new Promise((resolve, reject) => {
      let data = {
        type: 'taxonomy_term--stichwort',
        attributes: {
          name: stichwort,
        }
      };
      this.contenta.post('api/taxonomy_term/stichwort', {
        data: data
      }).then(() => {
        resolve();
      }).catch((error) => {
        reject();
      });
    });
    return promise;
  }
  
  delete(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/taxonomy_term/stichwort/' + id).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}
