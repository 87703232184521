/**
 * CDU-Gliederung
 * 
 * Attila Németh, UBG
 * 06.03.2019
 */
 
export class CduGliederung {
  
  id: string
  attributes: {
    name: string
    level: string
    ansprechpartner: string
    ort: string
    plz: string
    strasse: string
    e_mail: Array<string>
    telefon: Array<string>
    id: string
  }
  type: string
  
}