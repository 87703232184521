/**
 * Schadenmeldung-Formularelement-Komponent
 * 
 * Attila Németh, UBG
 * 03.04.2020
 */
 
import {Component, OnInit, Input} from '@angular/core';

import {Schadenmeldung} from '../../../../../model/meldung';

@Component({
  selector: 'ubg-schadenmeldung-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class SchadenmeldungFormComponent {
  
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  meldungObject: Schadenmeldung = null
  @Input() title: string = ''
  
  constructor() {}
   
}