/**
 * Formular für die Gliederung
 * 
 * Attila Németh, UBG
 * 15.04.2020
 */
 
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap } from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {MatSnackBar} from '@angular/material';

import {Schadenmeldung} from '../../../model/meldung';
import {SchadenmeldungService} from '../../../services/meldung.service'; 
import {SparteService} from '../../../services/sparte.service';
import {Sparte} from '../../../model/sparte';

@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  
  meldung: Schadenmeldung = null
  meldungId: string = null
  loggedIn: boolean = false
  isLoggingIn: boolean = false
  isLoading: boolean = false
  password: string = null
  
  passwordForm: FormGroup = null;
  
  constructor(private route: ActivatedRoute,
                private snackbar: MatSnackBar,
                private service: SchadenmeldungService,
                private sparteService: SparteService) {}
  
  ngOnInit() {
    this.meldungId = this.route.snapshot.paramMap.get('uuid');
    this.passwordForm = new FormGroup({
      passwortControl: new FormControl('', [Validators.required]),
    });
  }
  
  public hasError = (controlName: string, errorName: string) => {
    return this.passwordForm.controls[controlName].hasError(errorName);
  }
  
  passwordFormSubmitted(event: {
    value: {
      passwortControl: string
    }
  }) {
    this.isLoggingIn = true;
    this.service.login(this.meldungId, event.value.passwortControl).then((response: any) => {
      this.loggedIn = true;
      this.isLoggingIn = false;
      this.password = event.value.passwortControl;
      this.loadMeldung();
    }).catch((error) => {
      this.snackbar.open('Die Anmeldung war nicht erfolgreich', null, {
        duration: 15000,
      });
      this.isLoggingIn = false;
      this.loggedIn = false;
    });
  }
  
  loadMeldung(): void {
    this.isLoading = true;
    this.service.getByToken(this.meldungId, this.password).then((response: Schadenmeldung) => {
      this.meldung = response;
      this.sparteService.get(this.meldung.relationships.sparte.data.id).then((response: Sparte) => {
        this.meldung.relationships.sparte.data = response;
      });
      this.isLoading = false;
    }).catch(() => {
      this.snackbar.open('Der Fall kann nicht geladen werden', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
  saveMeldung(): void {
    this.isLoading = true;
    this.service.updateByToken(this.meldung, this.password).then((response: Schadenmeldung) => {
      this.loadMeldung();
      this.isLoading = false;
    }).catch(() => {
      this.snackbar.open('Der Fall kann nicht gespeichert werden', null, {
        duration: 15000,
      });
      this.isLoading = false;
    });
  }
  
}