/**
 * UBG Left Column 
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ubg-right-column',
  templateUrl: './right_column.component.html',
})
export class UbgRightColumnComponent implements OnInit {
  
  ngOnInit() {
    
  }
  
}