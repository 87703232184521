/**
 * Dokumentdialog
 * Attila Németh, UBG
 * 02.11.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

import {UbgFileModule} from '../../modules/file/file.module';
import {UbgFile} from '../../modules/file/model/file';
import {ContentaService} from '../../services/contenta.service';

@Component({
  templateUrl: './form_dialog_dokument.html',
})
export class UbgFormDialogDokumentComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    files: UbgFile,
  },
  private contenta: ContentaService) {
  }
  
  uploadUrl: string
  httpAuthorization: string
  
  ngOnInit() {
    this.uploadUrl = this.contenta.getUploadPath('ubg_schadenmeldung', 'ubg_schadenmeldung', 'dokumente');
    this.getToken();
  }
  
  getToken() {
    this.contenta.getToken().then((response) => {
      this.httpAuthorization = 'Bearer ' + response;
      setTimeout(() => {
        this.getToken();
      }, 15000);
    }).catch((error) => {
      console.error(error);
      setTimeout(() => {
        this.getToken();
      }, 60000);
    });
  }
  
  disabled() {
    if (this.data.files === null || this.data.files === undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  
  completed(e:any) {
    this.data.files = e;
  }
  
}