/**
 * CDUplus-Anmeldung anwenden
 * 
 * Attila Németh
 * 18.12.2018
 */

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {MatSnackBar} from '@angular/material';

import { ContentaService } from '../../../../services/contenta.service';
import { ContentaUserService } from '../../services/contenta_user.service';
import {environment} from '../../../../../environments/environment';

@Component({
  template: '',
})
export class ContentaUserDialogCduplusComponent implements OnInit {
  
  constructor(private route: ActivatedRoute,
              private router: Router,
              private snackbar: MatSnackBar,
              private service: ContentaUserService,
              private contenta: ContentaService) {}
  
  ngOnInit() {
    this.route.queryParams.subscribe((params: {
      code: string
      state: string
    }) => {
      let cookies = document.cookie.split(';');
      let cookieData = {};
      for (let i in cookies) {
        cookieData = cookies[i].split('=');
        if (cookieData[0].trim() == 'cduplus_state' && cookieData[1] == params.state) {
          this.contenta.updateToken({
            code: params.code,
            cduplus_client: environment.cduplus.clientId,
            cduplus_secret: environment.cduplus.clientSecret,
            cduplus_scope: 'profile offline_access cduplus_auth openid email',
          }).then((response: {
            refresh_token: string,
          }) => {
            this.service.refreshLogin(response.refresh_token).then(() => {
              this.router.navigate(['']);
              this.snackbar.open('Sie haben sich angemeldet', '', {
                duration: 5000,
              });
            });
          }).catch((error) => {
            console.error(error);
            this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', '', {
              duration: 12000,
            });
          });
        }
      }
    });
  }
  
}