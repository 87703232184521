import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule }    from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from "@angular/flex-layout";
import {Nl2BrPipeModule} from 'nl2br-pipe';
import { NgxEditorModule } from 'ngx-editor';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';
import {CKEditorModule} from '@ckeditor/ckeditor5-angular';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

/* Material-Elemente */
import {MatNativeDateModule} from '@angular/material/';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDividerModule} from '@angular/material/divider';
import {MatTableModule} from '@angular/material/table';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatPaginatorIntl} from '@angular/material';
import {MatPaginatorIntlDe} from './translation/paginator';
import {EuroPipe} from './translation/euro';
import {DatumPipe} from './translation/datum';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MAT_DATE_LOCALE } from '@angular/material';
// npm i @angular/material-moment-adapter --save
// npm i moment@^2.18.1 --save
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

/* UBG-Material-Elemente */
import {UbgFabComponent} from './components/ubg_fab.component';
import {UbgDividerComponent} from './components/divider.component';
import {UbgTabHeaderComponent} from './components/tab/header.component';
import {UbgDatepickerCellComponent} from './components/datepickercell/datepickercell.component';

/* UBG-Komponente */
import { AppComponent } from './app.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {DashboardDefaultComponent} from './dashboard/default/dashboard.component';
import {DashboardBgsComponent} from './dashboard/bgs/dashboard.component';
//import {EditComponent} from './form/edit.component';
//import {AddComponent} from './form/add.component';
//import {AddComponent} from './pages/meldung/add.component';
//import {EditComponent} from './pages/meldung/edit.component';
import {EditComponent} from './components/meldung/edit/edit.component';
import {AddComponent} from './components/meldung/add/add.component';
import {SchadenmeldungComponent} from './components/meldung/meldung/meldung.component';
import {SchadenmeldungFormComponent} from './components/meldung/meldung/components/form/form.component';
import {SchadenmeldungOperationsComponent} from './components/meldung/meldung/components/operations/operations.component';
import {SchadenmeldungAktionenComponent} from './components/meldung/meldung/components/aktionen/aktionen.component';
import {NotizDialogComponent} from './components/meldung/meldung/components/aktionen/dialog/notiz/notiz.component';
import {NotizDialogAddComponent} from './components/meldung/meldung/components/aktionen/dialog/add/add.component';
import {SchadenmeldungDokumenteComponent} from './components/meldung/meldung/components/dokumente/dokumente.component';
import {DokumentAddDialogComponent} from './components/meldung/meldung/components/dokumente/dialog/add/add.component';
import {FormComponent} from './components/meldung/form/form.component';
import {SchadenmeldungFormHaftpflichtComponent} from './components/meldung/form/haftpflicht/haftpflicht.component';
import {SchadenmeldungFormElementAbsenderComponent} from './components/meldung/form/elements/absender/absender.component';
import {SchadenmeldungFormElementOperationsComponent} from './components/meldung/form/elements/operations/operations.component';
import {SchadenmeldungFormElementAnspruchstellerComponent} from './components/meldung/form/elements/anspruchsteller/anspruchsteller.component';
import {SchadenmeldungFormElementBeschreibungComponent} from './components/meldung/form/elements/beschreibung/beschreibung.component';
import {SchadenmeldungDatenblattComponent} from './components/meldung/meldung/components/datenblatt/datenblatt.component';
import {SchadenmeldungFormElementDokumenteComponent} from './components/meldung/form/elements/dokumente/dokumente.component';
import {MeldungSaveComponent} from './form/save.component';
//import {MeldungBgsComponent} from './form/bgs.component';
import {ListComponent} from './list/list.component';
import {UbgFormDialogNotizComponent} from './form/dialog/form_dialog_notiz';
import {UbgFormDialogDateiComponent} from './form/dialog/form_dialog_datei';
import {UbgFormDialogMailComponent} from './form/dialog/form_dialog_mail';
import {UbgFormDialogMailVersicherungComponent} from './form/dialog/form_dialog_mail_versicherung';
import {UbgFormDialogBriefComponent} from './form/dialog/form_dialog_brief';
import {UbgFormDialogDetailsComponent} from './form/dialog/form_dialog_details';
import {UbgFormDialogDokumentComponent} from './form/dialog/form_dialog_dokument';
import {VersicherungsFirmaListComponent} from './versicherungsfirma/list.component';
import {VersicherungsFirmaEditComponent} from './versicherungsfirma/edit.component';
import {VersicherungsFirmaAddComponent} from './versicherungsfirma/add.component';
import {VersicherungsVertragListComponent} from './versicherungsvertrag/list.component';
import {VersicherungsVertragAddComponent} from './versicherungsvertrag/add.component';
import {VersicherungsVertragEditComponent} from './versicherungsvertrag/edit.component';
import {StichwortListComponent} from './stichwort/list.component';
import {UbgDialogDeleteComponent} from './components/dialog_delete.component';
import {UbgDialogConfirmComponent} from './components/dialog_confirm.component';
import {UbgUserComponent} from './user/user.component';
import {TextblockListComponent} from './textblock/textblock.component';
import {UbgTextblockDialogComponent} from './textblock/dialog/textblock';
import {UbgGliederungComponent} from './components/gliederung/gliederung.component';
import {UbgEditorComponent} from './components/editor/editor.component';
import {SchadenDatenblattComponent} from './components/datenblatt/datenblatt.component';

/* UBG Pipes */
import {BytesPipe} from './pipes/byte';

/* UBG-Module */
import {ContentaUserModule} from './modules/contenta_user/contenta_user.module';
import {UbgFileModule} from './modules/file/file.module';
import {UbgLayoutModule} from './modules/layout/layout.module';
import {UbgSpinnerModule} from './modules/spinner/spinner.module';

import {DatepickerModule} from '../../projects/ubg/datepicker/src/lib/datepicker.module';
import {DrupalLongTextareaModule} from '../../projects/ubg/drupal-long-textarea/src/lib/drupal-long-textarea.module';
import { DashboardOverviewComponent } from './dashboard/bgs/elements/dashboard-overview/dashboard-overview.component';
import { SchadenDialogWarningComponent } from './components/dialog/warning/schaden-dialog-warning/schaden-dialog-warning.component';

const appRoutes: Routes = [
  {
    path: '',
    component: DashboardComponent
  },
  {
    path: 'add',
    component: AddComponent
  },
  {
    path: 'meldung/:uuid',
    component: EditComponent
  },
  {
    path: 'fall/:uuid/formular',
    component: FormComponent
  },
  {
    path: 'list',
    component: ListComponent,
  },
  // Versicherungsfirmen 
  {
    path: 'versicherung/firma',
    component: VersicherungsFirmaListComponent
  },
  {
    path: 'versicherung/firma/add',
    component: VersicherungsFirmaAddComponent
  },
  {
    path: 'versicherung/firma/:uuid',
    component: VersicherungsFirmaEditComponent
  },
  // Versicherungsverträge
  {
    path: 'versicherung/vertrag',
    component: VersicherungsVertragListComponent
  },
  {
    path: 'versicherung/vertrag/add',
    component: VersicherungsVertragAddComponent
  },
  {
    path: 'versicherung/vertrag/:uuid',
    component: VersicherungsVertragEditComponent
  },
  // Sonstige
  {
    path: 'stichwort',
    component: StichwortListComponent
  },
  {
    path: 'textblock',
    component: TextblockListComponent,
  },
  // Benutzer 
  {
    path: 'benutzerdaten',
    component: UbgUserComponent,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    DashboardDefaultComponent,
    DashboardBgsComponent,
    EditComponent,
    AddComponent,
    SchadenmeldungComponent,
    SchadenmeldungFormComponent,
    SchadenmeldungOperationsComponent,
    SchadenmeldungAktionenComponent,
    NotizDialogComponent,
    NotizDialogAddComponent,
    SchadenmeldungDokumenteComponent,
    DokumentAddDialogComponent,
    FormComponent,
    SchadenmeldungFormHaftpflichtComponent,
    SchadenmeldungFormElementAbsenderComponent,
    SchadenmeldungFormElementOperationsComponent,
    SchadenmeldungFormElementAnspruchstellerComponent,
    SchadenmeldungFormElementBeschreibungComponent,
    SchadenmeldungDatenblattComponent,
    SchadenmeldungFormElementDokumenteComponent,
    MeldungSaveComponent,
//    MeldungBgsComponent,
    ListComponent,
    UbgFormDialogNotizComponent,
    UbgFormDialogDateiComponent,
    UbgFormDialogMailComponent,
    UbgFormDialogMailVersicherungComponent,
    UbgFormDialogBriefComponent,
    UbgFormDialogDetailsComponent,
    UbgFormDialogDokumentComponent,
    VersicherungsFirmaListComponent,
    VersicherungsFirmaEditComponent,
    VersicherungsFirmaAddComponent,
    VersicherungsVertragListComponent,
    VersicherungsVertragAddComponent,
    VersicherungsVertragEditComponent,
    UbgFabComponent,
    UbgDividerComponent,
    StichwortListComponent,
    UbgDialogDeleteComponent,
    UbgDialogConfirmComponent,
    UbgTabHeaderComponent,
    EuroPipe,
    DatumPipe,
    UbgUserComponent,
    BytesPipe,
    UbgDatepickerCellComponent,
    TextblockListComponent,
    UbgTextblockDialogComponent,
    UbgGliederungComponent,
    UbgEditorComponent,
    SchadenDatenblattComponent,
    DashboardOverviewComponent,
    SchadenDialogWarningComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSnackBarModule,
    MatDividerModule,
    MatTableModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatDialogModule,
    MatPaginatorModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatMenuModule,
    MatProgressBarModule,
    MatTooltipModule,
    ContentaUserModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    Nl2BrPipeModule,
    NgxEditorModule,
    UbgFileModule,
    UbgLayoutModule,
    UbgSpinnerModule,
    CKEditorModule,
    DatepickerModule,
    DrupalLongTextareaModule,
  ],
  providers: [
    {
      provide: MAT_DATE_LOCALE, 
      useValue: 'de-DE'
    },
    { 
      provide: MatPaginatorIntl, 
      useClass: MatPaginatorIntlDe
    }
  ],
  entryComponents: [
    UbgDialogDeleteComponent,
    UbgDialogConfirmComponent,
    UbgFormDialogNotizComponent,
    UbgFormDialogDateiComponent,
    UbgFormDialogMailComponent,
    UbgFormDialogMailVersicherungComponent,
    UbgTextblockDialogComponent,
    UbgFormDialogBriefComponent,
    UbgFormDialogDetailsComponent,
    UbgFormDialogDokumentComponent,
    NotizDialogComponent,
    NotizDialogAddComponent,
    DokumentAddDialogComponent,
    SchadenDialogWarningComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
