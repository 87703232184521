/**
 * Textblock-Dialog
 * Attila Németh, UBG
 * 10.10.2018
 */
 
import { Component, Inject } from '@angular/core';
import {FormsModule} from "@angular/forms";
import { NgxEditorModule } from 'ngx-editor';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

import {Textblock} from '../../model/textblock';

@Component({
  templateUrl: './textblock.html',
})
export class UbgTextblockDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    textblock: Textblock
  }) {
  }
  
}