/**
 * Dashboard - Redaktion
 * Attila Németh, UBG
 * 24.09.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatSnackBar} from '@angular/material';

import {Schadenmeldung} from '../../model/meldung';
import {Notiz} from '../../model/notiz';

import {ContentaUserService} from '../../modules/contenta_user/services/contenta_user.service';
import {HeaderService} from '../../services/header.service';
import {SchadenmeldungService} from '../../services/meldung.service';
import {UbgDialogConfirmComponent} from '../../components/dialog_confirm.component';
import {NotizService} from '../../services/notiz.service';
import {Gliederung} from '../../model/gliederung';
import {GliederungService} from '../../services/gliederung.service';
import {Sparte} from '../../model/sparte';
import {SparteService} from '../../services/sparte.service';

@Component({
  selector: 'ubg-dashboard-bgs',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashoard.component.scss'],
})
export class DashboardBgsComponent implements OnInit {
  
  gliederungId: string
  datumVon: any
  datumBis: any
  meldungen: {
    all: Array<Schadenmeldung>
    filtered: Array<Schadenmeldung>
    displayed: Array<Schadenmeldung>
  }
  filter: {
    category: string
  }
  pager: {
    index: number
    length: number
  }
  columnsToDisplay = ['schadensnummer', 'sparte', 'gliederung', 'anspruchsteller', 'schadensdatum', 'open'];
  columnsWvToDisplay = ['schadensnummer', 'gliederung', 'title', 'wiedervorlage', 'open'];
  listTitle: string
  countWiedervorlage: number = 0;
  gliederungen: Array<Gliederung> = []
  wiedervorlage: Array<{
    meldung: Schadenmeldung
    notiz: Notiz
  }>
  sparten: Array<Sparte> = []
  isLoading: boolean = false

  constructor(private router: Router,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private user: ContentaUserService,
              private header: HeaderService,
              private service: SchadenmeldungService,
              private notizService: NotizService,
              private gliederung: GliederungService,
              private sparteService: SparteService) {
    this.meldungen = {
      all: [],
      filtered: [],
      displayed: [],
    };
    this.filter = {
      category: null
    }
  }
  
  ngOnInit() {
    this.pager = {
      index: 0,
      length: 25,
    };
    this.gliederungId = '';
    this.header.setTitle('Dashboard');
    this.gliederungId = '';
    /*
    this.listTitle = 'Offene Schäden';
    this.filter = {
      category: 'offen',
    }
    this.update();
    */
    this.loadGliederungen();
    this.loadSparten();
    this.categoryChange('open');
  }
  
  private loadSparten(): void {
    this.sparteService.getAll().then((response: Array<Sparte>) => {
      this.sparten = response;
    }).catch(() => {
      this.snackbar.open('Die Sparten können nicht geladen werden', null, {
        duration: 15000,
      });
    });
  }
  
  update() {
    this.meldungen = {
      all: [],
      filtered: [],
      displayed: [],
    };
    let filters: {[key: string]: {
      group?: {
        conjunction: string
      },
      condition?: {
        path: string
        value: string
        operator: string
        memberOf?: string
      }
    }} = {};
    if (this.gliederungId !== '') {
      filters.gliederung_referenz = {
        condition: {
          path: 'gliederung_referenz.id',
          value: this.gliederungId,
          operator: '=',
        },
      };
    }
    if (this.datumVon !== undefined && this.datumVon !== null) {
      filters.datumVon = {
        condition: {
          path: 'schadensdatum',
          value: this.datumVon.format('YYYY-MM-DD'),
          operator: '>=',
        },
      };
    }
    if (this.datumBis !== undefined && this.datumBis !== null) {
      filters.datumBis = {
        condition: {
          path: 'schadensdatum',
          value: this.datumBis.format('YYYY-MM-DD'),
          operator: '<=',
        }
      };
    }
    this.service.getFiltered(filters).then((response: Array<Schadenmeldung>) => {
      this.meldungen.all = response;
      this.applyFilters();
      this.applyPagination();
      this.loadNotizen();
    }).catch((error) => {
      console.warn(error);
//      this.update();
    });
  }
  
  reset() {
    this.gliederungId = '';
    this.datumVon = null;
    this.datumBis = null;
    this.update();
  }
  
  private applyFilters() {
    this.wiedervorlage = [];
    this.meldungen.filtered = [];
    for (let i in this.meldungen.all) {
      if (this.filter.category == 'offen' && this.meldungen.all[i].attributes.status == 0) {
        this.meldungen.filtered.push(this.meldungen.all[i]);
      }
      if (this.filter.category == 'neu') {
        if (this.meldungen.all[i].relationships.gesehen === undefined) {
          this.meldungen.filtered.push(this.meldungen.all[i]);
        }
        else {
          let g: boolean = false;
          for (let j in this.meldungen.all[i].relationships.gesehen.data) {
            if (this.meldungen.all[i].relationships.gesehen.data[j].id == this.user.user.id) {
              g = true;
              break;
            }
          }
          if (!g) {
            this.meldungen.filtered.push(this.meldungen.all[i]);
          }
        }
      }
      if (this.filter.category == 'wiedervorlage') {
        let n = new Date();
        for (let j in this.meldungen.all[i].relationships.notizen.data) {
          if (this.meldungen.all[i].relationships.notizen.data[j].attributes !== undefined 
          && this.meldungen.all[i].relationships.notizen.data[j].attributes.wiedervorlage !== undefined
          && this.meldungen.all[i].relationships.notizen.data[j].attributes.wiedervorlage !== null) {
            let d = new Date(this.meldungen.all[i].relationships.notizen.data[j].attributes.wiedervorlage);
            let e: Date;
            if (this.meldungen.all[i].relationships.notizen.data[j].attributes.wv_erledigt !== null) {
              e = new Date(this.meldungen.all[i].relationships.notizen.data[j].attributes.wv_erledigt);
            }
            if (this.meldungen.all[i].attributes.status === 0 && d <= n && e === undefined) {
              this.wiedervorlage.push({
                meldung: this.meldungen.all[i],
                notiz: this.meldungen.all[i].relationships.notizen.data[j],
              });
              //break;
            }
          }
        }
      }
    }
  }
  
  private applyPagination() {
    this.meldungen.displayed = this.meldungen.filtered.slice(this.pager.index * this.pager.length, (this.pager.index + 1) * this.pager.length)
  }
  
  pageEvent(event: {
    pageIndex: number,
    pageSize: number,
  }) {
    this.pager = {
      index: event.pageIndex,
      length: event.pageSize,
    };
    this.applyPagination();
  }
  
  openMeldung(meldung: Schadenmeldung) {
    this.router.navigate(['meldung/' + meldung.id]);
  }
  
  openMeldungTab(meldung: Schadenmeldung, tab: number) {
    this.router.navigate(['meldung/' + meldung.id], { queryParams: { tab: tab }});
  }
  
  countOffen() {
    let c = 0;
    for (let i in this.meldungen.all) {
      if (this.meldungen.all[i].attributes.status === 0) {
        c++;
      }
    }
    return c;
  }
  
  countNeu() {
    let c = 0;
    for (let i in this.meldungen.all) {
      if (this.meldungen.all[i].relationships.gesehen === undefined) {
        c++;
      }
      else {
        let g: boolean = false;
        for (let j in this.meldungen.all[i].relationships.gesehen.data) {
          if (this.meldungen.all[i].relationships.gesehen.data[j].id === this.user.user.id) {
            g = true;
          }
        }
        if (!g) {
          c++;
        }
      }
    }
    return c;
  }
  
  filterCategory(name: string) {
    switch(name) {
      case 'offen':
        this.listTitle = 'Offene Schäden';
        break;
      case 'neu':
        this.listTitle = 'Neue Schäden';
        break;
      case 'wiedervorlage':
        this.listTitle = 'Wiedervorlage';
        break;
      default:
        console.warn('Unbekannte Kategorie', name);
    }
    this.filter.category = name;
    this.applyFilters();
    this.applyPagination();
  }
  
  deleteMeldung(meldung: Schadenmeldung) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie diese Schadenmeldung endgültig löschen? Diese Aktion ist nicht wiederrufbar!',
    };
    let confirmDialog = this.dialog.open(UbgDialogConfirmComponent, dialogConfig);
    confirmDialog.afterClosed().toPromise().then((response: number) => {
      if (response == 1) {
        const dialog2Config = new MatDialogConfig();
        dialog2Config.disableClose = true;
        dialog2Config.autoFocus = true;
        dialog2Config.data = {
          label: 'Sie sind also ganz sicher? Der Fall wird endgültig gelöscht.'
        };
        let confirm2Dialog = this.dialog.open(UbgDialogConfirmComponent, dialog2Config);
        confirm2Dialog.afterClosed().subscribe(result => {
          if (result == 1) {
            this.service.delete(meldung).then(() => {
              this.snackbar.open('Die Meldung wurde gelöscht', '', {
                duration: 3000
              });
              this.update();
            }).catch(() => {
              this.snackbar.open('Die Meldung konnte nicht gelöscht werden', '', {
                duration: 8000
              });
            });
          }
        });
      }
    });
  }
  
  gotoList() {
    this.router.navigate(['list', {
      gliederungId: this.gliederungId,
      datumVon: this.datumVon,
      datumBis: this.datumBis,
    }]);
  }
  
  loadNotizen() {
    let count = 0;
    for (let i in this.meldungen.all) {
      for (let j in this.meldungen.all[i].relationships.notizen.data) {
        if (this.meldungen.all[i].relationships.notizen.data[j].attributes === undefined) {
          this.notizService.get(this.meldungen.all[i].relationships.notizen.data[j].type, this.meldungen.all[i].relationships.notizen.data[j].id).then((response: Notiz) => {
//            this.meldungen.all[i].notizen[j].title = response.title;
//            if (response.wiedervorlage === undefined) {
//              this.meldungen.all[i].notizen[j].wiedervorlage = null;
//            }
//            else {
//              this.meldungen.all[i].notizen[j].wiedervorlage = response.wiedervorlage;
//            }
//            if (response.wv_erledigt === undefined) {
//              this.meldungen.all[i].notizen[j].wv_erledigt = null;
//            }
//            else {
//              this.meldungen.all[i].notizen[j].wv_erledigt = response.wv_erledigt;
//            }
            for (let k in this.meldungen.all) {
              for (let l in this.meldungen.all[k].relationships.notizen.data) {
                if (this.meldungen.all[k].relationships.notizen.data[l].id == response.id) {
                  this.meldungen.all[k].relationships.notizen.data[l] = response;
                }
              }
            }
            this.countNotizen();
          });
        }
        else {
          this.countNotizen();
//          console.debug(this.meldungen.all[i].relationships.notizen[j]);
//          if (this.meldungen.all[i].notizen[j].wiedervorlage !== null) {
//            let d = new Date(this.meldungen.all[i].notizen[j].wiedervorlage);
//            let n = new Date();
//            let e: Date;
//            if (this.meldungen.all[i].notizen[j].wv_erledigt !== null) {
//              e = new Date(this.meldungen.all[i].notizen[j].wv_erledigt);
//            }
//            if (this.meldungen.all[i].status === '0' && d <= n && e === undefined) {
//              count++;
//            }
//          }
        }
      }
    }
//    this.countWiedervorlage = count;
  }
  
  private countNotizen() {
    let count = 0;
    for (let i in this.meldungen.all) {
      for (let j in this.meldungen.all[i].relationships.notizen.data) {
        if (this.meldungen.all[i].relationships.notizen.data[j].attributes === undefined) {}
        else if (this.meldungen.all[i].relationships.notizen.data[j].attributes.wiedervorlage === undefined) {}
        else if (this.meldungen.all[i].relationships.notizen.data[j].attributes.wiedervorlage === null) {}
        else {
          let d = new Date(this.meldungen.all[i].relationships.notizen.data[j].attributes.wiedervorlage);
          let n = new Date();
          let e: Date;
          if (this.meldungen.all[i].relationships.notizen.data[j].attributes.wv_erledigt !== null) {
            e = new Date(this.meldungen.all[i].relationships.notizen.data[j].attributes.wv_erledigt);
          }
          if (this.meldungen.all[i].attributes.status === 0 && d <= n && e === undefined) {
            count++;
          }
        }
      }
    }
    this.countWiedervorlage = count;
  }
    
  loadGliederungen() {
    this.gliederung.getAll().then((response: Array<Gliederung>) => {
      this.gliederungen = response;
    }).catch((error) => {
      console.warn(error);
      setTimeout(() => {
        this.loadGliederungen();
      }, 5000);
    });
  }
  
  gliederungSelected(event: string) {
    this.gliederungId = event;
    //this.update();
    this.filterList();
  }
  
  dateChanged() {
    //this.update();
    this.filterList();
  }

  filterList(): void {
    this.meldungen.filtered = this.meldungen.all;
    if (this.gliederungId !== null && this.gliederungId !== undefined && this.gliederungId !== '') {
      this.meldungen.filtered = this.meldungen.filtered.filter(m => m.relationships.gliederung_referenz.data.id === this.gliederungId);
    }
    if (this.datumVon !== null && this.datumVon !== undefined) {
      this.meldungen.filtered = this.meldungen.filtered.filter(m => m.attributes.schadensdatum >= this.datumVon.format('YYYY-MM-DD'));
    }
    if (this.datumBis !== null && this.datumBis !== undefined) {
      this.meldungen.filtered = this.meldungen.filtered.filter(m => m.attributes.schadensdatum <= this.datumBis.format('YYYY-MM-DD'));
    }
    this.applyPagination();
  }
  
  sparteName(sparte: Sparte): string {
    if (sparte === null) {
      return '- FEHLER -';
    }
    for (let i in this.sparten) {
      if (this.sparten[i].id == sparte.id) {
        return this.sparten[i].attributes.name;
      }
    }
    return '- Sparte nicht gefunden -';
  }

  categoryChange(category: string): void {
    this.isLoading = true;
    this.filter.category = category;
    switch(category) {
      case 'open':
        this.listTitle = 'Offene Anträge';
        break;
      case 'new':
        this.listTitle = 'Neue Anträge';
        break;
      case 'wiedervorlage':
        this.listTitle = 'Wiedervorlage';
        break;
      default:
        this.listTitle = 'FEHLER bei den Anträgen';
    }
    this.service.getMeldungenByCategory(category).then(meldungen => {
      this.isLoading = false;
      this.meldungen = {
        all: meldungen,
        filtered: meldungen,
        displayed: [],
      };
      this.applyPagination();
    }).catch(() => {
      console.warn('Error');
    });
  }
  
}
