/**
 * Textblock
 * Attila Németh, UBG
 * 10.10.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatTooltipModule} from '@angular/material/tooltip';

import {Textblock} from '../model/textblock';
import {UbgFabComponent} from '../components/ubg_fab.component';
import {UbgDialogDeleteComponent} from '../components/dialog_delete.component';
import {HeaderService} from '../services/header.service';
import {TextblockService} from '../services/textblock.service';
import {UbgTextblockDialogComponent} from './dialog/textblock';

@Component({
  selector: 'app-textblock-list',
  templateUrl: './textblock.component.html',
})
export class TextblockListComponent implements OnInit {

  textbloecke: Array<Textblock>
  
  constructor(private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private header: HeaderService,
              private service: TextblockService) {
    this.textbloecke = [];
  }
  
  ngOnInit() {
    this.header.setTitle('Textblöcke');
    this.updateList();
  }
  
  updateList() {
    this.service.getAll().then((response: Array<Textblock>) => {
      this.textbloecke = response;
    }).catch((error) => {
      console.error(error);
      setTimeout(() => {
        this.updateList();
      }, 50);
    });
  }
  
  editTextblock(textblock: Textblock) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      textblock: textblock,
    };
    let formDialog = this.dialog.open(UbgTextblockDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((result) => {
      if (result == 1) {
        this.service.update(dialogConfig.data.textblock).then(() => {
          this.snackbar.open('Der Textblock wurde aktualisiert', '', {
            duration: 3000,
          });
        }).catch((error) => {
          console.error(error);
          this.snackbar.open('Der Textblock konnte nicht aktualisiert werden', '', {
            duration: 8000,
          });
        })
      }
    });
  }
  
  addTextblock() {
    let textblock = new Textblock();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      textblock: textblock,
    };
    let formDialog = this.dialog.open(UbgTextblockDialogComponent, dialogConfig);
    formDialog.afterClosed().subscribe((result) => {
      if (result == 1) {
        this.service.add(dialogConfig.data.textblock).then(() => {
          this.textbloecke.push(dialogConfig.data.textblock);
          this.snackbar.open('Der Textblock wurde hinzugefügt', '', {
            duration: 3000,
          });
        }).catch((error) => {
          console.error(error);
          this.snackbar.open('Der Textblock konnte nicht hinzugefügt werden', '', {
            duration: 8000,
          });
        })
      }
    });
  }
  
  deleteTextblock(textblock: Textblock) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie diesen Textblock wirklich löschen?',
    };
    let deleteDialog = this.dialog.open(UbgDialogDeleteComponent, dialogConfig);
    deleteDialog.afterClosed().toPromise().then((response) => {
      if (response == 1) {
        this.service.delete(textblock).then(() => {
          this.snackbar.open('Der Textblock wurde gelöscht', '', {
            duration: 3000,
          });
          this.updateList();
        }).catch((error) => {
          console.error(error);
          this.snackbar.open('Der Textblock kann nicht gelöscht werden', '', {
            duration: 8000,
          });
        })
      }
    });
  }

}