/**
 * Löschen-Dialog
 * Attila Németh, UBG
 * 13.09.2018
 */
 
import { Component, Inject } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'ubg-dialog-delete',
  templateUrl: './dialog_delete.component.html',
})
export class UbgDialogDeleteComponent {
  
  label: string
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    label: string
  }) {
    if (data.label !== null && data.label !== undefined) {
      this.label = data.label;
    }
  }
  
}