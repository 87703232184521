/**
 * Textblock-Service
 * Attila Németh, UBG
 * 10.10.2018
 */
 
import { Injectable } from '@angular/core';

import {Textblock} from '../model/textblock';
import { ContentaService } from './contenta.service';
import {Schadenmeldung} from'../model/meldung';

@Injectable({
  providedIn: 'root'
})
export class TextblockService {

  constructor(private contenta: ContentaService) {
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getJsonApi('taxonomy_term/textblock').then((response: Array<Textblock>) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  add(textblock: Textblock) {
    let promise = new Promise((resolve, reject) => {
      textblock.attributes.description = {
        value: textblock.attributes.description.value,
      };
      if (textblock.attributes.changed !== undefined) {
        delete textblock.attributes.changed;
      }
      this.contenta.post('api/taxonomy_term/textblock', {data: textblock}).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  update(textblock: Textblock) {
    let promise = new Promise((resolve, reject) => {
      textblock.attributes.description = {
        value: textblock.attributes.description.value,
      };
      if (textblock.attributes.changed !== undefined) {
        delete textblock.attributes.changed;
      }
      this.contenta.patch('api/taxonomy_term/textblock/' + textblock.id, {data: textblock}).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  delete(textblock: Textblock) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/taxonomy_term/textblock/' + textblock.id).then((response: any) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    });
    return promise;
  }
  
  getReplacement(text: string, meldungId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/api/notiz/tokens/replace', {
        text: text,
        meldung: meldungId,
        textblock: 1,
      }).then((response: string) => {
        let text = response.replace(new RegExp('\n', 'g'), '<br />\n');
        resolve(text);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
}
