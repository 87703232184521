/**
 * Versicherungsvertrag-Service
 * Attila Németh, UBG
 * 10.09.2018
 */
 
import { Injectable } from '@angular/core';

import {Versicherungsvertrag} from '../model/vvertrag';

import {ContentaService} from './contenta.service';

@Injectable({
  providedIn: 'root'
})
export class VersicherungsvertragService {

  vertrag: Versicherungsvertrag

  constructor(private contenta: ContentaService) { }
  
  getFiltered(filters: {}) {
    let promise = new Promise((resolve, reject) => {
      let filterQuery: Array<string> = [];
      for (let propertyName in filters) {
        if (filters[propertyName] !== '' && filters[propertyName] !== null && filters[propertyName] !== undefined) {
          filterQuery.push('filter[' + propertyName + '.id]=' + filters[propertyName]);
        }
      }
      let filterString: string = filterQuery.join('&');
      this.contenta.get('api/ubg_versicherungsvertrag/ubg_versicherungsvertrag?include=sparte,versicherungsgesellschaft&' + filterString).then((response: {
        data: Array<{
          id: string
          attributes: {
            number: string
            gultigkeit: {
              value: string,
              end_value: string
            }
          }
          relationships: {
            sparte: {
              data: {
                id: string
              }
            }
            versicherungsgesellschaft: {
              data: {
                id: string
              }
            }
          }
        }>
      }) => {
        let vertraege: Array<Versicherungsvertrag> = [];
        for (let i in response.data) {
          let newVertrag: Versicherungsvertrag = {
            id: response.data[i].id,
            number: response.data[i].attributes.number,
            sparte: response.data[i].relationships.sparte.data.id,
            versicherungsgesellschaft: response.data[i].relationships.versicherungsgesellschaft.data.id,
            datum: {
              beginn: response.data[i].attributes.gultigkeit.value,
              ende: response.data[i].attributes.gultigkeit.end_value,
            }
          };
          vertraege.push(newVertrag);
        }
        resolve(vertraege);
      }).catch((error) => {
        console.warn(error);
        reject();
      });
    });
    return promise;
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/ubg_versicherungsvertrag/ubg_versicherungsvertrag?include=sparte,versicherungsgesellschaft').then((response: {
        data: Array<{
          id: string,
          attributes: {
            number: string
            gultigkeit: {
              value: any
              end_value: any
            }
          },
          relationships: {
            sparte: {
              data: {
                id: string
              }
            }
            versicherungsgesellschaft: {
              data: {
                id: string
              }
            }
          }
        }>
        included: Array<{
          id: string
          attributes: {
            name: string
          }
        }>
      }) => {
        let vertraege = [];
        for (let i in response.data) {
          let newVertrag: Versicherungsvertrag = {
            id: response.data[i].id,
            number: response.data[i].attributes.number,
            sparte: response.data[i].relationships.sparte.data.id,
            versicherungsgesellschaft: response.data[i].relationships.versicherungsgesellschaft.data.id,
            datum: {
              beginn: response.data[i].attributes.gultigkeit.value,
              ende: response.data[i].attributes.gultigkeit.end_value
            },
            sparteName: '',
            versicherungsgesellschaftName: ''
          };
          for (let ii in response.included) {
            if (response.included[ii].id == response.data[i].relationships.sparte.data.id) {
              newVertrag.sparteName = response.included[ii].attributes.name;
            }
            if (response.included[ii].id == response.data[i].relationships.versicherungsgesellschaft.data.id) {
              newVertrag.versicherungsgesellschaftName = response.included[ii].attributes.name;
            }
          }
          vertraege.push(newVertrag);
        }
        resolve(vertraege);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }
  
  get(uuid: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getJsonApi('ubg_versicherungsvertrag/ubg_versicherungsvertrag/' + uuid).then((response: {
        id: string
        attributes: {
          number: string
          gultigkeit: {
            value: any
            end_value: any
          }
        }
        relationships: {
          sparte: {
            data: {
              id: string
            }
          }
          versicherungsgesellschaft: {
            data: {
              id: string
            }
          }
        }
      }) => {
        let newVertrag: Versicherungsvertrag = {
          id: response.id,
          number: response.attributes.number,
          sparte: response.relationships.sparte.data.id,
          versicherungsgesellschaft: response.relationships.versicherungsgesellschaft.data.id,
          datum: {
            beginn: response.attributes.gultigkeit.value,
            ende: response.attributes.gultigkeit.end_value
          },
          sparteName: '',
          versicherungsgesellschaftName: ''
        };
        resolve(newVertrag);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  preprocess() {
    let data = {
      id: null,
      type: 'ubg_versicherungsvertrag--ubg_versicherungsvertrag',
      attributes: {
        number: this.vertrag.number,
        gultigkeit: {
          value: this.getDateString(this.vertrag.datum.beginn),
          end_value: this.getDateString(this.vertrag.datum.ende)
        }
      },
      relationships: {
        sparte: {
          data: {
            type: 'taxonomy_term--sparte',
            id: this.vertrag.sparte
          }
        },
        versicherungsgesellschaft: {
          data: {
            type: 'ubg_versicherungsfirma--ubg_versicherungsfirma',
            id: this.vertrag.versicherungsgesellschaft
          }
        }
      }
    };
    if (this.vertrag.id !== undefined && this.vertrag.id !== '') {
      data.id = this.vertrag.id;
    }
    return data;
  }
  
  getDateString(original: any) {
    if (typeof(original) == 'string') {
      return original;
    }
    else if (typeof(original) == 'object') {
      return original.format('YYYY-MM-DD');
    }
  }
  
  create(vertrag: Versicherungsvertrag) {
    this.vertrag = vertrag;
    let promise = new Promise((resolve, reject) => {
      let data = this.preprocess();
      let path = 'api/ubg_versicherungsvertrag/ubg_versicherungsvertrag';
      this.contenta.post(path, {
        data: data
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(vertrag: Versicherungsvertrag) {
    this.vertrag = vertrag;
    let promise = new Promise((resolve, reject) => {
      let data = this.preprocess();
      let path = 'api/ubg_versicherungsvertrag/ubg_versicherungsvertrag/' + vertrag.id;
      this.contenta.patch(path, { data: data }).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}