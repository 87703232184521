/**
 * Password vergessen
 * Attila Németh, UBG
 * 24.10.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA} from "@angular/material";

@Component({
  templateUrl: './forgot.html',
})
export class ContentaUserDialogForgotComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                email: string
              }) {
  }
  
  ngOnInit() {
  };
  
  formHasErrors() {
    if (this.data.email == '') {
      return true;
    }
    return false;
  }
  
}