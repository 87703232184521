/**
 * Datenblatt-Komponent
 * 
 * Attila Németh, UBG
 * 30.01.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatSnackBar} from '@angular/material';

import {Schadenmeldung} from '../../model/meldung';
import {SchadenmeldungService} from '../../services/meldung.service';
import {UbgNextcloudService} from '../../modules/nextcloud/services/nextcloud.service';

@Component({
  selector: 'sch-datenblatt',
  templateUrl: './datenblatt.component.html',
  styleUrls: ['./datenblatt.component.scss'],
})
export class SchadenDatenblattComponent {
  
  @Input() meldung: Schadenmeldung
  @Output() cloudChanged = new EventEmitter()
  isLoading: boolean = false
  
  constructor(private snackbar: MatSnackBar, 
                private service: SchadenmeldungService,
                private nextCloud: UbgNextcloudService) {}
  
  meldungIsSaved(): boolean {
    if (this.meldung.id !== null && this.meldung.id !== '') {
      return true;
    }
    else {
      return false;
    }
  }
  
  getDatenblatt() {
    console.warn('Datenblatt funktioniert vorübergehend nicht');
//    this.isLoading = true;
//    this.service.getDatenblatt(this.meldung).then((response: Blob) => {
//      this.isLoading = false;
//      let fileName = 'datenblatt_' + this.meldung.schadensnummer.toLowerCase() + '.pdf'
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, fileName);
//      } 
//      else {
//        a.href = url;
//        a.download = fileName;
//        a.click();
//      }
//    }).catch(() => {
//      this.isLoading = false;
//      this.snackbar.open('Ein Fehler ist aufgetreten. Code: DB-58', null, {
//        duration: 12000,
//      });
//    });
  }
  
  getDatenblattVers() {
    console.warn('Datenblatt funktioniert vorübergehend nicht');
//    this.isLoading = true;
//    this.service.getVersicherungDatenblatt(this.meldung).then((response: Blob) => {
//      this.isLoading = false;
//      let fileName = 'datenblatt_vers_' + this.meldung.schadensnummer.toLowerCase() + '.pdf'
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, fileName);
//      } 
//      else {
//        a.href = url;
//        a.download = fileName;
//        a.click();
//      }
//    }).catch(() => {
//      this.isLoading = false;
//      this.snackbar.open('Ein Fehler ist aufgetreten. Code: DB-79', null, {
//        duration: 12000,
//      });
//    });
//    this.service.getVersicherungDatenblatt(this.meldung).then((response: {
//      id: string
//    }) => {
//      let newDocs = [];
//      for (let i in this.meldung.dokumente) {
//        newDocs.push(this.meldung.dokumente[i]);
//      }
//      newDocs.push(response.id);
//      this.meldung.dokumente = newDocs;
//      this.addToCloud(response.id);
//      this.isLoading = false;
//    }).catch(() => {
//      this.isLoading = false;
//      this.snackbar.open('Ein Fehler ist aufgetreten. Code: DB-79', null, {
//        duration: 12000,
//      });
//    });
  }
  
//  addToCloud(dokument: string): void {
//    this.nextCloud.putFile('schaden/' + this.meldung.schadensnummer, dokument).then(() => {
//      this.cloudChanged.emit();
//    }).catch(() => {
//      this.cloudChanged.emit();
//    });
//  }
  
}