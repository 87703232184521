/**
 * Registrationsformular
 * Attila Németh, UBG
 * 23.10.2018
 */
 
import { Component, OnInit } from '@angular/core';

import {MatSnackBar} from '@angular/material';

import {ContentaUser} from '../../model/contenta_user';
import {ContentaUserService} from '../../services/contenta_user.service';

@Component({
  selector: 'ubg-contenta-user-register',
  templateUrl: './register.component.html',
})
export class ContentaUserRegisterComponent implements OnInit {
  
  user: ContentaUser
  emailPattern: string = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
  
  constructor(private snackbar: MatSnackBar,
              private service: ContentaUserService) {
    this.user = new ContentaUser;
  }
  
  ngOnInit() {}
  
  formInvalid() {
    let requiredFields = ['vorname', 'nachname', 'email', 'gliederung_angabe'];
    for(let i in requiredFields) {
      if (this.user[requiredFields[i]] == ''
            || this.user[requiredFields[i]] == null
            || this.user[requiredFields[i]] == undefined) {
              return true;
      }
    }
    if (!this.emailValid()) {
      return true;
    }
    return false;
  }
  
  emailValid() {
    if (this.user.email === undefined || this.user.email === null) {
      return true;
    }
    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return re.test(String(this.user.email).toLowerCase());
  }
  
  userRegister() {
    this.service.register(this.user).then(() => {
      this.snackbar.open('Die Registration war erfolgreich', '', {
        duration: 8000,
      });
    }).catch(() => {
      this.snackbar.open('Die Registration war nicht erfolgreich', '', {
        duration: 8000,
      });
    });
  }
  
}