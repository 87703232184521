/**
 * Paginator-Übersetzung
 * Attila Németh, UBG
 * 24.09.2018
 */
 
import {MatPaginatorIntl} from '@angular/material';

export class MatPaginatorIntlDe extends MatPaginatorIntl {
  itemsPerPageLabel = 'Anzahl der Elemente pro Seite';
  nextPageLabel     = 'Nächste';
  previousPageLabel = 'Vorige';

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 von ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' von ' + length;
  };

}