/**
 * Notiz Bearbeiten
 * 
 * Attila Németh, UBG
 * 14.04.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {NotizDialogData} from '../../aktionen.component';
import {NotizService} from '../../../../../../../services/notiz.service';
 
@Component({
  templateUrl: 'notiz.component.html',
  styleUrls: ['notiz.component.scss'],
})
export class NotizDialogComponent {
  
  editorConfig = {
    minHeight: 5,
//    toolbar: [
//      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
//      ['fontName', 'fontSize', 'color'],
//      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
//      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
//      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
//      ['link', 'unlink', /*'image', 'video'*/]
//    ],
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['cut', 'copy', 'delete'],
      ['undo', 'redo'],
    ],
  }

  constructor(
    public dialogRef: MatDialogRef<NotizDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotizDialogData,
    private service: NotizService) {}
    
  getNotizPdf() {
    this.service.getBriefPdf(this.data.notiz, this.data.meldung.id).then((response: Blob) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, 'brief.pdf');
      } 
      else {
        a.href = url;
        a.download = 'brief.pdf';
        a.click();
      }
    })
  }

}