/**
 * Datumfeld
 * Attila Németh, UBG
 * 08.10.2018
 */
 
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ubg-datepicker-cell',
  templateUrl: './datepickercell.component.html',
  styleUrls: ['./datepickercell.component.scss'],
})
export class UbgDatepickerCellComponent implements OnInit {
  
  @Input()
  set value(value: string) {
    this.inputValue = value;
    if (this.inputOpen) {
      this.popupValue = this.inputValue;
    }
    if (this.inputValue !== undefined) {
      this.dateValue = new Date(this.inputValue);
    }
  }
  @Input() free: boolean              // Ob es frei geändert werden darf.
  
  @Output() changed = new EventEmitter();
  
  inputValue: any
  inputOpen: boolean
  submitPossible: boolean
  popupValue: any
  dateValue: any
  
  constructor() {
    if (this.free === undefined) {
      this.free = true;
    }
    this.inputOpen = false;
    this.submitPossible = false;
    if (this.inputValue !== undefined) {
      this.dateValue = new Date(this.inputValue);
    }
  }
  
  ngOnInit() {
  }
  
  selectDate() {
    this.inputOpen = true;
    this.submitPossible = true;
    this.popupValue = this.inputValue;
  }
  
  setNow() {
    let d = new Date();
    this.inputValue = d;
    this.dateValue = d;
    this.changed.emit(this.inputValue);
  }
  
  reset() {
    this.inputValue = undefined;
    this.dateValue = undefined;
    this.changed.emit(this.inputValue);
  }

  dateTyped() {
    this.submitPossible = true;
  }
  
  apply() {
    this.inputValue = this.popupValue;
    this.inputOpen = false;
    if (this.inputValue !== undefined) {
      this.dateValue = new Date(this.value);
    }
    this.changed.emit(this.inputValue);
  }
  
  cancel() {
    this.inputOpen = false;
  }
    
}