/**
 * UBG Divider
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ubg-divider',
  templateUrl: './divider.component.html',
  styleUrls: ['./divider.scss']
})
export class UbgDividerComponent implements OnInit {
  
  ngOnInit() {
    
  }
  
}