/**
 * Drupal-Textarea-Komponent
 * 
 * @author Attila Németh
 * @date 17.04.2020
 */


import {Component, OnInit, Input, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const noop = () => {};

interface drupalLongText {
  value: string
}

@Component({
  selector: 'drupal-long-textarea',
  templateUrl: './drupal-long-textarea.html',
  styleUrls: ['./drupal-long-textarea.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DrupalLongTextareaInput),
      multi: true
    }
  ]
})
export class DrupalLongTextareaInput implements OnInit, ControlValueAccessor {
  
  @Input() placeholder: string = null
  @Input() autosizeMinRows: number = 1
  @Input() autosizeMaxRows: number = 12
   
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  
  private textObject: drupalLongText = {
    value: '',
  }

  constructor() {}

  ngOnInit() {}
  
  get value(): string | null | drupalLongText {
    return this.textObject;
  };

  set value(v: string | null | drupalLongText) {
    console.debug(v);
  }
  
  setFocus() {}

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: string | null | drupalLongText) {
    if (value === null) {
      this.textObject = {
        value: '',
      };
    }
    else if (value['value'] !== undefined) {
      this.textObject = {
        value: value['value'],
      };
    }
    else if (typeof value === 'string') {
      this.textObject = {
        value: value,
      };
    }
    else {
      console.error('Wrong Textarea Value', value);
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.textObject);
  }
  
  textChanged(): void {
    this.updateChanges();
  }

}
