/**
 * Notiz-Service
 * 
 * Attila Németh, UBG
 * 02.04.2020
 */
 
import {Injectable} from '@angular/core';

import {Notiz} from '../model/notiz';

import {ContentaService} from './contenta.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NotizService {
  
  constructor(private contenta: ContentaService) {}
  
  get(type: string, id: string) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/' + type.replace('--', '/') + '/' + id;
      this.contenta.get(path).then((response: {
        data: Notiz
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(notiz: Notiz) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/' + notiz.type.replace('--', '/');
      this.contenta.post(path, {
        data: notiz
      }).then((response: {
        data: Notiz
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(notiz: Notiz) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/' + notiz.type.replace('--', '/') + '/' + notiz.id;
      this.contenta.patch(path, {
        data: notiz
      }).then((response: {
        data: Notiz
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getAbsender() {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'ubg/api/notiz/absender';
      this.contenta.get(path).then((response: Array<string>) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getTokens() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/api/notiz/tokens').then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  getBriefPdf(notiz: Notiz, meldungId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getPostFile('ubg/api/notiz/brief', {
        template: notiz.attributes.template, 
        text: notiz.attributes.text.value,
        meldung: meldungId,
      }).then((response: Blob) => {
        resolve(response);
      }).catch((error) => {
        console.error(error);
      });
    });
    return promise;
  }
  
}