/**
 * E-Mail-Dialog
 * Attila Németh, UBG
 * 09.10.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {Textblock} from '../../model/textblock';
import {TextblockService} from '../../services/textblock.service';
import {ContentaService} from '../../services/contenta.service';

@Component({
  selector: 'ubg-dialog-form-mail',
  templateUrl: './form_dialog_mail.html',
})
export class UbgFormDialogMailComponent implements OnInit {
  
  nachrichtFocused: boolean;
  textblockLooked: boolean;
  textblockSearchString: string
  textbloecke: Array<Textblock>
  textblockService: TextblockService
  tokenLooked: boolean;
  tokenSearchString: string
  previewText: {
    show: boolean
    content: string
  }
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
    empfanger: string
    betreff: string
    nachricht: string
    options: Array<{
      label: string
      mail: string
    }>
    tokens: Array<{
      name: string
      label: string
    }>
    meldung: string,
  }, private contenta: ContentaService) {
    this.nachrichtFocused = false;
    this.textblockLooked = false;
    this.tokenLooked = false;
    this.previewText = {
      show: false,
      content: '',
    };
  }
  
  ngOnInit() {
    this.textblockService = new TextblockService(this.contenta);
    this.textblockService.getAll().then((response: Array<Textblock>) => {
      this.textbloecke = response;
    });
  };
  
  formHasErrors() {
    if (this.data.empfanger === '' || this.data.betreff === '' || this.data.nachricht === '') {
      return true;
    }
    return false;
  }
  
  lookForTextblock() {
    this.textblockLooked = true;
    this.textblockSearchString = '';
    this.nachrichtFocused = true;
  }
  
  getFilteredTextblockOptions() {
    let options = [];
    for (let i in this.textbloecke) {
      if (this.textbloecke[i].attributes.name.toLowerCase().indexOf(this.textblockSearchString.toLowerCase()) >= 0) {
        options.push(this.textbloecke[i].attributes.name);
      }
    }
    return options;
  }
  
  insertTextblock() {
    const textblockString: string = '[textblock:' + this.textblockSearchString + ']';
//    this.data.nachricht += '[textblock:' + this.textblockSearchString + ']';
    this.textblockService.getReplacement(textblockString, this.data.meldung).then((response: string) => {
      this.data.nachricht += response;
    }).catch((error) => {
      console.error(error);
       this.data.nachricht += '-FEHLER-';
    });
    this.textblockLooked = false;
    this.textblockSearchString = '';
  }
  
  lookForToken() {
    this.tokenLooked = true;
    this.tokenSearchString = '';
  }
  
  getFilteredTokenOptions() {
    let options = [];
    for (let i in this.data.tokens) {
      if (this.data.tokens[i].name.toLowerCase().indexOf(this.tokenSearchString.toLowerCase()) >= 0) {
        options.push(this.data.tokens[i].name);
      }
    }
    return options;
  }
  
  insertToken() {
    for (let i in this.data.tokens) {
      if (this.data.tokens[i].name.toLowerCase() == this.tokenSearchString.toLowerCase()) {
        const tokenString: string = '[notiz:' + i + ']';
        this.textblockService.getReplacement(tokenString, this.data.meldung).then((response: string) => {
          this.data.nachricht += response;
        }).catch((error) => {
          console.error(error);
           this.data.nachricht += '-FEHLER-';
        });
        break;
      }
    }
    this.tokenLooked = false;
  }
  
  //    [textblock:Lorem Ipsum] [notiz:schadensnummer]
  
  preview() {
    if (this.previewText.show) {
      this.contenta.post('ubg/api/notiz/tokens/replace', {
        text: this.data.nachricht,
        meldung: this.data.meldung,
        textblock: 1,
      }).then((response: string) => {
        this.previewText.content = response;
      });
    }
    else {
      this.previewText.content = '';
    }
  }
  
}