/**
 * Datei-Card-Normal Component
 * Attila Németh, UBG
 * 31.10.2018
 */
 
import { Component, OnInit, Input } from '@angular/core';

import {UbgFile} from '../../model/file';
import {ContentaService} from '../../../../services/contenta.service';

@Component({
  selector: 'ubg-file-card-normal',
  templateUrl: './card_normal.component.html',
})
export class UbgFileCardNormalComponent implements OnInit {
  
  @Input() fileId: string
  
  attributes: {
    filename: string
    filemime: string
    filesize: number
    uri: {
      url: string
    }
  }
  
  constructor(private contenta: ContentaService) {
    this.attributes = {
      filemime: '',
      filename: 'Unbekannt',
      filesize: 0,
      uri: {
        url: '',
      }
    };
  }
  
  ngOnInit() {
    this.contenta.getJsonApi('file/file/' + this.fileId).then((response: UbgFile) => {
      this.attributes = response.attributes;
      this.attributes.uri.url = this.contenta.baseUrl + response.attributes.uri.url;
    }).catch((error) => {
      this.attributes = {
        filemime: '',
        filename: 'Unbekannt',
        filesize: 0,
        uri: {
          url: '',
        }
      };
    });
  }
  
  open() {
    window.open(this.attributes.uri.url);
  }
  
}