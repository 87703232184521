/**
 * Layout-Component, mit 2:1
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ubg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.scss']
})
export class UbgLayoutComponent implements OnInit {
  
  ngOnInit() {
    
  }
  
}