export const environment = {
  production: true,
  contenta: {
    clientId: 'be5554ed-0903-4c99-b720-6ceb0fbb0ea8', //'fb05d033-c957-4359-800c-427d9c58dd78';
    clientSecret: 'test$123',
    baseUrl: 'https://contenta.ubgnet.de/',
    tokenUrl: 'https://contenta.ubgnet.de/oauth/token',
  },
  site: {
    name: 'CDU Schadenmanagement',
    url: 'https://schadensmanagement.cdu.de/',
  },
  cduplus: {
    clientId: 'schaden',
    clientSecret: 'ubg$123',
  }
};
