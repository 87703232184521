/**
 * Schadenmeldung bearbeiten
 * 
 * Attila Németh, UBG
 * 03.04.2020
 */
 
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap } from '@angular/router';

import {MatSnackBar} from '@angular/material';

import {Schadenmeldung} from '../../../model/meldung';
import {SchadenmeldungService} from '../../../services/meldung.service';
import {Sparte} from '../../../model/sparte';

@Component({
  templateUrl: './edit.component.html',
})
export class EditComponent implements OnInit {
  
  meldung: Schadenmeldung = null
  
  constructor(private route: ActivatedRoute,
                private snackbar: MatSnackBar,
                private service: SchadenmeldungService) {}
  
  ngOnInit() {
    let meldungId = this.route.snapshot.paramMap.get('uuid');
    this.service.get(meldungId).then((response: Schadenmeldung) => {
      if (response.relationships.sparte.data === null) {
        response.relationships.sparte.data = new Sparte;
      }
      this.meldung = response;
      // this.service.setMeldungGesehen(this.meldung).then(() => {}).catch(() => {});
    }).catch((error) => {
      this.snackbar.open('Der Fall kann nicht geladen werden. Fehlercode: ED-31', null, {
        duration: 15000,
      });
      console.error(error);
    });
  }
  
}