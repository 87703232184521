/**
 * Passwort-Dialog
 * Attila Németh, UBG
 * 24.10.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  templateUrl: './password.html',
})
export class ContentaUserDialogPasswortComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
                email: string
                password1: string
                password2: string
              }) {
  }
  
  ngOnInit() {
  };
  
  formHasErrors() {
    if (this.data.email == '') {
      return true;
    }
    if (this.data.password1 == '' 
      || this.data.password1 === null 
      || this.data.password1 === undefined) {
      return true;
    }
    if (this.data.password1 != this.data.password2) {
      return true;
    }
    return false;
  }
  
}