/**
 * Euro-Pipe
 * Attila Németh, UBG
 * 25.09.2018
 */
 
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euro'
})
export class EuroPipe implements PipeTransform {

  transform(val: string): string {
    if (val === undefined) {
      return '-';
    }
    let f:string = '';
    let n:number = Number(val);
    let minus:boolean = false;
    if (n < 0) {
      minus = true;
      n = -n;
    }
    let i:number = Math.floor(n);
    let d:number = n - i;
    f = i.toLocaleString().replace('.', ' ');
    let c = Math.round(d * 100);
    let cs: string;
    if (c == 0) {
      cs = '00';
    }
    else {
      cs = c.toString();
    }
    f += ',' + cs;
    f += ' €';
    f = f.trim();
    if (minus) {
      f = '-' + f;
    }
    return f;
  }
}