/**
 * Gliederung-Komponent
 * 
 * Attila Németh, UBG
 * 24.06.2019
 */
 
import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {GliederungService} from '../../services/gliederung.service';

@Component({
  selector: 'ubg-gliederung',
  templateUrl: './gliederung.component.html',
})
export class UbgGliederungComponent implements OnInit {
  
  @Input() set value(value: string) {
    if (value !== '' && value !== null && value !== undefined) {
      this.service.get(value).then((response: {
        name: string
      }) => {
        if (response !== undefined) {
          this.searchString = response.name;
//          this.gliederungSelected.emit(value);
        }
      });
    }
  }
  @Input() placeholder: string = ''
  @Output() gliederungSelected = new EventEmitter();
  
  searchOptions: Array<{
    id: string
    label: string
  }> = []
  searchString: string = ''
  
  constructor(private service: GliederungService) {}
  
  ngOnInit() {
    this.updateSearchOptions();
  }
  
  updateSearchOptions() {
    this.service.search(this.searchString).then((response: Array<{
      id: string
      label: string
    }>) => {
      this.searchOptions = response;
    }).catch(() => {
      this.searchOptions = [];
    });
  }
  
  selected(event: MatAutocompleteSelectedEvent) {
//    console.warn('??', 'Selected', '??');
    this.gliederungSelected.emit(event.option.value);
    setTimeout(() => {
      for (let i in this.searchOptions) {
        if (this.searchOptions[i].id == event.option.value) {
          this.searchString = this.searchOptions[i].label;
        }
      }
    }, 350);
  }
  
}