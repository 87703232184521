/**
 * Schadenmeldung-Service
 * Attila Németh, UBG
 * 12.09.2018
 */
 
import {Injectable} from '@angular/core';
import {HttpParams, HttpErrorResponse} from '@angular/common/http';

import {Schadenmeldung} from '../model/meldung';

import {ContentaService} from './contenta.service';
import {StichwortService} from './stichwort.service';
import { Observable } from 'rxjs';

interface Filter {[key: string]: {
  group?: {
    conjunction: string
  },
  condition?: {
    path: string
    value: string
    operator: string
    memberOf?: string
  }
}}
 
@Injectable({
  providedIn: 'root'
})
export class SchadenmeldungService {
  
  constructor(private contenta: ContentaService,
              private stichwort: StichwortService) {}
  
//  private rawToMeldung(raw: {
//    id: string
//    attributes: {
//      versicherungsvertrag_gultigkeit: {
//        value: string,
//        end_value: string
//      }
//      schadensdatum: {
//        value: string
//      }
//      schadenseingang: {
//        value: string
//      }
//      id: number
//    }
//    relationships: {
//      notizen: {
//        data: Array<{
//          id: string
//          type: string
//        }>
//      }
//    }
//  }) {
//    let meldung = this.create();
//    meldung.entityId = raw.attributes.id;
//    for (let attribute in raw.attributes) {
//      if (attribute != 'id') {
//        meldung[attribute] = raw.attributes[attribute];
//      }
//    }
//    if (raw.relationships.notizen !== undefined) {
//      meldung.notizen = [];
//      for (let delta in raw.relationships.notizen.data) {
//        meldung.notizen.push({
//          id: raw.relationships.notizen.data[delta].id,
//          type: raw.relationships.notizen.data[delta].type.replace('ubg_notiz--', ''),
//        });
//      }
//      delete raw.relationships.notizen;
//    }
//    for (let relationship in raw.relationships) {
//      if (raw.relationships[relationship] !== null
//                                         && raw.relationships[relationship].data !== null) {
//        if (raw.relationships[relationship].data.id !== undefined) {
//          meldung[relationship] = raw.relationships[relationship].data.id;
//        }
//        else if (raw.relationships[relationship].data[0] !== undefined 
//                    && raw.relationships[relationship].data[0].id !== undefined) {
//          meldung[relationship] = [];
//          for (let i in raw.relationships[relationship].data) {
//            meldung[relationship].push(raw.relationships[relationship].data[i].id);
//          }
//        }
//      }
//    }
//    if (raw.attributes.versicherungsvertrag_gultigkeit !== null 
//      && raw.attributes.versicherungsvertrag_gultigkeit !== undefined) {
//      if (raw.attributes.versicherungsvertrag_gultigkeit.value !== undefined) {
//        meldung.versicherungsvertragDatum.beginn = raw.attributes.versicherungsvertrag_gultigkeit.value;
//      }
//      if (raw.attributes.versicherungsvertrag_gultigkeit.end_value !== undefined) {
//        meldung.versicherungsvertragDatum.ende = raw.attributes.versicherungsvertrag_gultigkeit.value;
//      }
//    }
//    if (meldung.beschreibung === null) {
//      meldung.beschreibung = {
//        value: ''
//      };
//    }
//    meldung.id = raw.id;
//    meldung.status = meldung.status.toString();
//    return meldung;
//  }
//  
//  private meldungToRaw(meldung: Schadenmeldung) {
//    let promise = new Promise((resolve, reject) => {
//      let data: any = {
//        type: 'ubg_schadenmeldung--ubg_schadenmeldung',
//        attributes: {
//          title: meldung.title,
//        },
//        relationships: {},
//      };
//      let attributeNames = [
//        'abteilung',
//        'ansprechpartner',
//        'anspruchsteller_ansprechpartner',
//        'anspruchsteller_cdu',
//        'anspruchsteller_e_mail',
//        'anspruchsteller_name',
//        'anspruchsteller_ort',
//        'anspruchsteller_plz',
//        'anspruchsteller_strasse',
//        'anspruchsteller_telefon',
//        'e_mail',
//        'gliederung',
//        'jahrespramie',
//        'ort',
//        'plz',
//        'schadensquote',
//        'strasse',
//        'telefon',
//        'vg_ansprechpartner',
//        'vg_ort',
//        'vg_plz',
//        'vg_strasse',
//        'gemeldete_schadenshohe',
//        'schadensnummer_der_versicherung',
//        'regulierte_schadenshohe',
//        'eigenbeteiligung',
//        'eigenbeteiligung_hohe',
//        'kulanz',
//        'kulanz_hohe',
//        'art_der_entschadigung',
//        'status',
//        'geschlossen',
//        'cloud',
//        'cloud_id',
//        'cloud_password',
//      ];
//      for (let i in attributeNames) {
//        if (meldung[attributeNames[i]] !== null && meldung[attributeNames[i]] !== undefined) {
//          data.attributes[attributeNames[i]] = meldung[attributeNames[i]];
//        }
//      }
//      if (meldung.gliederung_referenz !== null 
//                    && meldung.gliederung_referenz !== undefined
//                    && meldung.gliederung_referenz !== '') {
//        data.relationships.gliederung_referenz = {
//          data: {
//            type: 'ubg_cdu_gliederung--ubg_cdu_gliederung',
//            id: meldung.gliederung_referenz,
//          }
//        }
//      }
//      if (meldung.sparte !== null && meldung.sparte !== undefined && meldung.sparte !== '') {
//        data.relationships.sparte = {
//          data: {
//            type: 'taxonomy_term--sparte',
//            id: meldung.sparte,
//          }
//        };
//      }
//      if (meldung.versicherungsgesellschaft !== null 
//                    && meldung.versicherungsgesellschaft !== undefined
//                    && meldung.versicherungsgesellschaft !== '') {
//        data.relationships.versicherungsgesellschaft = {
//          data: {
//            type: 'ubg_versicherungsfirma--ubg_versicherungsfirma',
//            id: meldung.versicherungsgesellschaft,
//          }
//        };
//      }
//      if (meldung.versicherungsvertrag !== null 
//                    && meldung.versicherungsvertrag !== undefined
//                    && meldung.versicherungsvertrag !== '') {
//        data.relationships.versicherungsvertrag = {
//          data: {
//            type: 'ubg_versicherungsvertrag--ubg_versicherungsvertrag',
//            id: meldung.versicherungsvertrag,
//          }
//        };
//      }
//      data.relationships.notizen = {
//        data: [],
//      }
//      for (let delta in meldung.notizen) {
//        data.relationships.notizen.data.push({
//          type: 'ubg_notiz--' + meldung.notizen[delta].type,
//          id: meldung.notizen[delta].id,
//        });
//      }
//      data.relationships.dokumente = {
//        data: [],
//      };
//      for (let delta in meldung.dokumente) {
//        data.relationships.dokumente.data.push({
//          type: 'file--file',
//          id: meldung.dokumente[delta],
//        });
//      }
//      data.attributes.beschreibung = {
//        value: '',
//      };
//      if (typeof (meldung.beschreibung) == 'string') {
//        data.attributes.beschreibung.value = meldung.beschreibung;
//      }
//      if (typeof (meldung.beschreibung) == 'object' 
//                      && meldung.beschreibung.value !== null 
//                      && meldung.beschreibung.value !== undefined) {
//        data.attributes.beschreibung.value = meldung.beschreibung.value;
//      }
//      if (meldung.versicherungsvertragDatum !== undefined
//            && meldung.versicherungsvertragDatum.beginn !== ''
//            && meldung.versicherungsvertragDatum.ende !== '') {
//        data.attributes.versicherungsvertrag_gultigkeit = {
//          value: this.getDateString(meldung.versicherungsvertragDatum.beginn),
//          end_value: this.getDateString(meldung.versicherungsvertragDatum.ende),
//        };
//      }
//      let dateFields = ['schadensdatum', 'schadenseingang', 'wiedervorlage', 'schadenmeldung_an_versicherungsm', 'schadensmeldung_an_versicherung'];
//      for (let i in dateFields) {
//        if (meldung[dateFields[i]] !== undefined && meldung[dateFields[i]] !== '') {
//          data.attributes[dateFields[i]] = {
//            value: this.getDateString(meldung[dateFields[i]]),
//          }
//        }
//      }
//      if (meldung.id !== undefined && meldung.id !== null && meldung.id !== '') {
//        data.id = meldung.id;
//      }
//      if (meldung.gesehen !== undefined) {
//        data.relationships.gesehen = {
//          data: [],
//        };
//        for (let i in meldung.gesehen) {
//          data.relationships.gesehen.data.push({
//            type: 'user--user',
//            id: meldung.gesehen[i],
//          });
//        }
//      }
//      data.relationships.stichwort = {
//        data: [],
//      };
//      this.stichwort.getAll().then((response: Array<{
//        id: string
//        attributes: {
//          name: string
//        }
//      }>) => {
//        for (let d in meldung.stichwort) {
//          for (let i in response) {
//            if (meldung.stichwort[d] == response[i].attributes.name) {
//              data.relationships.stichwort.data.push({
//                type: 'taxonomy_term--stichwort',
//                id: response[i].id,
//              });
//            }
//          }
//        }
//        resolve(data);
//      }).catch(() => {
//        console.warn('Stichworte können nicht gelöst werden');
//        resolve(data);
//      });
//    });
//    return promise;
//  }
//  
//  private getDateString(original: any) {
//    if (typeof(original) == 'string') {
//      return original;
//    }
//    else if (typeof(original) == 'object' && original !== null) {
//      return original.format('YYYY-MM-DD');
//    }
//    else {
//      return null;
//    }
//  }
//  
//  getAll() {
//    let promise = new Promise((resolve, reject) => {
//      let path = 'ubg_schadenmeldung/ubg_schadenmeldung';
//      this.contenta.getJsonApi(path).then((response: any) => {
//        let meldungen: Array<Schadenmeldung> = [];
//        for (let i in response) {
//          meldungen.push(this.rawToMeldung(response[i]));
//        }
//        this.contenta.getJsonApi('taxonomy_term/stichwort').then((response: Array<{
//          id: string
//          attributes: {
//            name: string
//          }
//        }>) => {
//          for (let i in response) {
//            for (let n in meldungen) {
//              for (let d in meldungen[n].stichwort) {
//                if (response[i].id == meldungen[n].stichwort[d]) {
//                  meldungen[n].stichwort[d] = response[i].attributes.name;
//                }
//              }
//            }
//          }
//          resolve(meldungen);
//        }).catch((error) => {
//          console.warn('Stichworte können nicht gelöst werden');
//          resolve(meldungen);
//        });
//      }).catch((error) => {
//        reject(error);
//      });
//    });
//    return promise;
//  }
//  
//  getByGliederung(gliederungId: string) {
//    let promise = new Promise((resolve, reject) => {
//      //let path = 'ubg_schadenmeldung/ubg_schadenmeldung?filter[gliederung_referenz.uuid]=' + gliederungId;
//      let path = 'ubg_schadenmeldung/ubg_schadenmeldung';
//      if (gliederungId == '' || gliederungId == null || gliederungId == undefined) {
//        console.warn('Gliederung-ID ist leer');
//        reject();
//      }
//      this.contenta.getJsonApi(path).then((response: any) => {
//        let meldungen: Array<{
//          stichwort: Array<string>
//        }> = [];
//        for (let i in response) {
//          meldungen.push(this.rawToMeldung(response[i]));
//        }
//        this.contenta.getJsonApi('taxonomy_term/stichwort').then((response: Array<{
//          id: string
//          attributes: {
//            name: string
//          }
//        }>) => {
//          for (let i in response) {
//            for (let n in meldungen) {
//              for (let d in meldungen[n].stichwort) {
//                if (response[i].id == meldungen[n].stichwort[d]) {
//                  meldungen[n].stichwort[d] = response[i].attributes.name;
//                }
//              }
//            }
//          }
//          resolve(meldungen);
//        }).catch((error) => {
//          console.warn('Stichworte können nicht gelöst werden');
//          resolve(meldungen);
//        });
//      }).catch((error) => {
//        reject(error);
//      });
//    });
//    return promise;
//  }
//  
              
  getFiltered(filters: any) {
    let promise = new Promise((resolve, reject) => {
      let params = new HttpParams();
      for (let filterName in filters) {
        for (let propertyName in filters[filterName]) {
          for (let itemName in filters[filterName][propertyName]) {
            params = params.set('filter[' + filterName + '][' + propertyName + '][' + itemName + ']', filters[filterName][propertyName][itemName]);
          }
        }
      }
      let path: string = 'api/ubg_schadenmeldung/ubg_schadenmeldung?' + params.toString();
      this.contenta.get(path).then((response: {
        data: Array<Schadenmeldung>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      })
    });
    return promise;
  }
//
//  getFiltered(filters:{[key: string]: {
//    group?: {
//      conjunction: string
//    },
//    condition?: {
//      path: string
//      value: string
//      operator: string
//    }
//  }}) {
//    let promise = new Promise((resolve, reject) => {
//      let params = new HttpParams();
//      for (let filterName in filters) {
//        for (let propertyName in filters[filterName]) {
//          for (let itemName in filters[filterName][propertyName]) {
//            params = params.set('filter[' + filterName + '][' + propertyName + '][' + itemName + ']', filters[filterName][propertyName][itemName]);
//          }
//        }
//      }
//      this.contenta.get('api/ubg_schadenmeldung/ubg_schadenmeldung?' + params.toString()).then((response: {
//        data: Array<any>,
//      }) => {
//        let meldungen: Array<Schadenmeldung> = [];
//        for (let i in response.data) {
//          meldungen.push(this.rawToMeldung(response.data[i]));
//        }
//        resolve(meldungen);
//      }).catch((error) => {
//        reject(error);
//      });
//    });
//    return promise;
//  }
//  
//  get(uuid: string) {
//    let promise = new Promise((resolve, reject) => {
//      let path = 'ubg_schadenmeldung/ubg_schadenmeldung/' + uuid;
//      this.contenta.getJsonApi(path).then((response: any) => {
//        let meldung = this.rawToMeldung((response));
//        this.contenta.getJsonApi('taxonomy_term/stichwort').then((response: Array<{
//          id: string
//          attributes: {
//            name: string
//          }
//        }>) => {
//          for (let i in response) {
//            for (let d in meldung.stichwort) {
//              if (response[i].id == meldung.stichwort[d]) {
//                meldung.stichwort[d] = response[i].attributes.name;
//              }
//            }
//          }
//          resolve(meldung);
//        }).catch((error) => {
//          console.warn('Stichworte können nicht gelöst werden');
//          resolve(meldung);
//        });
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
//  
  /**
   * Einen Fall laden
   */
  get(meldungId: string) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldungId;
      this.contenta.get(path).then((response: {
        data: Schadenmeldung
      }) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  getByToken(meldungId: string, token: string) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldungId;
      this.contenta.get(path, [{
        key: 'X-Schaden-Token',
        value: token,
      }]).then((response: {
        data: Schadenmeldung
      }) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
//  
//  create() {
//    return {
//      id: '',
//      title: '',
//      gliederung_referenz: '',
//      gliederung: '',
//      abteilung: '',
//      ansprechpartner: '',
//      strasse: '',
//      plz: '',
//      ort: '',
//      e_mail: '',
//      telefon: '',
//      anspruchsteller_name: '',
//      anspruchsteller_cdu: false,
//      anspruchsteller_ansprechpartner: '',
//      anspruchsteller_strasse: '',
//      anspruchsteller_plz: '',
//      anspruchsteller_ort: '',
//      anspruchsteller_e_mail: '',
//      anspruchsteller_telefon: '',
//      sparte: '',
//      versicherungsgesellschaft: '',
//      vg_ansprechpartner: '',
//      vg_strasse: '',
//      vg_plz: '',
//      vg_ort: '',
//      versicherungsvertrag: '',
//      versicherungsvertragDatum: {
//        beginn: '',
//        ende: '',
//      },
//      stichwort: [],
//      beschreibung: {
//        value: '',
//      },
//      schadensnummer: '',
//      schadensdatum: '',
//      schadenseingang: '',
//      status: '0',
//      gesehen: [],
//      geschlossen: null,
//      notizen: [],
//      regulierte_schadenshohe: 0,
//      entityId: null,
//      dokumente: [],
//      cloud: false,
//      cloud_id: '',
//      cloud_password: '',
//    };
//  }
//  
//  update(meldung: Schadenmeldung) {
//    let promise = new Promise((resolve, reject) => {
//      this.meldungToRaw(meldung).then((response: any) => {
//        let path = 'api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldung.id;
//        this.contenta.patch(path, {
//          data: response
//        }).then(() => {
//          resolve();
//          }).catch((e) => {
//            reject(e);
//          });
//      }).catch((e) => {
//        reject(e);
//      });
//    });
//    return promise;
//  }
//  
//  add(meldung: Schadenmeldung) {
//    let promise = new Promise((resolve, reject) => {
//      this.meldungToRaw(meldung).then((response: any) => {
//        let path = 'api/ubg_schadenmeldung/ubg_schadenmeldung';
//        console.debug('ADD');
//        this.contenta.post(path, {
//          data: response
//        }).then((response) => {
//          resolve(response);
//          }).catch(() => {
//            reject();
//          });
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
//  
//  delete(meldung: Schadenmeldung) {
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.delete('api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldung.id).then((response: any) => {
//        resolve();
//      }).catch((error) => {
//        console.error(error);
//        reject(error);
//      });
//    });
//    return promise;
//  }
//  
//  getDatenblatt(meldung: Schadenmeldung) {
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.getPostFile('ubg/schadenmeldung/' + meldung.id + '/datenblatt', null).then((response: Blob) => {
//        resolve(response);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
//  
//  getVersicherungDatenblatt(meldung: Schadenmeldung) {
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.getPostFile('ubg/schadenmeldung/' + meldung.id + '/datenblatt/versicherung', null).then((response: Blob) => {
//        resolve(response);
//      }).catch(() => {
//        reject();
//      });
//    });
//    return promise;
//  }
  
  /**
   * Ob der Fall gültig und zu speichern ist
   */
  public isValid(meldung: Schadenmeldung): boolean {
    if (meldung.attributes === undefined) {
      return false;
    }
    if (meldung.attributes.gliederung === null 
          || meldung.attributes.gliederung === undefined
          || meldung.attributes.gliederung === '') {
      return false;
    }
    if (meldung.relationships.sparte.data === null
      || meldung.relationships.sparte.data.id === undefined) {
      return false;
    }
    return true;
  }
  
  private prepare(meldung: Schadenmeldung): Schadenmeldung {
    let d: Date;
    let dateFields: Array<string> = [
      'schadensdatum',
      'schadenseingang',
      'schadenmeldung_an_versicherungsm',
      'wiedervorlage',
      'schadensmeldung_an_versicherung',
    ];
    for (let i in dateFields) {
      if (meldung.attributes[dateFields[i]] !== undefined && meldung.attributes[dateFields[i]] !== null) {
        d = new Date(meldung.attributes[dateFields[i]]);
        d.setHours(12);
        meldung.attributes[dateFields[i]] = d.toISOString().substring(0, 10);
      }
    }
    return meldung;
  }
  
  public create(meldung: Schadenmeldung) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/ubg_schadenmeldung/ubg_schadenmeldung';
      this.contenta.post(path, {
        data: this.prepare(meldung),
      }).then((response: {
        data: Schadenmeldung
      }) => {
        let createdMeldung = response.data as Schadenmeldung;
        resolve(createdMeldung);
      }).catch((error: HttpErrorResponse) => {
        if (error.status  == 422) {
          if (error.error['errors'] === undefined) {
            reject();
          }
          else {
            for (let i in error.error['errors']) {
              if (error.error['errors'][i]['status'] == 422) {
                reject(error.error['errors'][i]['detail']);
              }
            }
          }
        }
        else {
          reject();
        }
      });
    });
    return promise;
  }
  
  public updateByToken(meldung: Schadenmeldung, token: string) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldung.id;
      this.contenta.patch(path, {
        data: this.prepare(meldung),
      }, [{
        key: 'X-Schaden-Token',
        value: token,
      }]).then((response: {
        data: Schadenmeldung
      }) => {
        resolve(response.data);
      }).catch((error: HttpErrorResponse) => {
        if (error === undefined) {
          console.error('-- UNDEFINED ERROR --');
        }
        else if (error.status  == 422) {
          if (error.error['errors'] === undefined) {
            reject();
          }
          else {
            for (let i in error.error['errors']) {
              if (error.error['errors'][i]['status'] == 422) {
                reject(error.error['errors'][i]['detail']);
              }
            }
          }
        }
        else {
          reject();
        }
      });
    });
    return promise;
  }
  
  /**
   * Einen vorhandenen Fall aktualisieren
   */
  public update(meldung: Schadenmeldung) {
    let promise = new Promise((resolve, reject) => {
      let path: string = 'api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldung.id;
      this.contenta.patch(path, {
        data: this.prepare(meldung),
      }).then((response: {
        data: Schadenmeldung
      }) => {
        resolve(response.data);
      }).catch((error: HttpErrorResponse) => {
        if (error === undefined) {
          console.error('-- UNDEFINED ERROR --');
        }
        else if (error.status  == 422) {
          if (error.error['errors'] === undefined) {
            reject();
          }
          else {
            for (let i in error.error['errors']) {
              if (error.error['errors'][i]['status'] == 422) {
                reject(error.error['errors'][i]['detail']);
              }
            }
          }
        }
        else if (error.status  == 403) {
          if (error.error['errors'] === undefined) {
            reject();
          }
          else {
            for (let i in error.error['errors']) {
              if (error.error['errors'][i]['status'] == 403) {
                reject(error.error['errors'][i]['detail']);
              }
            }
          }
        }
        else {
          reject();
        }
      });
    });
    return promise;
  }
  
  public getFile(meldung: Schadenmeldung, documentId: string) {
    let promise = new Promise((resolve, reject) => {
      let path = 'ubg/schadenmeldung/' + meldung.id + '/dokument/' + documentId;
      this.contenta.getPostFile(path, null).then((response: Blob) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      })
    });
    return promise;
  }
  
  public login(meldungId: string, password: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.updateToken({
        meldung: meldungId,
        password: password,
      }).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getDatenblatt(meldung: Schadenmeldung) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getPostFile('ubg/schadenmeldung/' + meldung.id + '/datenblatt', null).then((response: Blob) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getVersicherungDatenblatt(meldung: Schadenmeldung) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getPostFile('ubg/schadenmeldung/' + meldung.id + '/datenblatt/versicherung', null).then((response: Blob) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  delete(meldung: Schadenmeldung) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.delete('api/ubg_schadenmeldung/ubg_schadenmeldung/' + meldung.id).then((response: any) => {
        resolve(null);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }

  /**
   * Statistische Übersicht
   */
  getMeldungStat(): Promise<any> {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/schaden/stat').then((response: any) => {
        resolve(response);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }

  /**
   * Schadenmeldungen nach Kategorie
   * @param category 
   * @returns Array<Schadenmeldung>
   */
  getMeldungenByCategory(category: string): Promise<Array<Schadenmeldung>> {
    const path: string = 'ubg/schaden/' + category;
    let promise = new Promise<Array<Schadenmeldung>>((resolve, reject) => {
      this.contenta.get(path).then((response: {
        data: Array<Schadenmeldung>
      }) => {
        resolve(response.data);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }

  /**
   * Meldung als gesehen markieren
   * @param meldung 
   */
  setMeldungGesehen(meldung: Schadenmeldung): Promise<void> {
    const path: string = 'ubg/schadenmeldung/' + meldung.id + '/gesehen';
    let promise = new Promise<void>((resolve, reject) => {
      this.contenta.post(path, null).then(() => {
        resolve();
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }  

}