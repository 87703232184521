/**
 * Sidebar Service
 * Attila Németh, UBG
 * 03.09.2018
 */
 
import { Injectable, ChangeDetectorRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  opened: boolean

  constructor() {
    this.opened = false;
  }
  
  open() {
    this.opened = true;
  }
  
  close() {
    this.opened = false;
  }
  
}
