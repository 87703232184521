/**
 * Datenblatt-Komponent
 * 
 * Attila Németh, UBG
 * 20.04.2020
 */
 
import {Component, Input} from '@angular/core';

import {MatSnackBar} from '@angular/material';

import {Schadenmeldung} from'../../../../../model/meldung';
import {SchadenmeldungService} from'../../../../../services/meldung.service';

@Component({
  selector: 'ubg-schadenmeldung-datenblatt',
  templateUrl: './datenblatt.component.html',
})
export class SchadenmeldungDatenblattComponent {
  
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  meldungObject: Schadenmeldung = null
  
  isLoading: boolean = false
  
  constructor(private snackbar: MatSnackBar,
                private service: SchadenmeldungService) {}
  
  getDatenblatt() {
    this.isLoading = true;
    this.service.getDatenblatt(this.meldungObject).then((response: Blob) => {
      this.isLoading = false;
      let fileName = 'datenblatt_' + this.meldungObject.attributes.schadensnummer.toLowerCase() + '.pdf'
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch((error) => {
      console.error(error);
      this.isLoading = false;
      this.snackbar.open('Ein Fehler ist aufgetreten. Code: DB-49', null, {
        duration: 15000,
      });
    });
  }
  
  getDatenblattVers(): void {
    this.isLoading = true;
    this.service.getVersicherungDatenblatt(this.meldungObject).then((response: Blob) => {
      this.isLoading = false;
      let fileName = 'datenblatt_vers_' + this.meldungObject.attributes.schadensnummer.toLowerCase() + '.pdf'
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch(() => {
      this.isLoading = false;
      this.snackbar.open('Ein Fehler ist aufgetreten. Code: DB-79', null, {
        duration: 12000,
      });
    });   
  }
   
}