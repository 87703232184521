/**
 * Liste der Versicherungsverträge
 * Attila Németh, UBG
 * 10.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import {UbgFabComponent} from '../components/ubg_fab.component';

import {Versicherungsvertrag} from '../model/vvertrag';
import {VersicherungsvertragService} from '../services/vvertrag.service';
import {HeaderService} from '../services/header.service';

@Component({
  selector: 'app-versicherungsfirma-list',
  templateUrl: './list.component.html',
})
export class VersicherungsVertragListComponent implements OnInit {

  vertraege: Array<Versicherungsvertrag>
  columnsToDisplay = ['open', 'number', 'sparte', 'vfirma'];
  
  constructor(private router: Router,
              private vvertrag: VersicherungsvertragService,
              private header: HeaderService) {
  }
  
  ngOnInit() {
    this.header.setTitle('Versicherungsverträge');
    this.vvertrag.getAll().then((response: any) => {
      this.vertraege = response;
    }).catch(() => {
      this.vertraege = [];
    });
  }  
  
  open(vertrag: Versicherungsvertrag) {
    this.router.navigate(['versicherung/vertrag/' + vertrag.id]);
  }
  
  add() {
    this.router.navigate(['versicherung/vertrag/add']);
  }

}