/**
 * Komibinerte Anmeldung
 * 
 * Attila Németh, UBG
 * 28.01.2020
 */
 
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ContentaUser} from '../../model/contenta_user';
import {ContentaUserService} from '../../services/contenta_user.service';
import {MatSnackBar} from '@angular/material';

import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'ubg-combined-login',
  templateUrl: './combined_login.component.html',
  styleUrls: ['./combined_login.component.scss'],
})
export class UbgCombinedLoginComponent implements OnInit {

  user: ContentaUser = new ContentaUser()
  isLoggingIn: boolean = false
    
  constructor(private router: Router,
                private snackbar: MatSnackBar,
                private service: ContentaUserService) {}
  
  ngOnInit() {
    let cookies: Array<string> = document.cookie.split(';');
    let cookieData: Array<string> = [];
    for (let i in cookies) {
      cookieData = cookies[i].trim().split('=');
      if (cookieData[0] == 'refresh_token') {
        this.isLoggingIn = true;
        this.service.refreshLogin(cookieData[1]).then(() => {
          this.isLoggingIn = false;
        }).catch(() => {
          this.isLoggingIn = false;
          console.error('Anmeldung ist fehlgeschlagen');
        });
      }
    }
  }
                
  userLogin(): void {
    this.service.login(this.user).then(() => {
      this.router.navigate(['']);
      this.snackbar.open('Sie haben sich angemeldet', '', {
        duration: 5000,
      });
    }).catch(() => {
      this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', '', {
        duration: 12000,
      });
    });
  }
  
  /**
   * Weiterleitung an CDUplus, wo der Benutzer sich anmelden kann. 
   * Nach der Anmeldung wird zu /cduplus/auth weiterleitet s. das Komponent ContentaUserDialogCduplusComponent
   * 
   * 07.01.2019
   */
  userCduplusLogin() {
    const state = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
    let d = new Date();
    d.setTime(d.getTime() + 600000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'cduplus_state=' + state + ';' + expiresString + ";path=/";
    let url = 'https://www.cduplus.cdu.de/oauth2/authorize?response_type=code&scope=profile+offline_access+cduplus_auth+email+openid&client_id=' + environment.cduplus.clientId + '&state=' + state;
    document.location.href = url;
  }
 
}