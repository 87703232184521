/**
 * Spinner-Modul
 * 
 * Attila Németh
 * 13.12.2018
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {SpinnerComponent} from './components/spinner/spinner.component';

@NgModule({
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    SpinnerComponent,
  ],
  exports: [
    SpinnerComponent,
  ],
  entryComponents: [
  ],
})
export class UbgSpinnerModule {

  constructor() {}

}