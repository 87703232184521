/**
 * Notizeninhalt
 * Attila Németh, UBG
 * 25.10.2018
 */
 
import { Component, Inject } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

import {Notiz} from '../../model/notiz';
import {Schadenmeldung} from '../../model/meldung';
import {ContentaService} from '../../services/contenta.service';

@Component({
  templateUrl: './form_dialog_details.html',
})
export class UbgFormDialogDetailsComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    notiz: Notiz
    meldung: Schadenmeldung
  },
  private contenta: ContentaService) {
    
  }
  
  propertyExists(propertyName: string) {
    if (this.data.notiz[propertyName] === undefined) {
      return false;
    }
    if (this.data.notiz[propertyName] === null) {
      return false;
    }
    if (this.data.notiz[propertyName] === '') {
      return false;
    }
    return true;
  }
  
  getNotizPdf() {
    console.error('Form Dialog Details');
//    this.contenta.getPostFile('ubg/api/notiz/brief', {
//      template: this.data.notiz.template, 
//      text: this.data.notiz.text,
//      meldung: this.data.meldung.id,
//    }).then((response) => {
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, 'brief.pdf');
//      } 
//      else {
//        a.href = url;
//        a.download = 'brief.pdf';
//        a.click();
//      }
//    }).catch((error) => {
//      console.error(error);
//    });
  }
  
  getFiles() {
    if (this.data.notiz.relationships !== undefined
      && this.data.notiz.relationships.datei !== undefined) {
        return this.data.notiz.relationships.datei;
    }
    else {
      return [];
    }
  }
  
}