/**
 * UBG Tabs - Header
 * Attila Németh, UBG
 * 19.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ubg-tab-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class UbgTabHeaderComponent implements OnInit {
  
  ngOnInit() {
    
  }
  
}