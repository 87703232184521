/**
 * Liste mit Suche
 * Attila Németh, UBG
 * 27.09.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {HeaderService} from '../services/header.service';
import {Schadenmeldung} from '../model/meldung';
import {SchadenmeldungService} from '../services/meldung.service';
import {Sparte} from '../model/sparte';
import {SparteService} from '../services/sparte.service';
import {Gliederung} from '../model/gliederung';
import {GliederungService} from '../services/gliederung.service';
import {StichwortService} from '../services/stichwort.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit {
  
  filters: {
    gliederung: string      // Gliederung UUID
    datumVon: any
    datumBis: any
    status: string
    stichwort: string
    schadensnummer: string
    schadensnummer_der_versicherung: string
    anspruchsteller: string
  }
  options: {
    gliederung: Array<{
      id: string
      value: string
    }>
    stichwort: Array<string>
  }
  filteredOptions: {
    stichwort: Array<string>
  }
  meldungen: Array<Schadenmeldung>
  sparten: Array<Sparte>
  columnsToDisplay = ['status', 'gliederung', 'datum', 'sparte', 'summe', 'op'];
  isFiltered: boolean
  keyUpTimeout: any
  
  constructor(private route: ActivatedRoute,
              private router: Router,
              private header: HeaderService,
              private service: SchadenmeldungService,
              private sparte: SparteService,
              private gliederung: GliederungService,
              private stichwort: StichwortService) { 
    this.filters = {
      gliederung: null,
      datumVon: null,
      datumBis: null,
      status: null,
      stichwort: null,
      schadensnummer: null,
      schadensnummer_der_versicherung: null,
      anspruchsteller: null,
    };
    this.meldungen = [];
    this.options = {
      gliederung: [],
      stichwort: [],
    };
    this.filteredOptions = {
      stichwort: [],
    };
    this.isFiltered = false;
  }
  
  ngOnInit() {
    this.header.setTitle('Liste');
    this.header.addIcon({
      name: 'listFilter',
      icon: 'filter_list',
    });
    this.route.params.subscribe((params: {
      gliederungId: string
    }) => {
      for (let paramName in params) {
        if (params[paramName] !== undefined && params[paramName] !== 'undefined') {
          if (paramName == 'datumVon' || paramName == 'datumBis') {
            let d = new Date(params[paramName] * 1);
            this.filters[paramName] = d;
          }
          else if (paramName == 'gliederungId' && params.gliederungId !== '') {
            this.filters.gliederung = params.gliederungId;
//            this.gliederung.get(params.gliederungId).then((response: Gliederung) => {
//              this.filters.gliederung = response.id;
//              this.update();
//            });
          }
          else {
            this.filters[paramName] = params[paramName];
          }
        }
      }
    });
    this.update();
    this.gliederung.getAll().then((response: Array<Gliederung>) => {
      this.options.gliederung = [];
      for (let i in response) {
        this.options.gliederung.push({
          id: response[i].id,
          value: response[i].name,
        });
      }
    });
    this.stichwort.getAll().then((response: Array<{
      attributes: {
        name: string
      }
    }>) => {
      this.options.stichwort = [];
      this.filteredOptions.stichwort = [];
      for (let i in response) {
        this.options.stichwort.push(response[i].attributes.name);
        this.filteredOptions.stichwort.push(response[i].attributes.name);
      }
    });
  }
  
  gliederungName(gliederung: {
    id: string
    value: string
  }) {
    if (gliederung !== null && gliederung !== undefined) {
      return gliederung.value;
    }
    else {
      return null;
    }
  }

  updateKeyup() {
    clearTimeout(this.keyUpTimeout);
    this.keyUpTimeout = setTimeout(() => {
      this.update();
    }, 500);
  }
  
  update() {
    let filters: {[key: string]: {
      group?: {
        conjunction: string
      },
      condition?: {
        path: string
        value: string
        operator: string
        memberOf?: string
      }
    }} = {};
    if (this.filters.gliederung !== null && this.filters.gliederung !== undefined) {
      filters.gliederung = {
        condition: {
          path: 'gliederung_referenz.id',
          value: this.filters.gliederung,
          operator: '=',
        }
      };
    }
    if (this.filters.datumVon !== null) {
      if (this.filters.datumVon instanceof Date) {
        filters.datumVon = {
          condition: {
            path: 'schadensdatum',
            value: this.filters.datumVon.getFullYear() + '-' + ('0' + (this.filters.datumVon.getMonth() + 1)).slice(-2) + '-' + ('0' + this.filters.datumVon.getDate()).slice(-2),
            operator: '>=',
          }
        };
      }
      else if (typeof this.filters.datumVon == 'object') {
        filters.datumVon = {
          condition: {
            path: 'schadensdatum',
            value: this.filters.datumVon.format('YYYY-MM-DD'),
            operator: '>=',
          }
        };
      }
    }
    if (this.filters.datumBis !== null) {
      if (this.filters.datumBis instanceof Date) {
        filters.datumBis = {
          condition: {
            path: 'schadensdatum',
            value: this.filters.datumBis.getFullYear() + '-' + ('0' + (this.filters.datumBis.getMonth() + 1)).slice(-2) + '-' + ('0' + this.filters.datumBis.getDate()).slice(-2),
            operator: '<=',
          },
        };
      }
      else if (typeof this.filters.datumBis == 'object') {
        filters.datumBis = {
          condition: {
            path: 'schadensdatum',
            value: this.filters.datumBis.format('YYYY-MM-DD'),
            operator: '<=',
          },
        };
      }
    }
    if (this.filters.status !== null) {
      filters.status = {
        condition: {
          path: 'status',
          value: this.filters.status,
          operator: '=',
        },
      };
    }
    if (this.filters.stichwort !== null) {
      filters.stichwort = {
        condition: {
          path: 'stichwort.name',
          value: this.filters.stichwort,
          operator: '=',
        },
      };
    }
    if (this.filters.schadensnummer !== null) {
      filters.schadensnummer = {
        condition: {
          path: 'schadensnummer',
          value: this.filters.schadensnummer,
          operator: '=',
        },
      };
    }
    if (this.filters.schadensnummer_der_versicherung !== null) {
      filters.schadensnummer_der_versicherung = {
        condition: {
          path: 'schadensnummer_der_versicherung',
          value: this.filters.schadensnummer_der_versicherung,
          operator: '=',
        },
      };
    }
    if (this.filters.anspruchsteller !== null) {
      filters.anspruchsteller = {
        group: {
          conjunction: 'OR',
        },
      };
      filters.anspruchsteller_name = {
        condition: {
          path: 'anspruchsteller_name',
          value: this.filters.anspruchsteller,
          operator: 'CONTAINS',
          memberOf: 'anspruchsteller',
        },
      };
      filters.anspruchsteller_ansprechpartner = {
        condition: {
          path: 'anspruchsteller_ansprechpartner',
          value: this.filters.anspruchsteller,
          operator: 'CONTAINS',
          memberOf: 'anspruchsteller',
        },
      };
    }
    if (Object.keys(filters).length > 0) {
      this.isFiltered = true;
    }
    else {
      this.isFiltered = false;
    }
    this.service.getFiltered(filters).then((response: Array<Schadenmeldung>) => {
      this.meldungen = response;
    }).catch((error) => {
      console.error(error);
    });
    this.sparte.getAll().then((response: Array<Sparte>) => {
      this.sparten = response
    }).catch((error) => {
      console.error(error);
    });
  }
  
  getStatusClass(meldung: Schadenmeldung) {
    switch (meldung.attributes.status) {
      case 0:
        return 'status-open';
        break;
      case 1:
        return 'status-closed';
        break;
      case -1:
        return 'status-refused';
        break;
      default:
        return 'status-error';
        break;
    }
  }
  
  getStatusIcon(meldung: Schadenmeldung) {
    switch (meldung.attributes.status) {
      case 0:
        return 'assignment';
        break;
      case 1:
        return 'assignment_turned_in';
        break;
      case -1:
        return 'assignment_return';
        break;
      default:
        console.warn('Kein Ikon für Status gefunden', meldung.attributes.status);
        return 'error';
        break;
    }
  }
  
  getDatum(meldung: Schadenmeldung) {
    let d = new Date(meldung.attributes.schadensdatum);
//    let d = new Date(meldung.schadensdatum * 1000);
    return d.toLocaleDateString();
  }
  
  getSparte(meldung: Schadenmeldung) {
    for (let i in this.sparten) {
      if (this.sparten[i].id == meldung.relationships.sparte.data.id) {
        return this.sparten[i].attributes.name;
      }
    }
    return '';
  }
  
  getSumme() {
    let s: number = 0;
    for (let i in this.meldungen) {
      if (this.meldungen[i].attributes.regulierte_schadenshohe !== null) {
        s += this.meldungen[i].attributes.regulierte_schadenshohe * 1;
      }
    }
    return s;
  }
  
  reset() {
    this.filters = {
      gliederung: null,
      datumVon: null,
      datumBis: null,
      status: null,
      stichwort: null,
      schadensnummer: null,
      schadensnummer_der_versicherung: null,
      anspruchsteller: null,
    };
    this.gliederung.getAll().then((response: Array<Gliederung>) => {
      this.options.gliederung = [];
      for (let i in response) {
        this.options.gliederung.push({
          id: response[i].id,
          value: response[i].name,
        });
      }
    });
    this.update();
  }
  
//  gliederungSelected(event: {
//    option: {
//      value: {
//        id: string
//        value: string
//      }
//    }
//  }) {
//    this.filters.gliederung = event.option.value.id;
//    this.update();
//  }
  
  updateOptionsStichwort() {
    this.filteredOptions.stichwort = [];
    for (let i in this.options.stichwort) {
      if (this.options.stichwort[i].toLowerCase().indexOf(this.filters.stichwort.toLowerCase()) >= 0) {
        this.filteredOptions.stichwort.push(this.options.stichwort[i]);
      }
    }
  }
  
  openMeldung(meldung: Schadenmeldung) {
    this.router.navigate(['meldung/' + meldung.id]);
  }
  
  download() {
    let content: string = '\uFEFF';
    let data = [
      ['Gliederung', 'Datum', 'Sparte', 'Entschädigung', 'Status'],
    ];
    for (let i in this.meldungen) {
      let row = [
        this.meldungen[i].attributes.gliederung,
        this.getDatum(this.meldungen[i]),
        this.getSparte(this.meldungen[i])
      ];
      if (this.meldungen[i].attributes.regulierte_schadenshohe === null) {
        row.push('');
      }
      else {
        row.push(this.meldungen[i].attributes.regulierte_schadenshohe.toString());
      }
      switch (this.meldungen[i].attributes.status) {
        case 0:
          row.push('Offen');
          break;
        case 1:
          row.push('Geschlossen');
          break;
        case -1:
          row.push('Abgelehnt');
          break;
        default:
          row.push('Unbekannt');
      }
      data.push(row);
    }
    for (let i in data) {
      content += data[i].join(';') + "\n";
    }
    let blob = new Blob([content], { 
      type: 'text/csv;charset=utf-8',
    });
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = 'schadenmeldungen.csv';
    a.click();
  }
  
  gliederungSelected(event) {
    this.filters.gliederung = event;
    this.update();
  }
  
}