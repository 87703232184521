import { Component } from '@angular/core'; 

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatSnackBar} from '@angular/material';

import {ContentaUserService} from './modules/contenta_user/services/contenta_user.service';
import {SidebarService} from './services/sidebar.service';
import {HeaderService} from './services/header.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  
  title = 'schadenmeldung';

  
  activeTab: number   
  
  constructor(private user: ContentaUserService,
              private sidebar: SidebarService,
              private header: HeaderService,
              private snackbar: MatSnackBar,
              private router: Router,
              private route: ActivatedRoute) {
    this.activeTab = 0;
  }
  
  getTabClass(tab: number) {
    if (tab === this.activeTab) {
      return 'active';
    }
    else {
      return '';
    }
  }
  
  contentVisible() {
    if (this.user.loggedIn) {
      return true;
    }
    if (this.router.url.indexOf('/user/reset') == 0) {
      return true;
    }
    if (this.router.url.indexOf('/cduplus/auth') == 0) {
      return true;
    }
    if (this.router.url.indexOf('/admin/login') == 0) {
      return true;
    }
    if (this.router.url.indexOf('/admin/login') == 0) {
      return true;
    }
    if (this.router.url.match(/fall\/(.*?)\/formular$/g)) {
      return true;
    }
    return false;
  }
  
//  registerUser() {
//    this.user.register().then(() => {
//      this.snackbar.open('Die Registration war erfolgreich', '', {
//        duration: 8000,
//      });
//    }).catch(() => {
//      this.snackbar.open('Die Registration war nicht erfolgreich', '', {
//        duration: 8000,
//      });
//    });
//  }
  
//  loginUser() {
//    this.user.login().then(() => {
//      this.snackbar.open('Sie haben sich angemeldet', '', {
//        duration: 5000,
//      });
//    }).catch((error) => {
//      this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', '', {
//        duration: 12000,
//      });
//    })
//  }
  
  logoutUser() {
    this.sidebar.opened = false;
    this.user.logout();
    this.router.navigate(['']);
  }
  
  sidebarToggle() {
    if (this.sidebar.opened) {
      this.sidebar.opened = false;
    }
    else {
      this.sidebar.opened = true;
    }
  }
  
}
