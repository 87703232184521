/**
 * Sparte-Service
 * Attila Németh, UBG
 * 27.09.2018
 */
 
import { Injectable } from '@angular/core';

import {Sparte} from '../model/sparte';

import {ContentaService} from './contenta.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class SparteService {

  constructor(private contenta: ContentaService,
              private user: UserService) {
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/sparte').then((response: {
        data: Array<Sparte>
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.getJsonApi('taxonomy_term/sparte').then((response: Array<{
//        id: string
//        attributes: {
//          name: string
//        }
//      }>) => {
//        let sp: Array<Sparte> = [];
//        for(let i in response) {
//          sp.push({
//            id: response[i].id,
//            name: response[i].attributes.name,
//          });
//        }
//        resolve(sp);
//      }).catch((error) => {
//        reject(error);
//      });
//    });
//    return promise;
  }
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/taxonomy_term/sparte/' + id).then((response: {
        data: Sparte
      }) => {
        resolve(response.data);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}
