/**
 * Kopfbereich-Service
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private title: string
  private icons: Array<{
    name: string
    icon: string
    active: boolean
  }>
  
  constructor() {
    this.title = '';
    this.icons = [];
  }
  
  setTitle(title: string) {
    this.title = title;
    this.icons = [];
  }
  
  getTitle() {
    if (this.title == '') {
      return 'Versicherungsportal';
    }
    else {
      return this.title;
    }
  }
  
  addIcon(icon: {
    name: string
    icon: string
  }) {
    let iconExists: boolean = false;
    for (let i in this.icons) {
      if (this.icons[i].name == icon.name) {
        iconExists = true;
        break;
      }
    }
    if (!iconExists) {
      this.icons.push({
        name: icon.name,
        icon: icon.icon,
        active: false
      });
    }
  }
  
  getIconClass(icon: {
    active: boolean;
  }) {
    if (icon.active) {
      return 'icon-active';
    }
    else {
      return '';
    }
  }
  
  toggleIcon(icon: {
    active: boolean
  }) {
    if (icon.active) {
      icon.active = false;
    }
    else {
      icon.active = true;
    }
  }
  
  activeIcon(name: string) {
    for (let i in this.icons) {
      if (this.icons[i].name == name && this.icons[i].active) {
        return true;
      }
    }
    return false;
  }
  
}