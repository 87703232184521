/**
 * Schadenfall-Operationen
 * 
 * Attila Németh, UBG
 * 03.04.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {Schadenmeldung} from '../../../../../model/meldung';
import {SchadenmeldungService} from '../../../../../services/meldung.service';

@Component({
  selector: 'ubg-schadenmeldung-operations',
  templateUrl: './operations.component.html',
})
export class SchadenmeldungOperationsComponent {
  
  @Input() meldung: Schadenmeldung = null
  @Output() saveClick = new EventEmitter()
  statusOptions: Array<{
    key: number
    label: string
  }> = [
    {
      key: 0,
      label: 'Offen',
    },
    {
      key: 1,
      label: 'Geschlossen',
    },
    {
      key: -1,
      label: 'Abgelehnt',
    },
  ];

  constructor(private service: SchadenmeldungService) {}
  
  saveClicked() {
    this.saveClick.emit();
  }
  
  getSchadenSumme(): number {
    let r: number;
    if (typeof this.meldung.attributes.regulierte_schadenshohe === 'string') {
      r = Number(this.meldung.attributes.regulierte_schadenshohe);
    }
    else {
      r = this.meldung.attributes.regulierte_schadenshohe;
    }
    if (this.meldung.attributes.eigenbeteiligung) {
      if (typeof this.meldung.attributes.eigenbeteiligung_hohe === 'string') {
        r -= Number(this.meldung.attributes.eigenbeteiligung_hohe);
      }
      else {
        r -= this.meldung.attributes.eigenbeteiligung_hohe;
      }
    }
    if (this.meldung.attributes.kulanz) {
      if (typeof this.meldung.attributes.kulanz_hohe === 'string') {
        r += Number(this.meldung.attributes.kulanz_hohe);
      }
      else {
        r += this.meldung.attributes.kulanz_hohe;
      }
    }
    return r;
  }
  
}