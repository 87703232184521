/**
 * Stichwort-Liste
 * Attila Németh, UBG
 * 13.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogConfig} from "@angular/material";
import {MatPaginatorModule} from '@angular/material/paginator';

import {UbgFabComponent} from '../components/ubg_fab.component';
import {StichwortService} from '../services/stichwort.service';
import {UbgDialogDeleteComponent} from '../components/dialog_delete.component';
import {HeaderService} from '../services/header.service';

@Component({
  selector: 'app-stichwort-list',
  templateUrl: './list.component.html',
})
export class StichwortListComponent implements OnInit {

  stichworte: Array<{
    id: string
    value: string
  }>
  stichworteToList: Array<{
    id: string
    value: string
  }>
  columnsToDisplay = ['value', 'delete'];
  newStichwort: string
  pageIndex: number
  pageSize: number
  
  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private service: StichwortService,
              private header: HeaderService) {
    this.stichworte = [];
    this.stichworteToList = [];
    this.pageSize = 25;
    this.pageIndex = 0;
  }
  
  ngOnInit() {
    this.newStichwort = '';
    this.updateList();
    this.header.setTitle('Stichworte');
  }  
  
  save() {
    if (this.newStichwort == '') {
      this.snackbar.open('Kein Stichwort wurde hinzugefügt', '', {
        duration: 6000,
      });
    }
    else {
      this.service.add(this.newStichwort).then(() => {
        this.snackbar.open('Das Stichwort wurde hinzugefügt', '', {
          duration: 3000,
        });
        this.newStichwort = '';
        this.updateList();
      }).catch(() => {
        this.snackbar.open('Das Stichwort kann nicht hinzugefügt werden', '', {
          duration: 6000,
        });
      });
    }
  }
  
  updateList() {
    this.service.getAll().then((response: Array<{
      id: string
      attributes: {
        name: string
      }
    }>) => {
      this.stichworte = [];
      for (let i in response) {
        this.stichworte.push({
          id: response[i].id,
          value: response[i].attributes.name,
        });
      }
      this.stichworteToList = this.stichworte.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);
    }).catch(() => {
      setTimeout(() => {
        this.updateList();
      }, 10)
    });
  }
  
  delete(stichwort: {
    id: string
    value: string
  }) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      label: 'Möchten Sie das Stichwort ' + stichwort.value + ' wirklich löschen?',
    };
    let deleteDialog = this.dialog.open(UbgDialogDeleteComponent, dialogConfig);
    deleteDialog.afterClosed().toPromise().then((response) => {
      if (response == 1) {
        this.service.delete(stichwort.id).then(() => {
          this.updateList();
        });
      }
    });
  }
  
  pageEvent(event: {
    pageIndex: number,
    pageSize: number,
  }) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateList();
  }

}