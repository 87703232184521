/**
 * User Model
 * Attila Németh, UBG
 * 23.10.2018
 */
 
import {CduGliederung} from './cdu_gliederung';
 
export class ContentaUser {
  
  id: string
  name: string
  email: string
  vorname: string
  nachname: string
  gliederung: Array<CduGliederung>
  gliederung_angabe: string
  password: string
  permissions: any
    
}