/**
 * Gliederung-Service
 * Attila Németh, UBG
 * 18.09.2018
 */
 
import { Injectable } from '@angular/core';

import {Gliederung} from '../model/gliederung';

import {ContentaService} from './contenta.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class GliederungService {

  constructor(private contenta: ContentaService,
              private user: UserService) {
  }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      //console.trace();
      //return false;
      this.contenta.getJsonApi('ubg_cdu_gliederung/ubg_cdu_gliederung').then((response: Array<{
        id: string
        attributes: {
          name: string
          ansprechpartner: string
          ort: string
          plz: string
          strasse: string
          e_mail: Array<string>
          telefon: Array<string>
        }
      }>) => {
        let data: Array<Gliederung> = [];
        for (let i in response) {
          data.push({
            id: response[i].id,
            name: response[i].attributes.name,
            ansprechpartner: response[i].attributes.ansprechpartner,
            ort: response[i].attributes.ort,
            plz: response[i].attributes.plz,
            strasse: response[i].attributes.strasse,
            e_mail: response[i].attributes.e_mail,
            telefon: response[i].attributes.telefon,
          });
        }
        resolve(data);
      })
    });
    return promise;
  }
  
  get(id: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getJsonApi('ubg_cdu_gliederung/ubg_cdu_gliederung/' + id).then((response: {
        id: string
        attributes: {
          name: string
          ansprechpartner: string
          ort: string
          plz: string
          strasse: string
          e_mail: Array<string>
          telefon: Array<string>
        }
      }) => {
        resolve({
          id: response.id,
          name: response.attributes.name,
          ansprechpartner: response.attributes.ansprechpartner,
          ort: response.attributes.ort,
          plz: response.attributes.plz,
          strasse: response.attributes.strasse,
          e_mail: response.attributes.e_mail,
          telefon: response.attributes.telefon,
        });
      })
    });
    return promise;
  }
  
  search(search: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/gliederung/search', {
        search: search
      }).then((response: Array<{
        id: string
        label: string
      }>) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}
