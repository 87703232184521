/**
 * {Description}
 * 
 * Attila Németh, UBG
 * 02.07.2019
 */
 
import {Injectable} from '@angular/core';

import {ContentaService} from '../../../services/contenta.service';
import {UbgNextcloudFile} from '../model/file';

@Injectable({
  providedIn: 'root'
})
export class UbgNextcloudService {
  
  constructor(private contenta: ContentaService) {}
  
  loadDir(dirName: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/nextcloud/propfind', {
        dir: dirName,
      }).then((response: Array<UbgNextcloudFile>) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  loadDirFile(dirName: string, fileId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/nextcloud/propfind/file', {
        dir: dirName,
        file: fileId,
      }).then((response: UbgNextcloudFile) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  deleteFile(dirName: string, fileId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/nextcloud/delete/file', {
        dir: dirName,
        file: fileId,
      }).then((response: string) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  putFile(dirName: string, fileId: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/nextcloud/put/file', {
        dir: dirName,
        file: fileId,
      }).then((response: string) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}
