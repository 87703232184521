/**
 * Drop-Zone für Hochladen
 * Attila Németh, UBG
 * 04.10.2018
 */
 
/*

https://nishantmc.github.io/angular-material-fileupload.github.io/directives/FileUploadInputFor.html#source

 */
 
import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'ubg-file-upload-drop',
  templateUrl: './upload_drop.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UbgFileUploadDropComponent implements OnInit {
  
  @Input() label: string
  @Input() limit: number
  @Output() fileSelected = new EventEmitter();
  
  error: string
  
  ngOnInit() {
    if (this.limit === undefined) {
      this.limit = -1;
    }
    if (this.label === undefined) {
      if (this.limit == 1) {
        this.label = 'Datei hier ablegen';
      }
      else {
        this.label = 'Dateien hier ablegen';
      }
    }
    this.error = '';
  }
  
  @HostListener('drop', [ '$event' ])
  public onDrop(event: any): any {
    if (this.limit >= 1 && event.dataTransfer.files.length > this.limit) {
      this.error = this.limit + ' Dateien dürfen hochgeladen werden';
    }
    else {
      this.error = '';
      let files = event.dataTransfer.files;
      this.fileSelected.emit(event.dataTransfer.files);
    }
    event.preventDefault();
    event.stopPropagation();
  }
  
  @HostListener('dragover', [ '$event' ])
  public onDropOver(event: any): any {
    this.error = '';
    event.preventDefault();
  }
  
}