/**
 * Spinner
 * Attila Németh, UBG
 * 13.12.2018
 */
 
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ubg-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  
  @Input() active: boolean
  
  constructor() {}
  
  getClass() {
    let cls = 'ubg-spinner-wrapper';
    if (this.active) {
      cls += ' active';
    }
    return cls;
  }

}