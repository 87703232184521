import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import {User} from '../model/user';
import {ContentaService} from './contenta.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loggedIn: boolean
  userEmail: string
  userPassword: string
  registerEmail: string
  errorMessage: string

  user: User
  
  gliederungOptions: Array<{
    id: string
    value: string
  }>
      
  constructor(private http: HttpClient,
              private contenta: ContentaService) { 
    this.loggedIn = false;
    this.registerEmail = '';
    this.userEmail = '';
    this.userPassword = '';
    this.user = new User;
//    this.user.permissions = {
//      'edit ubg schadenmeldung': { access: false }
//    };
//    let cookies = document.cookie.split(';');
//    let cookieData = {};
//    for (let i in cookies) {
//      cookieData = cookies[i].split('=');
//      if (cookieData[0] == 'refresh_token') {
//        this.contenta.updateToken({
//          refresh: cookieData[1]
//        }).then((response: {
//          expires_in: number,
//          refresh_token: string
//        }) => {
//          this.loggedIn = true;
//          this.setCookies(response);
//          this.loadUserData();
//        });
//      }
//    }
    this.gliederungOptions = [];
  }
  
//  private setCookies(tokens: {
//    refresh_token: string
//  }) {
//    var d = new Date();
//    // Jetzt + 2 Wochen
//    d.setTime(d.getTime() + 2 * 7 * 86400 * 1000);
//    let expiresString = "expires="+ d.toUTCString();
//    document.cookie = 'refresh_token=' + tokens.refresh_token + ';' + expiresString + ";path=/";
//  }
  
  isLoggedIn() {
    return this.loggedIn;
  }
  
//  register() {
//    let data = {
//      name: [this.registerEmail],
//      mail: [this.registerEmail],
//      vorname: [this.user.vorname],
//      nachname: [this.user.nachname],
//    };
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.post('user/register?_format=json', data).then((response) => {
//        // Die Registration war erfolgreich
//        resolve(response);
//      }).catch(() => {
//        // Die Registration war nicht erfolgreich
//        reject();
//      });
//    });
//    return promise;
//  }
  
//  login() {
//    let promise = new Promise((resolve, reject) => {
//      this.contenta.updateToken({
//        username: this.userEmail,
//        password: this.userPassword
//      }).then((response: {
//        expires_in: number,
//        refresh_token: string
//      }) => {
//        this.loggedIn = true;
//        this.setCookies(response);
//        this.loadUserData();
//        resolve(response);
//      }).catch((error) => {
//        this.loggedIn = false;
//        reject(error);
//      });
//    });
//    return promise;
//  }
//  
//  logout() {
//    this.loggedIn = false;
//    this.userEmail = '';
//    this.userPassword = '';
//    this.user = new User;
//    this.user.permissions = {
//      'edit ubg schadenmeldung': { access: false }
//    };
//    var d = new Date();
//    d.setTime(d.getTime() - 7200);
//    let expiresString = "expires="+ d.toUTCString();
//    document.cookie = 'refresh_token=null;' + expiresString + ";path=/";
//    this.contenta.accessToken = null;
//    this.contenta.refreshToken = null;
//    this.contenta.tokenExpires = 0;
//  }
  
//  loadUserData() {
//    this.contenta.get('oauth/debug?_format=json').then((response: {
//      id: number
//      permissions: Array<{
//        access: boolean
//      }>
//    }) => {
//      this.user.permissions = response.permissions;
//      let path = 'user/user?filter[uid]=' + response.id;
//      this.contenta.getJsonApi(path).then((response: Array<{
//        id: string,
//        attributes: {
//          vorname: string
//          nachname: string
//        }
//        relationships: {
//          gliederung: {
//            data: Array<{
//              id: number
//            }>
//          }
//        }
//      }>) => {
//        this.user.uuid = response[0].id;
//        this.user.vorname = response[0].attributes.vorname;
//        this.user.nachname = response[0].attributes.nachname;
//        this.user.gliederung = [];
//        if (this.hasPermission('edit ubg schadenmeldung')) {
//          this.contenta.getJsonApi('ubg_cdu_gliederung/ubg_cdu_gliederung').then((response: Array<{
//            attributes: any
//          }>) => {
//            for (let i in response) {
//              this.user.gliederung.push(response[i].attributes);
//            }
//          });
//        }
//        else {
//          for (let i in response[0].relationships.gliederung.data) {
//            let path = 'ubg_cdu_gliederung/ubg_cdu_gliederung/' + response[0].relationships.gliederung.data[i].id;
//            this.contenta.getJsonApi(path).then((response: {
//              attributes: any
//            }) => {
//              this.user.gliederung.push(response.attributes);
//            });
//          }
//        }
//      }).catch((error) => {
//        console.error(error);
//        setTimeout(() => {
//          this.loadUserData();
//        }, 50);
//      });
//    });
//  }
  
//  getGliederungen() {
//    if (this.user.gliederung === undefined) {
//      return [];
//    }
//    else {
//      return this.user.gliederung;
//    }
//  }
  
//  getGliederungenOptions() {
//    if (this.user.gliederung === undefined) {
//      return [];
//    }
//    else {
//      let ok: boolean = true;
//      let o: Array<{
//        id: string
//        value: string
//      }> = [];
//      for (let j in this.user.gliederung) {
//        let jOk = false;
//        o.push({
//          id: this.user.gliederung[j].uuid,
//          value: this.user.gliederung[j].name,
//        });
//        for (let i in this.gliederungOptions) {
//          if (this.user.gliederung[j].uuid == this.gliederungOptions[i].id) {
//            jOk = true;
//          }
//        }
//        if (!jOk) {
//          ok = false;
//        }
//      }
//      if (!ok) {
//        this.gliederungOptions = o;
//      }
//      return this.gliederungOptions;
//    }
//  }
  
//  getGliederung(id: string) {
//    for (let i in this.user.gliederung) {
//      if (this.user.gliederung[i].uuid == id) {
//        return this.user.gliederung[i];
//      }
//    }
//    console.warn(id, 'Gilederung wurde nicht gefunden');
//  }
  
  hasPermission(permissionName: string) {
    console.error('hasPermission', permissionName);
//    if (this.user.permissions[permissionName] === undefined) {
//      return false;
//    }
//    if (this.user.permissions[permissionName].access) {
//      return true;
//    }
//    return false;
  }
  
//  get(propertyName: string) {
//    if (this.user[propertyName] !== undefined && this.user[propertyName] !== null) {
//      return this.user[propertyName];
//    }
//    else {
//      return null;
//    }
//  }
  
  checkPassword(passwordToCheck: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('oauth/debug?_format=json').then((response: {
        id: number
      }) => {
        let path = 'user/user?filter[uid]=' + response.id;
        this.contenta.getJsonApi(path).then((response: Array<{
            attributes: {
              name: string
            }
          }>) => {
          let url = this.contenta.baseUrl + 'user/login?_format=json';
          this.http.post(url, {
              name: response[0].attributes.name,
              pass: passwordToCheck,
            }).toPromise().then((response) => {
              resolve(response);
            }).catch((error) => {
              reject(error);
            });
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  update(uid: number, data: any) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.patch('user/' + uid, data).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  changePassword(uid: number, existingPass: string, newPass: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.post('ubg/user/' + uid + '/password', {
        existing: existingPass,
        new: newPass,
      }).then((response) => {
        resolve(response);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
}
