/**
 * Beschreibung
 * 
 * Attila Németh, UBG
 * 17.04.2020
 */
 
import {Component, Input} from '@angular/core';

import {Schadenmeldung} from '../../../../../model/meldung';

@Component({
  selector: 'meldung-form-element-beschreibung',
  templateUrl: './beschreibung.component.html',
  styleUrls: ['../../form.component.scss'],
})
export class SchadenmeldungFormElementBeschreibungComponent {
  
  meldungObject: Schadenmeldung = null
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  
  constructor() {}
  
}