/**
 * Sparte
 * Attila Németh, UBG
 * 27.09.2018
 */
 
export class Sparte {
  
  type: string = 'taxonomy_term--sparte'
  id: string 
  attributes: {
    name: string
  }
  
}