/**
 * UBG Datei-Hochladen-Komponent
 * Attila Németh, UBG
 * 02.11.2018
 */
 
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType, HttpRequest } from '@angular/common/http';

import {UbgFile} from '../../model/file';
import {BytesPipe} from '../../pipes/byte';

import {FileService} from '../../services/file.service';

@Component({
  selector: 'ubg-file-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UbgFileUploadComponent implements OnInit {
  
  @Input() url: string 
  @Input() httpAuthorization: string
  @Input() limit: number
  @Output() completed = new EventEmitter();
  
  files: Array<{
    file: File,
    progress: number
    uploaded: {}
  }>
  
  constructor(private http: HttpClient,
                private fileService: FileService) {
    this.files = [];
  }
  
  ngOnInit() {}
  
  fileDropped(event: any) {
    this.files = [];
    for (let i = 0; i < event.length; i++) {
      this.files.push({
        file: event[i],
        progress: 0,
        uploaded: null,
      });
    }
  }

  upload() {
    for (let i = 0; i < this.files.length; i++) {
      let headers = new HttpHeaders().set('Content-Type', 'application/octet-stream');
      
      headers = headers.set('Content-Disposition', 'file; filename="' + this.fileService.cleanName(this.files[i].file.name) + '"');
      if (this.httpAuthorization !== undefined) {
        headers = headers.set('Authorization', this.httpAuthorization);
      }
      this.uploadRequest(this.files[i].file, headers, i).then((response: any) => {
        this.files[i].uploaded = response;
        let allUploaded: boolean = true;
        for (let j in this.files) {
          if (this.files[j].uploaded === null || this.files[j].uploaded === false) {
            allUploaded = false;
            break;
          }
        }
        if (allUploaded) {
          this.completed.emit(this.files);
        }
      }).catch((error: {
        status: number
        statusText: string
        message: string
      }) => {
        this.files[i].uploaded = false;
        console.error(error.status, error.statusText);
        console.error(error.message);
      });
    }
    
    
//    for (let i = 0; i < this.files.length; i++) {
//      let headers = new HttpHeaders().set('Content-Type', 'application/octet-stream');
//      headers = headers.set('Content-Disposition', 'file; filename="' + this.files[i].name + '"');
//      if (this.httpAuthorization !== undefined) {
//        headers = headers.set('Authorization', this.httpAuthorization);
//        this.uploadRequest(this.files[i], headers, i).then((response: {
//          fid: any,
//        }) => {
//          this.uploaded[i] = response;
//          let allUploaded: boolean = true;
//          for (let j in this.uploaded) {
//            if (this.uploaded[j].fid === null) {
//              allUploaded = false;
//              break;
//            }
//          }
//          if (allUploaded) {
//            let response = [];
//            for (let j = 0; j < this.files.length; j++) {
//              response.push({
//                file: this.files[j],
//                uploaded: this.uploaded[j],
//              });
//            }
//            this.completed.emit(response);
//          }
//        }).catch((error: {
//          status: number
//          statusText: string
//          message: string
//        }) => {
//          console.error(error.status, error.statusText);
//          console.error(error.message);
//        });
//      }
//      else {
//        this.uploadRequest(this.files[i], headers, i).catch((error: {
//          status: number
//          statusText: string
//          message: string
//        }) => {
//          console.error(error.status, error.statusText);
//          console.error(error.message);
//        })
//      }
//    }
  }
  
  uploadRequest(file: File, headers: HttpHeaders, i:number) {
    let promise = new Promise((resolve, reject) => {
      const httpOptions = {
        headers: headers,
        reportProgress: true,
      };
      const request = new HttpRequest('POST', this.url, file, httpOptions);
      this.http.request(request).subscribe((event) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.files[i].progress = Math.floor(event.loaded / event.total * 100);
        }
        else if (event.type === HttpEventType.Response) {
          resolve(event.body);
        }
      }, (error: {
        status: number
        statusText: string
        error?: {
          message: string
        }
      }) => {
        let response = {
          status: error.status,
          message: '',
          statusText: error.statusText,
        };
        if (error.error !== undefined && error.error.message !== undefined) {
          response.message = error.error.message;
        }
        reject(response);
      });
    });
    return promise;
  }
  
  reset() {
    this.files = [];
  }
  
  fileSelected(event: Event) {
    for (let i = 0; i < event.target['files'].length; i++) {
      this.files.push({
        file: event.target['files'][i],
        progress: 0,
        uploaded: null,
      });
    }
  }
  
}