import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FlexLayoutModule} from '@angular/flex-layout';

import {ContentaUserLoginComponent} from './components/login/login.component';
import {ContentaUserRegisterComponent} from './components/register/register.component';
import {ContentaUserResetComponent} from './components/reset/reset.component';
import {ContentaUserDialogPasswortComponent} from './components/dialog/password/password';
import {ContentaUserDialogForgotComponent} from './components/dialog/forgot/forgot';
import {ContentaUserDialogCduplusComponent} from './components/cduplus/cduplus.component';
import {ContentaUserCduplusLoginComponent} from './components/cdupluslogin/cdupluslogin.component';
import {UbgCombinedLoginComponent} from './components/combined_login/combined_login.component';

const userRoutes: Routes = [
  {
    path: 'user/reset/:userId/:timestamp/:hash',
    component: ContentaUserResetComponent
  },
  {
    path: 'cduplus/auth',
    component: ContentaUserDialogCduplusComponent,
  },
  {
    path: 'admin/login',
    component: ContentaUserLoginComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot(
      userRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatDialogModule,
    MatSnackBarModule,
    MatCardModule,
    MatProgressBarModule,
    FlexLayoutModule,
  ],
  declarations: [
    ContentaUserLoginComponent,
    ContentaUserRegisterComponent,
    ContentaUserResetComponent,
    ContentaUserDialogPasswortComponent,
    ContentaUserDialogForgotComponent,
    ContentaUserDialogCduplusComponent,
    ContentaUserCduplusLoginComponent,
    UbgCombinedLoginComponent,
  ],
  exports: [
    ContentaUserLoginComponent,
    ContentaUserRegisterComponent,
    ContentaUserCduplusLoginComponent,
    UbgCombinedLoginComponent,
  ],
  entryComponents: [
    ContentaUserDialogPasswortComponent,
    ContentaUserDialogForgotComponent
  ],
})
export class ContentaUserModule {

  constructor() {}

}