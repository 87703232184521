import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpRequest, HttpEventType } from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentaService {

  clientId: string
  clientSecret: string
  baseUrl: string
  tokenUrl: string
  
  accessToken: string
  refreshToken: string
  tokenExpires: number
  tokenUpdating: boolean;

  constructor(private http: HttpClient) {
    this.clientId     = environment.contenta.clientId;
    this.clientSecret = environment.contenta.clientSecret;
    this.baseUrl      = environment.contenta.baseUrl;
    this.tokenUrl     = environment.contenta.tokenUrl;
    this.tokenExpires = 0;
    this.tokenUpdating = false;
  }
  
  getJsonApi(path: string) {
    let promise = new Promise((resolve, reject) => {
      this.get('api/' + path).then((response: {
        data: any
      }) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
    return promise;
  }
  
  getAnonymous(path: string, headers?: Array<{
    key: string
    value:any
  }>) {
    let promise = new Promise((resolve, reject) => {
      let url = this.baseUrl + path;
      console.debug(headers);
      let httpOptions = {
        headers: new HttpHeaders({
          'X-Consumer-ID': this.clientId,
        }),
      };
      for (let i in headers) {
        httpOptions.headers = httpOptions.headers.set(headers[i].key, headers[i].value);
      }
      this.http.get(url, httpOptions).toPromise().then((response: any) => {
        resolve(response);
      }).catch((error: {
        status: string
        statusText: string
      }) => {
        console.error(error);
        console.error(error.status, error.statusText);
        reject(error);
      });
    });
    return promise;
  }
  
  get(path: string, headers?: Array<{
    key: string
    value:any
  }>) {
    let promise = new Promise((resolve, reject) => {
      let url = this.baseUrl + path;
      this.getToken().then((accessToken) => {
        let httpHeaders = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken,
          'X-Consumer-ID': this.clientId,
        });
        if (headers !== undefined) {
          for (let i in headers) {
            httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
          }
        }
        let httpOptions = {
          headers: httpHeaders,
        };
        this.http.get(url, httpOptions).toPromise().then((response: any) => {
          resolve(response);
        }).catch((error: {
          status: string
          statusText: string
        }) => {
          console.error(error);
          console.error(error.status, error.statusText);
          reject(error);
        });
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }
  
  /**
   * Keine vollständige URL ist vorhanden, nur der Pfad.
   */
  getFileByPath(path: string) {
    let promise = new Promise((resolve, reject) => {
      const url = this.baseUrl + path;
      console.debug(url);
      this.getFile(url).then((response: Blob) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getFile(url: string) {
    let promise = new Promise((resolve, reject) => {
      this.getToken().then((accessToken) => {
        this.http.get(url, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          }),
          responseType: 'blob',
        }).subscribe((results) => {
          resolve(results);
        }, (error) => {
          reject(error);
        })
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
    return promise;
  }
  
  getBase64Image(url: string) {
    let promise = new Promise((resolve, reject) => {
      this.getFile(url).then((response: Blob) => {
        var reader  = new FileReader();
        reader.addEventListener('load', () => {
          resolve(reader.result);
        }, false);
        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getPostFile(path: string, data: any) {
    let promise = new Promise((resolve, reject) => {
      let url = this.baseUrl + path;
      this.getToken().then((accessToken) => {
        this.http.post(url, data, {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          }),
          responseType: 'blob',
        }).subscribe((results) => {
          resolve(results);
        }, (error: {
          status: number
          statusText: string
        }) => {
          console.error(error.status, error.statusText);
          reject();
        });
      });
    });
    return promise;
  }
  
  post(path: string, data: any) {
    let promise = new Promise((resolve, reject) => {
      let url = this.baseUrl + path;
      this.getToken().then((accessToken) => {
        let httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        if (typeof data == 'string') {
          httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }
        else {
          httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/vnd.api+json');
        }
        this.http.post(url, data, httpOptions).toPromise().then((response) => {
          resolve(response);
        }).catch((error: {
          status: number
          statusText: string
          detail: string
        }) => {
          console.error(error.status, error.statusText);
          if (error.detail !== undefined) {
            console.error('--', error.detail);
          }
          reject(error);
        });
      }).catch(() => {
        console.warn('Der Benutzer ist nicht angemeldet');
        let httpOptions = {
          headers: new HttpHeaders({})
        };
        if (typeof data == 'string') {
          httpOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }
        this.http.post(url, data, httpOptions).toPromise().then((response) => {
          resolve(response);
        }).catch((error: {
          status: number
          statusText: string
          detail: string
        }) => {
          console.error(error.status, error.statusText);
          if (error.detail !== undefined) {
            console.error('--', error.detail);
          }
          reject();
        });
      });
    });
    return promise;
  }
  
  patch(path: string, data: any, headers?: Array<{
    key: string
    value:any
  }>) {
    let promise = new Promise((resolve, reject) => {
      let url = this.baseUrl + path;
      this.getToken().then((accessToken) => {
        let httpHeaders = new HttpHeaders({
          'Authorization': 'Bearer ' + accessToken,
          'X-Consumer-ID': this.clientId,
        });
        if (headers !== undefined) {
          for (let i in headers) {
            httpHeaders = httpHeaders.set(headers[i].key, headers[i].value);
          }
        }
        let httpOptions = {
          headers: httpHeaders,
        };
        if (typeof data == 'string') {
          httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }
        else {
          httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/vnd.api+json');
        }
        this.http.patch(url, data, httpOptions).toPromise().then((response) => {
          resolve(response);
        }).catch((error: {
          status: number
          statusText: string
          detail: string
        }) => {
          console.error(error.status, error.statusText);
          reject(error);
        });
      }).catch(() => {
        console.warn('Der Benutzer ist nicht angemeldet');
        let httpOptions = {
          headers: new HttpHeaders({})
        };
        if (typeof data == 'string') {
          httpOptions.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        }
        this.http.patch(url, data, httpOptions).toPromise().then((response) => {
          resolve(response);
        }).catch((error: {
          status: number
          statusText: string
          detail: string
        }) => {
          console.error(error.status, error.statusText);
          if (error.detail !== undefined) {
            console.error('--', error.detail);
          }
          reject();
        });
      });
    });
    return promise;
  }
  
  delete(path: string) {
    let promise = new Promise((resolve, reject) => {
      let url = this.baseUrl + path;
      this.getToken().then((accessToken) => {
        let httpOptions = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + accessToken
          })
        };
        this.http.delete(url, httpOptions).toPromise().then((response) => {
          resolve();
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  getToken() {
    let promise = new Promise((resolve, reject) => {
      let now = new Date().getTime();
      if (this.tokenExpires > now + 12000 && this.accessToken !== undefined) {
        resolve(this.accessToken);
      }
      else if (this.refreshToken !== undefined && !this.tokenUpdating) {
        // Refresh Token
        this.updateToken({
          refresh: this.refreshToken
        }).then(() => {
          resolve(this.accessToken);
        }).catch(() => {
          reject();
        });
      }
      else if (this.tokenUpdating) {
        setTimeout(() => {
          if (!this.tokenUpdating) {
            resolve(this.accessToken);
          }
          else {
            console.warn('Access Token wurde in 5 Sekunden nicht aktualisiert!');
            reject();
          }
        }, 5000);
      }
      else {
        reject({
          message: 'Unbekannter Fehler',
        });
      }
    });
    return promise;
  }
  
  updateToken(data: any) {
    let promise = new Promise((resolve, reject) => {
      let queryString = 'client_id=' + this.clientId + '&client_secret=' + this.clientSecret;
      let now = new Date().getTime();
      let httpOptions = {
        headers: {}
      };
      if (this.tokenExpires > now && this.refreshToken !== undefined) {
        // Refresh Token
        queryString += '&grant_type=refresh_token&refresh_token=' + this.refreshToken;
        httpOptions.headers =  new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        });
      }
      else if (data.username !== undefined && data.password !== undefined) {
        // Benutzerdaten
        queryString += '&grant_type=password&username=' + data.username + '&password=' + data.password;
        queryString += '&scope=administrator cdu_bundesgeschaftsstelle schadenmeldung_form_client';
        httpOptions.headers =  new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        });
      }
      else if (data.code !== undefined) {
        // Authorization Code
        queryString += '&grant_type=cduplus&code=' + data.code + '&cduplus_client=' + data.cduplus_client + '&cduplus_secret=' + data.cduplus_secret;
        queryString += '&cduplus_scope=' + data.cduplus_scope;
        queryString += '&scope=administrator cdu_bundesgeschaftsstelle schadenmeldung_form_client';
        httpOptions.headers =  new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        });
      }
      else if (data['meldung'] !== undefined && data['password'] !== undefined) {
        // Benutzerdaten
        queryString += '&grant_type=schaden&meldung=' + data['meldung'] + '&password=' + data['password'];
        queryString += '&scope=administrator cdu_bundesgeschaftsstelle schadenmeldung_form_client';
        httpOptions.headers =  new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        });
      }
      else if (data.refresh !== undefined) {
        queryString += '&grant_type=refresh_token&refresh_token=' + data.refresh;
        httpOptions.headers =  new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded'
        });
      }
      else {
        console.error('Token Error', data);
      }
      if (this.tokenUpdating) {
        console.trace('Access Token wird aktualisiert!');
      }
      console.info('Access Token wird aktualisiert...');
      this.tokenUpdating = true;
      this.http.post(this.tokenUrl, queryString, httpOptions).toPromise().then((response: {
        access_token: string
        expires_in: number
        refresh_token: string
      }) => {
        console.info('Access Token wurde aktualisiert');
        this.tokenUpdating = false;
        this.setCookies(response);
        this.accessToken = response.access_token;
        let now = new Date().getTime();
        // 8 Sekuden früher, um Timeout-Probleme zu verhindern
        this.tokenExpires = now + (response.expires_in * 1000) - 8000;
        this.refreshToken = response.refresh_token;
        resolve(response);
      }).catch((error: {
        status: number
        statusText: string
      }) => {
        console.warn('Token konnte nicht aktualisiert werden');
        this.tokenUpdating = false;
        console.error(error.status, error.statusText);
        reject();
      });
    });
    return promise;
  }
  
  private setCookies(tokens: {
    refresh_token: string
  }) {
    var d = new Date();
    // Jetzt + 2 Wochen
    d.setTime(d.getTime() + 2 * 7 * 86400 * 1000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=' + tokens.refresh_token + ';' + expiresString + ";path=/";
  }
  
  public getUploadPath(entityTypeId: string, bundleId: string, fieldName: string) {
    let path: string = this.baseUrl + 'file/upload/' + entityTypeId + '/' + bundleId + '/' + fieldName + '?_format=json&rnd=' + Math.random();
    return path;
  }
  
  public request(method: string, path: string, data?: any, options?: any) {
    let promise = new Promise((resolve, reject) => {
      if (options === undefined) {
        options = [];
      }
      const request = new HttpRequest(method, this.baseUrl + path, data, options);
      this.http.request(request).subscribe((event) => {
        if (event.type == HttpEventType.Response) {
          if (event.status == 200) {
            resolve(event.body);
          }
          else {
            console.error(event.status, event.statusText);
          }
        }
      }, (error: {
        status: string
        statusText: string
      }) => {
        console.error(error.status, error.statusText);
        reject();
      });
    });
    return promise;
  }
  
}
