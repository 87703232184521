/**
 * Versicherungsfirma-Service
 * Attila Németh, UBG
 * 05.09.2018
 */
 
import { Injectable } from '@angular/core';

import {ContentaService} from './contenta.service';

@Injectable({
  providedIn: 'root'
})
export class VersicherungsfirmaService {

  constructor(private contenta: ContentaService) { }
  
  getAll() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getJsonApi('ubg_versicherungsfirma/ubg_versicherungsfirma').then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  get(uuid: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.getJsonApi('ubg_versicherungsfirma/ubg_versicherungsfirma/' + uuid).then((response: any) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  create(firma: any) {
    let promise = new Promise((resolve, reject) => {
      let post = {
        type: 'ubg_versicherungsfirma--ubg_versicherungsfirma',
        attributes: firma
      };
      let path = 'api/ubg_versicherungsfirma/ubg_versicherungsfirma';
      this.contenta.post(path, {
        data: post
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  update(firma: {
    uuid: string
  }) {
    let promise = new Promise((resolve, reject) => {
      let attributes = firma;
      let post = {
        type: 'ubg_versicherungsfirma--ubg_versicherungsfirma',
        attributes: attributes,
        id: firma.uuid
      };
      let path = 'api/ubg_versicherungsfirma/ubg_versicherungsfirma/' + firma.uuid;
      this.contenta.patch(path, {data: post}).then(() => {
        resolve();
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}