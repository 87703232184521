/**
 * Benutzer-Service
 * Attila Németh, UBG
 * 23.10.2018
 */
 
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import {ContentaUser} from '../model/contenta_user';
import {ContentaService} from '../../../services/contenta.service';
import {environment} from '../../../../environments/environment';
import {CduGliederungService} from './cdu_gliederung.service';
import {CduGliederung} from '../model/cdu_gliederung';
import {HeaderService} from '../../../services/header.service';

@Injectable({
  providedIn: 'root'
})
export class ContentaUserService {
  
  loggedIn: boolean
  user: ContentaUser
  applicableGliederungen: Array<CduGliederung> = []

  constructor(private contenta: ContentaService,
                private gliederung: CduGliederungService,
                private headerService: HeaderService) { 
    this.loggedIn = false;
    this.user = new ContentaUser;
  }
  
  register(user: ContentaUser) {
    let promise = new Promise((resolve, reject) => {
      let data = {
        name: [user.email],
        mail: [user.email],
        vorname: [user.vorname],
        nachname: [user.nachname],
        gliederung_angabe: [user.gliederung_angabe],
        webseite: [{
          title: environment.site.name,
          uri: environment.site.url,
        }]
      };
      this.contenta.post('user/register?_format=json', data).then((response) => {
        // Die Registration war erfolgreich
        resolve(response);
      }).catch(() => {
        // Die Registration war nicht erfolgreich
        reject();
      });
    });
    return promise;
  }
  
  login(user: ContentaUser) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.updateToken({
        username: user.name,
        password: user.password,
      }).then((response: {
        expires_in: number,
        refresh_token: string
      }) => {
        this.loggedIn = true;
        this.setCookies(response);
        this._getInfo().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
//        this.contenta.get('oauth/debug?_format=json').then((response: {
//          id: number
//          permissions: any
//        }) => {
//          this.user.permissions = response.permissions;
//          this.loadData(response.id).then(() => {
//            resolve();
//          }).catch(() => {
//            reject();
//          });
//        }).catch(() => {
//          reject();
//        });
      }).catch((error) => {
        this.loggedIn = false;
        reject(error);
      });
    });
    return promise;
  }
  
  refreshLogin(refreshToken: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.updateToken({
        refresh: refreshToken,
      }).then((response: {
        expires_in: number,
        refresh_token: string
      }) => {
        this.loggedIn = true;
        this.setCookies(response);
        this._getInfo().then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
//        this.contenta.get('oauth/debug?_format=json').then((response: {
//          id: number
//          permissions: any
//        }) => {
//          this.user.permissions = response.permissions;
//          this.loadData(response.id).then(() => {
//            resolve();
//          }).catch(() => {
//            reject();
//          });
//        }).catch(() => {
//          reject();
//        });
      }).catch((error) => {
        this.loggedIn = false; 
        this.removeCookies();
        reject(error);
      });
    });
    return promise;
  }
  
  private _getInfo() {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('ubg/roles/info').then((response: {
        id: string,
        uid: number
        permissions: Array<string>
      }) => {
        this.user.permissions = {};
        for (let i in response.permissions) {
          this.user.permissions[response.permissions[i]] = {
            access: true
          };
        }
        this.loadData(response.uid).then(() => {
          resolve();
        }).catch(() => {
          reject();
        });
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
  private loadData(userId: number) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.get('api/user/user?include=gliederung&filter[uid]=' + userId).then((userResponse: {
        data: Array<{
          id: string
          attributes: {
            vorname: string
            nachname: string
          }
          relationships: {
            gliederung: {
              data: Array<CduGliederung>
            }
          }
        }>
        included: Array<CduGliederung>
      }) => {
        this.user.id = userResponse.data[0].id;
        this.user.vorname = userResponse.data[0].attributes.vorname;
        this.user.nachname = userResponse.data[0].attributes.nachname;
        this.user.gliederung = userResponse.data[0].relationships.gliederung.data;
        for (let i in this.user.gliederung) {
          for (let j in userResponse.included) {
            if (userResponse.included[j].type == 'ubg_cdu_gliederung--ubg_cdu_gliederung'
              && userResponse.included[j].id == this.user.gliederung[i].id) {
              this.user.gliederung[i].attributes = userResponse.included[j].attributes;
            }
          }
        }
        this.getGliederungen().then((response: Array<CduGliederung>) => {
          this.applicableGliederungen = response;
        });
        resolve();
      });
    });
    return promise;
  }
  
  logout() {
    this.loggedIn = false;
    this.user = new ContentaUser;
    var d = new Date();
    d.setTime(d.getTime() - 7200);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=null;' + expiresString + ";path=/";
    this.contenta.accessToken = null;
    this.contenta.refreshToken = null;
    this.contenta.tokenExpires = 0;
    this.headerService.setTitle('');
  }
  
  hasPermission(permissionName: string) {
    if (this.user.permissions === undefined) {
      return false;
    }
    if (this.user.permissions[permissionName] === undefined) {
      return false;
    }
    return this.user.permissions[permissionName].access;
  }
  
  private setCookies(tokens: {
    refresh_token: string
  }) {
    var d = new Date();
    // Jetzt + 2 Wochen
    d.setTime(d.getTime() + 2 * 7 * 86400 * 1000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=' + tokens.refresh_token + ';' + expiresString + ";path=/";
  }
  
  private removeCookies() {
    var d = new Date();
    // Jetzt - 2 Wochen
    d.setTime(d.getTime() - 2 * 7 * 86400 * 1000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'refresh_token=ex;' + expiresString + ";path=/";
  }
  
  public get(propertyName: string) {
    if (this.user[propertyName] === null || this.user[propertyName] === undefined) {
      return '';
    }
    else {
      return this.user[propertyName];
    }
  }
  
  public getGliederungen() {
    let promise = new Promise((resolve, reject) => {
      if (this.hasPermission('use all ubg cdu gliederungen')) {
        this.gliederung.getAll().then((response) => {
          resolve(response);
        }).catch(() => {
          reject();
        });
      }
      else if (this.user.gliederung === undefined) {
        resolve([]);
      }
      else if (this.user.gliederung.length == 0) {
        resolve([]);
      }
      else {
        resolve(this.user.gliederung);
      }
    });
    return promise;
  }

  public getGliederung(id: string) {
    if (this.user.gliederung !== undefined) {
      for (let i in this.user.gliederung) {
        if (this.user.gliederung[i].id == id) {
          return this.user.gliederung[i];
        }
      }
    }
    return {
      id: '',
      attributes: {}
    }
  }
  
  passwordForgot(e_mail: string) {
    let promise = new Promise((resolve, reject) => {
      this.contenta.request('POST', 'ubg/user/forgot', {
        email: e_mail
      }).then((response) => {
        resolve(response);
      }).catch(() => {
        reject();
      });
    });
    return promise;
  }
  
}
