/**
 * Dashboard Component
 * Attila Németh, UBG
 * 31.08.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {Schadenmeldung} from '../../model/meldung';
import {Gliederung} from '../../model/gliederung';

import {ContentaUserService} from '../../modules/contenta_user/services/contenta_user.service';
import {HeaderService} from '../../services/header.service';
import {SchadenmeldungService} from '../../services/meldung.service';

@Component({
  selector: 'ubg-dashboard-default',
  templateUrl: './dashboard.component.html',
})
export class DashboardDefaultComponent implements OnInit {
  
  gliederungId: string
  applicableGliederungen: Array<Gliederung>
  meldungenAll: Array<Schadenmeldung>
  meldungenToDisplay: Array<Schadenmeldung>
  currentStatus: string;
  columnsToDisplay = ['schadensnummer', 'schadensdatum', 'open'];
  pageIndex: number
  pageSize: number
  updateCounter: number = 0;

  constructor(private router: Router,
              private user: ContentaUserService,
              private header: HeaderService,
              private service: SchadenmeldungService) { 
    this.header.setTitle('Dashboard');
    this.gliederungId = '';
    this.applicableGliederungen = [];
  }
  
  ngOnInit() {
    this.pageIndex = 0;
    this.pageSize = 25;
    this.currentStatus = '0';
    let cookies = document.cookie.split(';');
    let cookieData = {};
    for (let i in cookies) {
      cookieData = cookies[i].split('=');
      if (cookieData[0] == 'schaden_gliederung') {
        this.gliederungId = cookieData[1];
      }
    }
    this.updateList();
  }
  
  updateList() {
    console.error('Dashboard updateList');
//    this.user.getGliederungen().then((response: Array<Gliederung>) => {
//      if (response.length > 0) {
//        this.applicableGliederungen = response;
////        if (this.gliederungId === '' || this.gliederungId === null || this.gliederungId === undefined) {
//          if (response.length === 1) {
//            this.gliederungId = response[0].id;
//          }
//          if (this.gliederungId !== '') {
//            this.service.getByGliederung(this.gliederungId).then((response: Array<Schadenmeldung>) => {
//              this.meldungenAll = response;
//              this.meldungenToDisplay = [];
//              for (let i in response) {
//                if (response[i].status == this.currentStatus && response[i].gliederung_referenz == this.gliederungId) {
//                  this.meldungenToDisplay.push(response[i]);
//                }
//              }
//            }).catch(() => {
//              this.updateCounter++;
//              if (this.updateCounter < 12) {
//                let timeout = this.updateCounter * 250;
//                console.warn('Reload', this.updateCounter, this.updateCounter * 250, 'ms');
//                setTimeout(() => {
//                  this.updateList();
//                }, timeout);
//              }
//              else {
//                console.error('Die Netzverbindung funktioniert nicht');
//              }
//            });
//          }
//          else {
//            this.meldungenToDisplay = [];
//          }
////        }
//      } else {
//        this.updateCounter++;
//        if (this.updateCounter < 12) {
//          let timeout = 0;
//          for(let i = 0; i < this.updateCounter; i++) {
//            timeout += i * 100;
//          }
//          setTimeout(() => {
//            this.updateList();
//          }, timeout);
//        }
//      }
//    });
  }
  
  selectGliederung() {
    var d = new Date(2099, 11, 31);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'schaden_gliederung=' + this.gliederungId + ';' + expiresString + ";path=/";
    this.updateList();
  }
  
  countStatus(statusCode: string) {
    console.error('Dashboard contStatus');
//    let count: number = 0;
//    for (let i in this.meldungenAll) {
//      if (this.meldungenAll[i].status == statusCode  && this.meldungenAll[i].gliederung_referenz == this.gliederungId) {
//        count++;
//      }
//    }
//    return count;
  }
  
  setStatus(statusCode: string) {
    this.currentStatus = statusCode;
    this.updateList();
  }
  
  getListTitle() {
    switch (this.currentStatus) {
      case '0':
        return 'Offene Schäden';
        break;
      case '1':
        return 'Geschlossene Schäden';
        break;
      case '-1':
        return 'Abgelehnte Schäden';
        break;
      default:
        console.error(this.currentStatus);
        return '- Ein Fehler ist aufgetreten -';
    }
  }
  
  pageEvent(event: {
    pageIndex: number,
    pageSize: number,
  }) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.updateList();
  }
  
  openMeldung(meldung: Schadenmeldung) {
    this.router.navigate(['meldung/' + meldung.id]);
  }

}
