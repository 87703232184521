/**
 * Layout-Modul
 * Attila Németh, UBG
 * 02.11.2018
 */
 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatCardModule} from '@angular/material/card';

import {UbgLayoutComponent} from './components/layout/layout.component';
import {UbgLeftColumnComponent} from './components/column/left_column.component';
import {UbgRightColumnComponent} from './components/column/right_column.component';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
  ],
  declarations: [
    UbgLayoutComponent,
    UbgLeftColumnComponent,
    UbgRightColumnComponent,
  ],
  exports: [
    UbgLayoutComponent,
    UbgLeftColumnComponent,
    UbgRightColumnComponent,
  ],
  entryComponents: [
  ],
})
export class UbgLayoutModule {

  constructor() {}

}