/**
 * Anspruchsteller
 * 
 * Attila Németh, UBG
 * 16.04.2020
 */
 
import {Component, Input} from '@angular/core';

import {Schadenmeldung} from '../../../../../model/meldung';

@Component({
  selector: 'meldung-form-element-anspruchsteller',
  templateUrl: './anspruchsteller.component.html',
  styleUrls: ['../../form.component.scss'],
})
export class SchadenmeldungFormElementAnspruchstellerComponent {
  
  meldungObject: Schadenmeldung = null
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  
  constructor() {}
  
}