/**
 * Schadenmeldungsformuar-Aktionen
 * 
 * Attila Németh, UBG
 * 14.04.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatSnackBar} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';

import {Schadenmeldung} from '../../../../../model/meldung';
import {Notiz} from '../../../../../model/notiz';
import {NotizService} from '../../../../../services/notiz.service';
import {NotizDialogComponent} from './dialog/notiz/notiz.component';
import {NotizDialogAddComponent} from './dialog/add/add.component';

export interface NotizDialogData {
  notiz: Notiz
  meldung: Schadenmeldung
}

@Component({
  selector: 'ubg-schadenmeldung-aktionen',
  templateUrl: './aktionen.component.html',
  styleUrls: ['./aktionen.component.scss'],
})
export class SchadenmeldungAktionenComponent {
  
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
    this.loadNotizen();
  }
  @Output() changed = new EventEmitter();
  meldungObject: Schadenmeldung = null
  columns: Array<string> = ['icon', 'title', 'date', 'popup']
  
  constructor(private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private notizService: NotizService) {}
              
  getIcon(notiz: Notiz) {
    switch(notiz.type) {
      case 'ubg_notiz--notiz':
        return 'description';
      case 'ubg_notiz--e_mail':
      case 'ubg_notiz--brief_an_versicherung':
        return 'alternate_email';
      case 'ubg_notiz--brief':
        return 'mail_outline';
      case 'ubg_notiz--datei':
        return 'attachment';
      default:
        console.warn('Unkbekannter Notizentyp', notiz.type);
        return 'bookmark';
    }
  }
  
  notizHasAttribute(notiz: Notiz, attribute: string): boolean {
    if (notiz.attributes === undefined) {
      return false;
    }
    if (notiz.attributes[attribute] === undefined) {
      return false;
    }
    if (notiz.attributes[attribute] === null) {
      return false;
    }
    return true;
  }
  
  openNotiz(notiz: Notiz): void {
    const dialogRef = this.dialog.open(NotizDialogComponent, {
      width: '640px',
      maxWidth: '90%',
      data: {
        notiz: notiz,
        meldung: this.meldungObject,
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.notizService.update(result).then((response: Notiz) => {
          let newNotizen: Array<Notiz> = [];
          for (let i in this.meldungObject.relationships.notizen.data) {
            if (this.meldungObject.relationships.notizen.data[i].id == response.id) {
              newNotizen.push(response);
            }
            else {
              newNotizen.push(this.meldungObject.relationships.notizen.data[i]);
            }
          }
          this.meldungObject.relationships.notizen.data = newNotizen;
        });
      }
    });
  }
  
  addNotiz(type: string) {
    let notiz = new Notiz;
    notiz.type = 'ubg_notiz--' + type;
    notiz.attributes = {
      title: '',
    };
    const dialogRef = this.dialog.open(NotizDialogAddComponent, {
      width: '480px',
      maxWidth: '90%',
      data: {
        notiz: notiz,
        meldung: this.meldungObject,
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.notizService.create(result).then((response: Notiz) => {
          let newNotizen: Array<Notiz> = [];
          for (let i in this.meldungObject.relationships.notizen.data) {
            newNotizen.push(this.meldungObject.relationships.notizen.data[i]);
          }
          newNotizen.push(response);
          this.meldungObject.relationships.notizen.data = newNotizen;
          this.changed.emit();
        });
      }
    });
  }
  
  loadNotizen(): void {
    let newNotizen: Array<Notiz> = [];
    let allLoaded: boolean = false;
    if (this.meldungObject.relationships.notizen !== undefined) {
      for (let i in this.meldungObject.relationships.notizen.data) {
        newNotizen.push(this.meldungObject.relationships.notizen.data[i]);
      }
      for (let i in this.meldungObject.relationships.notizen.data) {
        this.notizService.get(this.meldungObject.relationships.notizen.data[i].type, this.meldungObject.relationships.notizen.data[i].id).then((response: Notiz) => {
          for (let j in newNotizen) {
            if (newNotizen[j].id == response.id) {
              newNotizen[j] = response;
            }
          }
          allLoaded = true;
          for (let j in newNotizen) {
            if (newNotizen[j].attributes === undefined) {
              allLoaded = false;
            }
          }
          if (allLoaded) {
            this.meldungObject.relationships.notizen.data = newNotizen;
          }
        }).catch(() => {
          this.snackbar.open('Ein Notiz kann nicht geladen werden', null, {
            duration: 15000,
          });
        })
      }
    }
  }
   
}