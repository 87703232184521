/**
 * Benutzer
 * Attila Németh, UBG
 * 18.09.2018
 */

export class User {
  
  id: string
  type: string = 'user--user'
  
//  vorname: string
//  nachname: string
//  uuid: string
//  permissions: {}
//  gliederung: Array<{
//    id: number
//    uuid: string
//    name: string
//    ansprechpartner: string
//    strasse: string
//    plz: string
//    ort: string
//    e_mail: string
//    telefon: string
//  }>
  
}