/**
 * Dokumente
 * 
 * Attila Németh, UBG
 * 14.04.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatSnackBar} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';

import {Schadenmeldung} from '../../../../../model/meldung';
import {DokumentAddDialogComponent} from './dialog/add/add.component';
import {UbgDialogConfirmComponent} from '../../../../../components/dialog_confirm.component';
import {SchadenmeldungService} from '../../../../../services/meldung.service';

@Component({
  selector: 'ubg-schadenmeldung-dokumente',
  templateUrl: './dokumente.component.html',
  styleUrls: ['./dokumente.component.scss'],
})
export class SchadenmeldungDokumenteComponent {
  
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  @Output() changed = new EventEmitter();
  meldungObject: Schadenmeldung = null
  columns: Array<string> = ['card', 'actions']
  
  constructor(private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private service: SchadenmeldungService) {}
              
  addDokument():void {
    const dialogRef = this.dialog.open(DokumentAddDialogComponent, {
      width: '480px',
      maxWidth: '90%',
      data: {
        meldung: this.meldungObject,
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.changed.emit();
      }
    });
  }
  
  removeDokument(dokument: {
    id: string
  }) {
    const dialogRef = this.dialog.open(UbgDialogConfirmComponent, {
      width: '480px',
      maxWidth: '90%',
      data: {
        label: 'Möchten Sie dieses Dokument endgültig entfernen?',
        hint: 'Es ist nicht wiederrufbar',
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == '1') {
        let newDokumente: Array<{
          id: string
          type?: string
        }> = [];
        for (let i in this.meldungObject.relationships.dokumente.data) {
          if (this.meldungObject.relationships.dokumente.data[i].id !== dokument.id) {
            newDokumente.push(this.meldungObject.relationships.dokumente.data[i]);
          }
        }
        this.meldungObject.relationships.dokumente.data = newDokumente;
        this.changed.emit();
      }
    });
  }
  
  downloadFile(dokument: {
    id: string
  }, fileName: string) {
    this.service.getFile(this.meldungObject, dokument.id).then((response: Blob) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, fileName);
      } 
      else {
        a.href = url;
        a.download = fileName;
        a.click();
      }
    }).catch((error) => {
      console.error(error);
      this.snackbar.open('Die Datei kann nicht heruntergeladen werden. Code: DO-85', null, {
        duration: 15000,
      });
    });
  }
   
}