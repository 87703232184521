/**
 * File-Service
 * 
 * Attila Németh, UBG
 * 04.06.2020
 */
 
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() {}
  
  cleanName(originalName: string): string {
    const original: Array<string> = ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü'];
    const replace: Array<string> = ['ae', 'oe', 'ue', 'Ae', 'Oe', 'Ue'];
    let name: string = originalName;
    for (let i in original) {
      name = name.replace(original[i], replace[i]);
    }
    return encodeURI(name);
  }
  
}
