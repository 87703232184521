/**
 * Datei-Model
 * Attila Németh, UBG
 * 31.10.2018
 */
 
export class UbgFile {
  
  id: string
  type: string = 'file--file'
  attributes: {
    filename: string
    filemime: string
    filesize: number
    uri: {
      url: string
    }
  }
  links: {
    large: {
      href: string
    }
    thumbnail: {
      href: string
    }
  }
  loaded: boolean = false
  
  icon() {
    if (this.attributes === undefined) {
      return 'hourglass_full';
    }
    if (this.attributes.filemime === undefined) {
      return 'error';
    }
    return 'attachment';
  }
  
  thumbnail() {
    if (this.links === undefined) {
      return null;
    }
    else if (this.links.thumbnail === undefined) {
      return null;
    }
    else {
      return this.links.thumbnail.href ;
    }
  }
  
  isImage() {
    if (this.attributes === undefined) {
      return false;
    }
    if (this.attributes.filemime === undefined) {
      return false;
    }
    if (this.attributes.filemime == 'image/jpeg') {
      return true;
    }
    if (this.attributes.filemime == 'image/png') {
      return true;
    }
  }
  
}