/**
 * Benutzerkonto verwalten
 * Attila Németh, UBG
 * 02.10.2018
 */
 
import { Component, OnInit } from '@angular/core';

import {FormControl} from '@angular/forms';
import {Router} from '@angular/router';

import {MatSnackBar} from '@angular/material';
import {HeaderService} from '../services/header.service';
import {UserService} from '../services/user.service';
import {ContentaUserService} from '../modules/contenta_user/services/contenta_user.service';

@Component({
  selector: 'ubg-user',
  templateUrl: './user.component.html',
})
export class UbgUserComponent implements OnInit {

  password: {
    old: string
    new: string
    confirm: string
  }
  
  errors: Array<{
    element: string
    message: string
  }>
  
  passwortOldControl = new FormControl('');
  passwortNewControl = new FormControl('');
  passwortConfirmControl = new FormControl('');

  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private header: HeaderService,
              private service: UserService,
              private contenta: ContentaUserService) {
    this.password = {
      old: '',
      new: '',
      confirm: '',
    };
    this.errors = [];
  }
  
  ngOnInit() {
    this.header.setTitle('Benutzerkonto');
  }  
  
  save() {
    this.validate().then((response: {
      current_user: {
        uid: number
      }
    }) => {
      this.service.changePassword(response.current_user.uid, this.password.old, this.password.new).then((response) => {
//      this.service.update(response.current_user.uid, data).then((response) => {
        this.snackbar.open('Ihr Passwort wurde geändert', '', {
          duration: 8000,
        });
        this.contenta.logout();
        this.router.navigate(['']);
      }).catch((error) => {
        this.snackbar.open('Ihr Passwort kann nicht geändert werden', '', {
          duration: 8000,
        });
      });
    });
  }
  
  validate() {
    let promise = new Promise((resolve, reject) => {
      this.errors = [];
      if (this.password.old == '') {
        this.errors.push({
          element: 'password.old',
          message: 'Das alte Passwort muss angegeben werden',
        });
        this.passwortOldControl.setErrors({
          incorrect: true
        });
      }
      if (this.password.new == '') {
        this.errors.push({
          element: 'password.new',
          message: 'Das neue Passwort muss angegeben werden',
        });
        this.passwortNewControl.setErrors({
          incorrect: true
        });
      }
      if (this.password.new !== this.password.confirm) {
        this.errors.push({
          element: 'password.confirm',
          message: 'Das neue Passwort stimmt mit der Bestätigung nicht überen',
        });
        this.passwortConfirmControl.setErrors({
          incorrect: true
        });
      }
      this.service.checkPassword(this.password.old).then((response: any) => {
        resolve(response);
      }).catch((error) => {
        this.errors.push({
          element: 'password.old',
          message: 'Das alte Passwort ist falsch',
        });
        this.passwortOldControl.setErrors({
          incorrect: true
        });
      });
    });
    return promise;
  }
  
  hasError(name: string) {
    for (let i in this.errors) {
      if (this.errors[i].element == name) {
        return true;
      }
    }
    return false;
  }
  
  getError(name: string) {
    for (let i in this.errors) {
      if (this.errors[i].element == name) {
        return this.errors[i].message;
      }
    }
    return false;
  }
  
}