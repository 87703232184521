/**
 * Login-Component
 * Attila Németh, UBG
 * 23.10.2018
 */
 
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import {MatSnackBar} from '@angular/material';
import {MatDialog, MatDialogConfig} from "@angular/material";

import {ContentaUser} from '../../model/contenta_user';
import {ContentaUserService} from '../../services/contenta_user.service';
import {ContentaUserDialogForgotComponent} from '../dialog/forgot/forgot';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'ubg-contenta-user-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class ContentaUserLoginComponent implements OnInit {

  @Input() register: boolean = true

  user: ContentaUser
    
  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private service: ContentaUserService) {
    this.user = new ContentaUser;
  }
  
  ngOnInit() {
    let cookies = document.cookie.split(';');
    let cookieData = {};
    for (let i in cookies) {
      cookieData = cookies[i].trim().split('=');
      if (cookieData[0] == 'refresh_token') {
        this.service.refreshLogin(cookieData[1]).catch((error) => {
          console.error('Anmeldung ist fehlgeschlagen');
        });
      }
    }
  }
  
  userLogin() {
    this.service.login(this.user).then(() => {
      this.router.navigate(['']);
      this.snackbar.open('Sie haben sich angemeldet', '', {
        duration: 5000,
      });
    }).catch(() => {
      this.snackbar.open('Ihre Anmeldung war nicht erfolgreich', '', {
        duration: 12000,
      });
    });
  }
  
  userForgot() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      email: '',
    };
    let formDialog = this.dialog.open(ContentaUserDialogForgotComponent, dialogConfig);
    formDialog.afterClosed().subscribe((response) => {
      if (response == 1) {
        this.service.passwordForgot(dialogConfig.data.email).then(() => {
          this.snackbar.open('Sie bekommen in kurze eine E-Mail', '', {
            duration: 5000,
          });
        }).catch(() => {
          this.snackbar.open('Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es noch einmal, später', '', {
            duration: 8000,
          });
        });
      }
    });
  }

  /**
   * Weiterleitung an CDUplus, wo der Benutzer sich anmelden kann. 
   * Nach der Anmeldung wird zu /cduplus/auth weiterleitet s. das Komponent ContentaUserDialogCduplusComponent
   * 
   * 07.01.2019
   */
  userCduplusLogin() {
    const state = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 4);
    let d = new Date();
    d.setTime(d.getTime() + 600000);
    let expiresString = "expires="+ d.toUTCString();
    document.cookie = 'cduplus_state=' + state + ';' + expiresString + ";path=/";
    let url = 'https://www.cduplus.cdu.de/oauth2/authorize?response_type=code&scope=profile+offline_access+cduplus_auth+email+openid&client_id=' + environment.cduplus.clientId + '&state=' + state;
    document.location.href = url;
  }
  
}