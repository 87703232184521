/**
 * Datumformatter
 * Attila Németh, UBG
 * 26.09.2018
 */
 
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datum'
})
export class DatumPipe implements PipeTransform {

  transform(val: any): string {
    if (val === undefined) {
      return '-';
    }
    if (typeof val === 'string') {
      val = new Date(val);
    }
    return val.toLocaleDateString();
  }
}