/**
 * Datei-Module
 * Attila Németh, UBG
 * 31.10.2018
 */
 
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import {BytesPipe} from './pipes/byte';

import {UbgFileCardNormalComponent} from './components/card_normal/card_normal.component';
import {UbgFileCardIconComponent} from './components/card_icon/card_icon.component';
import {UbgFileUploadComponent} from './components/upload/upload.component';
import {UbgFileUploadDropComponent} from './components/upload/upload_drop.component';
import {UbgFile} from './model/file';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatListModule,
    MatInputModule,
    MatProgressBarModule,
  ],
  declarations: [
    BytesPipe,
    UbgFileCardNormalComponent,
    UbgFileCardIconComponent,
    UbgFileUploadComponent,
    UbgFileUploadDropComponent,
  ],
  exports: [
    UbgFileCardNormalComponent,
    UbgFileCardIconComponent,
    UbgFileUploadComponent,
    UbgFileUploadDropComponent,
  ],
  entryComponents: [
  ],
})
export class UbgFileModule {

  constructor() {}

}