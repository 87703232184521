/**
 * Versicherungsvertrag bearbeiten
 * Attila Németh, UBG
 * 12.09.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import {MatSnackBar} from '@angular/material';

import {Versicherungsvertrag} from '../model/vvertrag';

import {VersicherungsvertragService} from '../services/vvertrag.service';
import {VersicherungsfirmaService} from '../services/vfirma.service';
import {ContentaService} from '../services/contenta.service';

@Component({
  selector: 'app-versicherungsvertrag-edit',
  templateUrl: './form.html',
})
export class VersicherungsVertragEditComponent implements OnInit {

  vertrag: Versicherungsvertrag
  operation: string
  options: {
    sparte: Array<{
      id: string
      name: string
    }>
    vfirma: Array<{
      id: string
      name: string
    }>
  }
  
  constructor(private snackbar: MatSnackBar,
              private route: ActivatedRoute,
              private vvertrag: VersicherungsvertragService,
              private vfirma: VersicherungsfirmaService,
              private contenta: ContentaService) {
    this.operation = 'edit';
    this.vertrag = {
      id: '',
      number: '',
      sparte: '',
      versicherungsgesellschaft: '',
      datum: {
        beginn: null,
        ende: null
      },
      sparteName: '',
      versicherungsgesellschaftName: ''
    };
    this.options = {
      sparte: [],
      vfirma: []
    };
  }
  
  ngOnInit() {
    let uuid = this.route.snapshot.paramMap.get('uuid');
    this.vvertrag.get(uuid).then((response: any) => {
      this.vertrag = response;
    });
    this.loadOptionsSparte();
    this.loadOptionsVFirma();
  }
  
  loadOptionsSparte() {
    this.options.sparte = [];
    this.contenta.getJsonApi('taxonomy_term/sparte').then((response: Array<{
      id: string
      attributes: {
        name: string
      }
    }>) => {
      for (let i in response) {
        this.options.sparte.push({
          id: response[i].id,
          name: response[i].attributes.name
        });
      }
    }).catch(() => {
      setTimeout(() => {
        this.loadOptionsSparte();
      }, 100)
    });
  }
  
  loadOptionsVFirma() {
    this.options.vfirma = [];
    this.vfirma.getAll().then((response: Array<{
      id: string
      attributes: {
        name: string
      }
    }>) => {
      for (let i in response) {
        this.options.vfirma.push({
          id: response[i].id,
          name: response[i].attributes.name
        });
      }
    }).catch(() => {
      setTimeout(() => {
        this.loadOptionsVFirma();
      }, 100);
    });
  }
  
  save() {
    this.vvertrag.update(this.vertrag).then(() => {
      this.snackbar.open('Der Vertrag wurde bearbeitet', '', {
        duration: 3000
      });
    }).catch(() => {
      this.snackbar.open('Der Vertrag kann nicht bearbeitet werden', '', {
        duration: 5000
      });
    });
  }

}