/**
 * Briefdialog
 * Attila Németh, UBG
 * 12.10.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { NgxEditorModule } from 'ngx-editor';

import {Schadenmeldung} from '../../model/meldung';
import {Textblock} from '../../model/textblock';
import {TextblockService} from '../../services/textblock.service';
import {ContentaService} from '../../services/contenta.service';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  templateUrl: './form_dialog_brief.html',
})
export class UbgFormDialogBriefComponent implements OnInit {
  
  nachrichtFocused: boolean;
  textblockLooked: boolean;
  textblockSearchString: string
  textbloecke: Array<Textblock>
  textblockService: TextblockService
  tokenLooked: boolean;
  tokenSearchString: string

  editorConfig = {
    minHeight: 5,
    toolbar: [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontName', 'fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['link', 'unlink', /*'image', 'video'*/]
    ],
  }
  
  ckEditor = ClassicEditor;
  ckeConfig = {
    language: 'de',
    placeholder: '',
    toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList'],
    heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Normal Text',
          },
          {
            model: 'address',
            title: 'Adresse',
            class: 'ubg-editor-address',
          }
        ]
    }
  };
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
    text: string
    template:string
    tokens: Array<{
      name: string
      label: string
    }>
    meldung: string
  }, private contenta: ContentaService) {
    this.nachrichtFocused = false;
    this.textblockLooked = false;
    this.tokenLooked = false;
  }
  
  ngOnInit() {
    this.textblockService = new TextblockService(this.contenta);
    this.textblockService.getAll().then((response: Array<Textblock>) => {
      this.textbloecke = response;
    });
  };
  
  formHasErrors() {
    if (this.data.text === '' || this.data.template === '') {
      return true;
    }
    return false;
  }
  
  lookForTextblock() {
    this.tokenLooked = false;   
    this.textblockLooked = true;
    this.textblockSearchString = '';
    this.nachrichtFocused = true;
  }
  
  getFilteredTextblockOptions() {
    let options = [];
    for (let i in this.textbloecke) {
      if (this.textbloecke[i].attributes.name.toLowerCase().indexOf(this.textblockSearchString.toLowerCase()) >= 0) {
        options.push(this.textbloecke[i].attributes.name);
      }
    }
    return options;
  }
  
  insertTextblock() {
    const textblockString: string = '[textblock:' + this.textblockSearchString + ']';
//    this.data.nachricht += '[textblock:' + this.textblockSearchString + ']';
    this.textblockService.getReplacement(textblockString, this.data.meldung).then((response: string) => {
      this.data.text += response;
    }).catch((error) => {
      console.error(error);
       this.data.text += '-FEHLER-';
    });
    this.textblockLooked = false;
    this.textblockSearchString = '';
  }
  
  lookForToken() {
    this.textblockLooked = false;
    this.tokenLooked = true;
    this.tokenSearchString = '';
  }
  
  getFilteredTokenOptions() {
    let options = [];
    for (let i in this.data.tokens) {
      if (this.data.tokens[i].name.toLowerCase().indexOf(this.tokenSearchString.toLowerCase()) >= 0) {
        options.push(this.data.tokens[i].name);
      }
    }
    return options;
  }
  
  insertToken() {
    for (let i in this.data.tokens) {
      if (this.data.tokens[i].name.toLowerCase() == this.tokenSearchString.toLowerCase()) {
        const tokenString: string = '[notiz:' + i + ']';
        this.textblockService.getReplacement(tokenString, this.data.meldung).then((response: string) => {
          this.data.text += response;
        }).catch((error) => {
          console.error(error);
           this.data.text += '-FEHLER-';
        });
        break;
      }
    }
    this.tokenLooked = false;
  }
  
  getPdf() {
    this.contenta.getPostFile('ubg/api/notiz/brief', {
      template: this.data.template,
      text: this.data.text,
      meldung: this.data.meldung,
    }).then((response) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, 'brief.pdf');
      } 
      else {
        a.href = url;
        a.download = 'brief.pdf';
        a.click();
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  
}