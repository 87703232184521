/**
 * Dokumente hochladen
 * 
 * Attila Németh, UBG
 * 14.04.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import {ContentaService} from '../../../../../../../services/contenta.service';
import {Schadenmeldung} from '../../../../../../../model/meldung';
 
@Component({
  templateUrl: 'add.component.html',
  styleUrls: ['add.component.scss'],
})
export class DokumentAddDialogComponent {
  
  uploadUrl: string
  httpAuthorization: string
  documentsLength: number = 0

  constructor(private contenta: ContentaService,
    public dialogRef: MatDialogRef<DokumentAddDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      meldung: Schadenmeldung
    }) {
  }
  
  ngOnInit() {
    this.uploadUrl = this.contenta.getUploadPath('ubg_notiz', 'ubg_notiz', 'datei');
    this.getToken();
  }
  
  getToken() {
    this.contenta.getToken().then((response) => {
      this.httpAuthorization = 'Bearer ' + response;
      setTimeout(() => {
        this.getToken();
      }, 15000);
    }).catch((error) => {
      console.error(error);
      setTimeout(() => {
        this.getToken();
      }, 60000);
    });
  }
  
  filesUploaded(event: Array<File>) {
    let newFiles: Array<{
      id: string
      type: string
    }> = [];
    if (this.data.meldung.relationships !== undefined
      && this.data.meldung.relationships.dokumente !== undefined
      && this.data.meldung.relationships.dokumente.data !== null) {
      for (let i in this.data.meldung.relationships.dokumente.data) {
        newFiles.push({
          id: this.data.meldung.relationships.dokumente.data[i].id,
          type: this.data.meldung.relationships.dokumente.data[i].type,
        });
      }
    }
    for (let i in event) {
      if (event[i]['uploaded'] !== undefined) {
        newFiles.push({
          type: 'file--file',
          id: event[i]['uploaded']['uuid'][0]['value'],
        });
        this.documentsLength ++;
      }
    }
    if (this.data.meldung.relationships === undefined) {
      this.data.meldung.relationships = {
        dokumente: {
          data: [],
        }
      };
    }
    this.data.meldung.relationships.dokumente.data = newFiles;
  }

}