/**
 * Notizendialog
 * Attila Németh, UBG
 * 26.09.2018
 */
 
import { Component, Inject } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import { NgxEditorModule } from 'ngx-editor';
//import { AngularFontAwesomeModule } from 'angular-font-awesome';

@Component({
  selector: 'ubg-dialog-form-notiz',
  templateUrl: './form_dialog_notiz.html',
})
export class UbgFormDialogNotizComponent {
  
  title: string
  beschreibung: string

  editorConfig = {
    minHeight: 5,
//    toolbar: [
//      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
//      ['fontName', 'fontSize', 'color'],
//      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
//      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
//      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
//      ['link', 'unlink', /*'image', 'video'*/]
//    ],
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['cut', 'copy', 'delete'],
      ['undo', 'redo'],
    ],
  }
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string
    beschreibung: string
  }) {
    
  }
  
}