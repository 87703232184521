/**
 * Schadenmeldung-Komponent
 * 
 * Attila Németh, UBG
 * 03.04.2020
 */
 
import {Component, OnInit, Input} from '@angular/core';

import {MatTabChangeEvent} from '@angular/material/tabs';
import {MatSelectChange} from '@angular/material/select';
import {MatDialog, MatSnackBar} from '@angular/material';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

import {Schadenmeldung} from '../../../model/meldung';
import {SchadenmeldungService} from '../../../services/meldung.service';
import {ContentaUserService} from '../../../modules/contenta_user/services/contenta_user.service';
import {CduGliederungService} from '../../../modules/contenta_user/services/cdu_gliederung.service';
import {CduGliederung} from '../../../modules/contenta_user/model/cdu_gliederung';
import {Sparte} from '../../../model/sparte';
import {SparteService} from '../../../services/sparte.service';
import {Versicherungsgesellschaft} from '../../../model/vfirma';
import {VersicherungsfirmaService} from '../../../services/vfirma.service';
import {Versicherungsvertrag} from '../../../model/vvertrag';
import {VersicherungsvertragService} from '../../../services/vvertrag.service';
import {Stichwort} from '../../../model/stichwort';
import {StichwortService} from '../../../services/stichwort.service';
import {HeaderService} from '../../../services/header.service';
import { SchadenDialogWarningComponent } from '../../dialog/warning/schaden-dialog-warning/schaden-dialog-warning.component';

@Component({
  selector: 'ubg-schadenmeldung',
  templateUrl: './meldung.component.html',
  styleUrls: ['./meldung.component.scss'],
})
export class SchadenmeldungComponent implements OnInit {
  
  @Input() set meldung(meldung: Schadenmeldung) {
    if (meldung !== null) {
      this.meldungObject = meldung;
      if (this.meldungObject.attributes.beschreibung === null) {
        this.meldungObject.attributes.beschreibung = {
          value: '',
        };
      }
      if (this.meldungObject.relationships.versicherungsgesellschaft.data !== null) {
        this.versicherungsgesellschaftsId = this.meldungObject.relationships.versicherungsgesellschaft.data.id;
      }
      if (this.meldungObject.relationships.versicherungsvertrag !== undefined 
        && this.meldungObject.relationships.versicherungsvertrag.data !== null) {
        this.versicherungsvertragId = this.meldungObject.relationships.versicherungsvertrag.data.id;
      }
      this.defaultMeldungObject = JSON.parse(JSON.stringify(this.meldungObject));
      this.headerService.setTitle(this.meldungObject.attributes.schadensnummer);
    }
  }
  meldungObject: Schadenmeldung = null
  defaultMeldungObject: Schadenmeldung = null
  isProcessing: boolean = false
  sparten: Array<Sparte> = []
  versicherungsGesellschaften: Array<Versicherungsgesellschaft> = []
  versicherungsgesellschaftsId: string = null
  versicherungsvertraege: Array<Versicherungsvertrag> = []
  versicherungsvertragId: string = null
  stichwoerter: Array<Stichwort> = []
  stichwortSearchString: string = ''
  columnsDocument: Array<string> = ['card', 'delete']
  
  constructor(private snackbar: MatSnackBar,
                private dialog: MatDialog,
                private user: ContentaUserService,
                private service: SchadenmeldungService,
                private gliederungService: CduGliederungService,
                private sparteService: SparteService,
                private versicherunsGesellschaftService: VersicherungsfirmaService,
                private versicherungsvertragService: VersicherungsvertragService,
                private stichwortService: StichwortService,
                private headerService: HeaderService) {}
                
  ngOnInit() {
    this.sparteService.getAll().then((sparten: Array<Sparte>) => {
      this.sparten = sparten;
    });
    this.versicherunsGesellschaftService.getAll().then((versicherungsGesellschaften: Array<Versicherungsgesellschaft>) => {
      this.versicherungsGesellschaften = versicherungsGesellschaften;
    });
    this.versicherungsvertragService.getAll().then((response: Array<Versicherungsvertrag>) => {
      this.versicherungsvertraege = response;
    });
    this.stichwortService.getAll().then((response: Array<Stichwort>) => {
      this.stichwoerter = response;
    });
//    setInterval(() => {
//      console.debug(this.meldungObject.attributes.schaden_durch_wahlplakate);
//    }, 1000);
  }
  
  gliederungSelected(gliederungId: string) {
    this.gliederungService.get(gliederungId).then((response: CduGliederung) => {
      this.meldungObject.attributes.gliederung = response.attributes.name;
      this.meldungObject.attributes.ansprechpartner = response.attributes.ansprechpartner;
      this.meldungObject.attributes.strasse = response.attributes.strasse;
      this.meldungObject.attributes.plz = response.attributes.plz;
      this.meldungObject.attributes.ort = response.attributes.ort;
      if (response.attributes.e_mail !== null) {
        this.meldungObject.attributes.e_mail = response.attributes.e_mail[0];
      }
      if (response.attributes.telefon !== null) {
        this.meldungObject.attributes.telefon = response.attributes.telefon[0];
      }
      this.meldungObject.relationships.gliederung_referenz = {
        data: response,
      };
    });
  }
  
  getVersicherungsgesellschaftsId() {
    if (this.meldungObject.relationships.versicherungsgesellschaft.data === null) {
      return null;
    }
    else {
      return this.meldungObject.relationships.versicherungsgesellschaft.data.id;
    }
  }
  
  sparteSelected(event: MatSelectChange) {
    for (let i in this.sparten) {
      if (this.sparten[i].id == event.value) {
        this.meldungObject.relationships.sparte.data = this.sparten[i];
      }
    }
    this.refreshVersicherungsVertraege();
  }
  
  versicherungsGesellschaftSelected(event: MatSelectChange) {
    for (let i in this.versicherungsGesellschaften) {
      if (this.versicherungsGesellschaften[i].id == event.value) {
        this.meldungObject.relationships.versicherungsgesellschaft.data = this.versicherungsGesellschaften[i];
      }
    }
    this.refreshVersicherungsVertraege();
  }
  
  refreshVersicherungsVertraege(): void {
    if (this.meldungObject.relationships.versicherungsgesellschaft.data === null) {
      this.versicherungsvertraege = [];
    }
    else if (this.meldungObject.relationships.sparte.data.id === undefined) {
      this.versicherungsvertraege = [];
    }
    else {
      this.versicherungsvertragService.getAll().then((response: Array<Versicherungsvertrag>) => {
        let newVertraege: Array<Versicherungsvertrag> = [];
        for (let i in response) {
          if (response[i].sparte === this.meldungObject.relationships.sparte.data.id
            && response[i].versicherungsgesellschaft === this.meldungObject.relationships.versicherungsgesellschaft.data.id) {
            newVertraege.push(response[i]);
          }
        }
        this.versicherungsvertraege = newVertraege;
      });
    }
  }
  
  versicherungContactDisabled(): boolean {
    for (let i in this.versicherungsGesellschaften) {
      if (this.versicherungsGesellschaften[i].id == this.meldungObject.relationships.versicherungsgesellschaft.data.id) {
        if (this.meldungObject.attributes.vg_ansprechpartner !== this.versicherungsGesellschaften[i].attributes.ansprechpartner) {
          return false;
        }
        if (this.meldungObject.attributes.vg_e_mail !== this.versicherungsGesellschaften[i].attributes.e_mail[0]) {
          return false;
        }
        if (this.meldungObject.attributes.vg_ort !== this.versicherungsGesellschaften[i].attributes.ort) {
          return false;
        }
        if (this.meldungObject.attributes.vg_plz !== this.versicherungsGesellschaften[i].attributes.plz) {
          return false;
        }
        if (this.meldungObject.attributes.vg_strasse !== this.versicherungsGesellschaften[i].attributes.strasse) {
          return false;
        }
        if (this.meldungObject.attributes.vg_telefon !== this.versicherungsGesellschaften[i].attributes.telefon[0]) {
          return false;
        }
      }
    }
    return true;
  }
  
  updateVersicherungContact(): void {
    for (let i in this.versicherungsGesellschaften) {
      if (this.versicherungsGesellschaften[i].id == this.meldungObject.relationships.versicherungsgesellschaft.data.id) {
        this.meldungObject.attributes.vg_ansprechpartner = this.versicherungsGesellschaften[i].attributes.ansprechpartner;
        this.meldungObject.attributes.vg_e_mail = this.versicherungsGesellschaften[i].attributes.e_mail[0];
        this.meldungObject.attributes.vg_ort = this.versicherungsGesellschaften[i].attributes.ort;
        this.meldungObject.attributes.vg_plz = this.versicherungsGesellschaften[i].attributes.plz;
        this.meldungObject.attributes.vg_strasse = this.versicherungsGesellschaften[i].attributes.strasse;
        this.meldungObject.attributes.vg_telefon = this.versicherungsGesellschaften[i].attributes.telefon[0];
      }
    }
  }
  
  versicherungsvertragSelected(event: MatSelectChange): void {
    for (let i in this.versicherungsvertraege) {
      if (this.versicherungsvertraege[i].id == event.value) {
        this.meldungObject.relationships.versicherungsvertrag.data.id = event.value;
      }
    }
  }
  
  getFilteredStichwortOptions(): Array<string> {
    let options: Array<string> = [];
    let existing: boolean;
    for (let i in this.stichwoerter) {
      if (this.stichwoerter[i].attributes.name.toLowerCase().indexOf(this.stichwortSearchString.toLowerCase()) >= 0) {
        existing = false;
        for (let j in this.meldungObject.relationships.stichwort.data) {
          if (this.meldungObject.relationships.stichwort.data[j].id == this.stichwoerter[i].id) {
            existing = true;
          }
        }
        if (!existing) {
          options.push(this.stichwoerter[i].attributes.name);
        }
      }
    }
    return options;
  }
  
  stichwortAdded(event: MatAutocompleteSelectedEvent): void {
    let newStichwoerter: Array<Stichwort>  = [];
    for (let i in this.meldungObject.relationships.stichwort.data) {
      newStichwoerter.push(this.meldungObject.relationships.stichwort.data[i]);
    }
    for (let i in this.stichwoerter) {
      if (this.stichwoerter[i].attributes.name == event.option.value) {
        newStichwoerter.push(this.stichwoerter[i]);
      }
    }
    this.meldungObject.relationships.stichwort.data = newStichwoerter;
    setTimeout(() => {
      this.stichwortSearchString = '';
    }, 500);
  }
  
  stichwortRemoved(stichwort: Stichwort): void {
    let newStichwoerter: Array<Stichwort>  = [];
    for (let i in this.meldungObject.relationships.stichwort.data) {
      if (this.meldungObject.relationships.stichwort.data[i].id !== stichwort.id) {
        newStichwoerter.push(this.meldungObject.relationships.stichwort.data[i]);
      }
    }
    this.meldungObject.relationships.stichwort.data = newStichwoerter;
  }
  
  getStichwortName(stichwort: Stichwort): string {
    for (let i in this.stichwoerter) {
      if (this.stichwoerter[i].id == stichwort.id) {
        return this.stichwoerter[i].attributes.name;
      }
    }
    return 'Unbekanntes Stichwort';
  }
  
  private formHasErrors(): boolean {
    return !this.service.isValid(this.meldungObject);
  }
  
  tabChanged(event: MatTabChangeEvent) {
    if (!this.formHasErrors()) {
      this.save();
    }
  }
  
  isSparte(sparteName: string): boolean {
    for (let i in this.sparten) {
      if (this.sparten[i].id == this.meldungObject.relationships.sparte.data.id
        && this.sparten[i].attributes.name == sparteName) {
          return true;
      }
    }
    return false;
  }
  
  private save() {
    console.debug('SAVE');
    if (JSON.stringify(this.defaultMeldungObject) == JSON.stringify(this.meldungObject)) {
      console.info('Keine Änderung');
    }
    else {
      this.isProcessing = true;
      if (this.meldungObject.id === null || this.meldungObject.id === undefined) {
        // Neuer Fall
        console.debug('NEU');
        this.service.create(this.meldungObject).then((response: Schadenmeldung) => {
          this.meldungObject = response;
          if (this.meldungObject.attributes.beschreibung === null) {
            this.meldungObject.attributes.beschreibung = {
              value: '',
            };
          }
          this.snackbar.open('Der Fall wurde erfolgreich eingetragen', null, {
            duration: 6000,
          });
          this.defaultMeldungObject = JSON.parse(JSON.stringify(this.meldungObject));
          this.isProcessing = false;
        }).catch((error: string) => {
          if (error !== undefined) {
            this.snackbar.open('Der Fall kann nicht gespeichert werden: ' + error, null, {
              duration: 15000,
            });
            console.debug(this.meldungObject);
          }
          this.isProcessing = false;
        })
      }
      else {
        // Bestehender Fall
        console.debug('UPDATE');
        this.service.update(this.meldungObject).then((response: Schadenmeldung) => {
          console.debug(response);
          this.meldungObject = response;
          if (this.meldungObject.attributes.beschreibung === null) {
            this.meldungObject.attributes.beschreibung = {
              value: '',
            };
          }
          this.snackbar.open('Der Fall wurde erfolgreich aktualisiert', null, {
            duration: 6000,
          });
          this.defaultMeldungObject = JSON.parse(JSON.stringify(this.meldungObject));
          this.isProcessing = false;
        }).catch((error: string) => {
          console.debug(error);
          if (error !== undefined) {
            this.dialog.open(SchadenDialogWarningComponent, {
              data: error
            }).afterClosed().subscribe(() => {
              console.debug(this.meldungObject);
            });
          }
          this.isProcessing = false;
        })
      }
    }
  }
   
}