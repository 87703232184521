/**
 * Dokumente
 * 
 * Attila Németh, UBG
 * 20.04.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {MatSnackBar} from '@angular/material';
import {MatDialog} from '@angular/material/dialog';

import {Schadenmeldung} from '../../../../../model/meldung';
import {DokumentAddDialogComponent} from '../../../meldung/components/dokumente/dialog/add/add.component';

@Component({
  selector: 'meldung-form-element-dokumente',
  templateUrl: './dokumente.component.html',
  styleUrls: ['../../form.component.scss'],
})
export class SchadenmeldungFormElementDokumenteComponent {
  
  meldungObject: Schadenmeldung = null
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  @Output() changed = new EventEmitter();
  
  constructor(private snackbar: MatSnackBar,
                private dialog: MatDialog) {}
  
  uploadDocument() {
    const dialogRef = this.dialog.open(DokumentAddDialogComponent, {
      width: '480px',
      maxWidth: '90%',
      data: {
        meldung: this.meldungObject,
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== '0') {
        this.snackbar.open('Ihr Dokument wurde erforgreich hochgeladen', null, {
          duration: 6000,
        })
        this.changed.emit();
      }
    });
  }
  
}