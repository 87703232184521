/**
 * Neue Versicherungsfirma hinzufügen
 * Attila Németh, UBG
 * 07.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

import {MatSnackBar} from '@angular/material';

import {VersicherungsfirmaService} from '../services/vfirma.service';

@Component({
  selector: 'app-versicherungsfirma-add',
  templateUrl: './form.html',
})
export class VersicherungsFirmaAddComponent implements OnInit {

  firma: {
    name: string
    bemerkung: {
      value: string
    }
  }
  operation: string
  
  constructor(private snackbar: MatSnackBar,
              private vfirm: VersicherungsfirmaService) {
    this.operation = 'create';
    this.firma = {
      name: '',
      bemerkung: {
        value: ''
      }
    };
  }
  
  ngOnInit() {
    
  }
  
  save() {
    this.vfirm.create(this.firma).then(() => {
      this.snackbar.open('Die Firma wurde hinzugefügt', '', {
        duration: 3000
      });
    }).catch(() => {
      this.snackbar.open('Die Firma kann nicht hinzugefügt werden', '', {
        duration: 8000
      });
    });
  }

}