/**
 * Dashboard-Base-Component
 * Attila Németh, UBG
 * 24.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

import {ContentaUserService} from '../modules/contenta_user/services/contenta_user.service';
import {HeaderService} from '../services/header.service';

import {DashboardDefaultComponent} from './default/dashboard.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
  constructor(private user: ContentaUserService,
              private header: HeaderService) { 
  }
  
  ngOnInit() {
    this.header.setTitle('Startseite');
  }
  
}
