/**
 * Operationen
 * 
 * Attila Németh, UBG
 * 16.04.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';

import {Schadenmeldung} from '../../../../../model/meldung';

@Component({
  selector: 'meldung-form-element-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['../../form.component.scss'],
})
export class SchadenmeldungFormElementOperationsComponent {
  
  meldungObject: Schadenmeldung = null
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  @Input() saveDisabled: boolean = true
  @Output() saveClick = new EventEmitter
  
  constructor() {}
  
  saveClicked(): void {
    this.saveClick.emit();
  }
  
}