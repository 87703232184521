/**
 * Textblock
 * Attila Németh, UBG
 * 10.10.2018
 */
 
export class Textblock {
  
  id: string
  type: string
  attributes: {
    name: string
    description: {
      value: string
    }
    changed?: number
  }
  
  constructor() {
    this.type = 'taxonomy_term--textblock';
    this.attributes = {
      name: '',
      description: {
        value: '',
      }
    };
  }
  
}