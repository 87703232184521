/**
 * Neue Meldung einreichen
 * 
 * Attila Németh, UBG
 * 03.04.2020
 */
 
import {Component, OnInit} from '@angular/core';

import {Schadenmeldung} from '../../../model/meldung';
import {CduGliederung} from '../../../modules/contenta_user/model/cdu_gliederung';
import {Sparte} from '../../../model/sparte';
import {Versicherungsgesellschaft} from '../../../model/vfirma';

@Component({
  templateUrl: './add.component.html',
})
export class AddComponent implements OnInit {
  
  meldung: Schadenmeldung = null
  
  constructor() {}
  
  ngOnInit() {
    this.meldung = new Schadenmeldung;
    this.meldung.attributes = {
      status: 0,
      beschreibung: {
        value: ''
      },
      schaden_durch_wahlplakate: {
        value: ''
      },
      bemerkungen: {
        value: ''
      }
    };
    this.meldung.relationships = {
      gliederung_referenz: {
        data: new CduGliederung,
      },
      sparte: {
        data: new Sparte,
      },
      versicherungsgesellschaft: {
        data: null
      },
      stichwort: {
        data: null
      }
    };
  }
  
}