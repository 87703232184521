import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-schaden-dialog-warning',
  templateUrl: './schaden-dialog-warning.component.html',
  styleUrls: ['./schaden-dialog-warning.component.scss']
})
export class SchadenDialogWarningComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public warning: string) { }

}