/**
 * Haftpflicht-Formular
 * 
 * Attila Németh, UBG
 * 16.04.2020
 */
 
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {MatSnackBar} from '@angular/material';

import {Schadenmeldung} from '../../../../model/meldung';
import {SchadenmeldungService} from '../../../../services/meldung.service'; 

@Component({
  selector: 'meldung-form-haftpflicht',
  templateUrl: './haftpflicht.component.html',
  styleUrls: ['../form.component.scss'],
})
export class SchadenmeldungFormHaftpflichtComponent {
  
  meldungObject: Schadenmeldung = null
  @Input() set meldung(meldung: Schadenmeldung) {
    this.meldungObject = meldung;
  }
  @Output() saveClick = new EventEmitter
  
  constructor(private snackbar: MatSnackBar,
                private service: SchadenmeldungService) {}
                
  saveDisabled(): boolean {
    if (this.meldungObject.attributes.schadensdatum === null) {
      return true;
    }
    return false;
  }
  
  saveClicked(): void {
    this.saveClick.emit();
  }
  
}