/**
 * Vericherungsfirma-Liste
 * Attila Németh, UBG
 * 05.09.2018
 */
 
import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import {UbgFabComponent} from '../components/ubg_fab.component';

import {VersicherungsfirmaService} from '../services/vfirma.service';
import {Versicherungsgesellschaft} from '../model/vfirma';
import {HeaderService} from '../services/header.service';

@Component({
  selector: 'app-versicherungsfirma-list',
  templateUrl: './list.component.html',
})
export class VersicherungsFirmaListComponent implements OnInit {

  firmen: Array<{}>
  columnsToDisplay = ['open', 'name', 'ansprechpartner', 'plz', 'ort', 'telefon', 'email'];
  
  constructor(private router: Router,
              private vfirm: VersicherungsfirmaService,
              private header: HeaderService) {
    
  }
  
  ngOnInit() {
    this.header.setTitle('Versicherungsfirmen');
    this.vfirm.getAll().then((response: any) => {
      this.firmen = response;
    }).catch(() => {
      this.firmen = [];
    });
  }  
  
  email(firma: {
    attributes: {
      e_mail: Array<string>
    }
  }) {
    window.location.href='mailto:' + firma.attributes.e_mail[0];
  }
  
  open(firma: Versicherungsgesellschaft) {
    this.router.navigate(['versicherung/firma/' + firma.id]);
  }
  
  add() {
    this.router.navigate(['versicherung/firma/add']);
  }

}