/**
 * Versicherungsfirma bearbeiten
 * Attila Németh, UBG
 * 05.09.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import {MatSnackBar} from '@angular/material';

import {VersicherungsfirmaService} from '../services/vfirma.service';

@Component({
  selector: 'app-versicherungsfirma',
  templateUrl: './form.html',
})
export class VersicherungsFirmaEditComponent implements OnInit {

  firma: {
    name: string
    uuid: string
    bemerkung: {
      value: string
    }
  }
  operation: string
  
  constructor(private route: ActivatedRoute,
              private snackbar: MatSnackBar,
              private vfirm: VersicherungsfirmaService) {
    this.operation = 'edit';
    this.firma = {
      name: '',
      uuid: '',
      bemerkung: {
        value: ''
      }
    };
  }
  
  ngOnInit() {
    let uuid = this.route.snapshot.paramMap.get('uuid');
    this.vfirm.get(uuid).then((response: {
      id: string
      attributes: {
        name: string,
        uuid: string,
        bemerkung: {
          value: string
        }
      }
    }) => {
      this.firma = response.attributes;
      if (this.firma.bemerkung === null) {
        this.firma.bemerkung = { value: '' };
      }
      this.firma.uuid = response.id;
    });
  }
  
  save() {
    this.vfirm.update(this.firma).then(() => {
      this.snackbar.open('Die Firma wurde aktualisiert', '', {
        duration: 3000
      });
    }).catch(() => {
      this.snackbar.open('Die Firma kann nicht aktualisiert werden', '', {
        duration: 3000
      });
    });
  }

}