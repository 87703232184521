/**
 * Meldungsformular-rechte Seite
 * Attila Németh, UBG
 * 24.09.2018
 */
 
import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ubg-meldung-form-save',
  templateUrl: './save.component.html',
//  styleUrls: ['./save.component.scss']
})
export class MeldungSaveComponent {
  
  @Input() disabled: boolean
  @Output() saveClick = new EventEmitter();
  
  save() {
    this.saveClick.emit();
  }
  
}