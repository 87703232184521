/**
 * Bestätigungsdialog
 * Attila Németh, UBG
 * 25.09.2018
 */

import { Component, Inject } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'ubg-dialog-confirm',
  templateUrl: './dialog_confirm.component.html',
})
export class UbgDialogConfirmComponent {
  
  label: string
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    label: string
  }) {
    if (data.label !== null && data.label !== undefined) {
      this.label = data.label;
    }
  }
  
  
  
}