/**
 * Schadenmeldung
 * Attila Németh, UBG
 * 12.09.2018
 */

import {Notiz} from '../model/notiz';
import {Sparte} from './sparte';
import {User} from './user';
import {CduGliederung} from '../modules/contenta_user/model/cdu_gliederung';
import {Versicherungsgesellschaft} from './vfirma';
import {Versicherungsvertrag} from './vvertrag';
import {Stichwort} from './stichwort';
  
export class Schadenmeldung {
  
  id: string
  type: string = 'ubg_schadenmeldung--ubg_schadenmeldung'
  attributes: {
    status: number
    schadensnummer?: string
    gliederung?: string
    schadensdatum?: string
    ansprechpartner?: string
    strasse?: string
    plz?: string
    ort?: string
    e_mail?: string
    telefon?: string
    vg_ansprechpartner?: string
    vg_e_mail?: string
    vg_ort?: string
    vg_plz?: string
    vg_strasse?: string
    vg_telefon?: string
    gemeldete_schadenshohe?: number
    beschreibung: {
      value: string
    }
    schaden_durch_wahlplakate: {
      value: string
    }
    bemerkungen: {
      value: string
    }
    regulierte_schadenshohe?: number
    eigenbeteiligung?: boolean
    eigenbeteiligung_hohe?: number
    kulanz?: boolean
    kulanz_hohe?: number,
    anspruchsteller_e_mail?: string
  }
  relationships: {
    sparte?: {
      data: Sparte
    }
    notizen?: {
      data: Array<Notiz>
    }
    gesehen?: {
      data: Array<User>
    }
    gliederung_referenz?: {
      data: CduGliederung
    }
    versicherungsgesellschaft?: {
      data: Versicherungsgesellschaft
    }
    versicherungsvertrag?: {
      data: Versicherungsvertrag
    }
    stichwort?: {
      data: Array<Stichwort>
    }
    dokumente?: {
      data: Array<{
        id: string
        type?: string
      }>
    }
  }
  
//  id: string
//  title: string
//  gliederung_referenz: string
//  gliederung: string
//  abteilung: string
//  ansprechpartner: string
//  strasse: string
//  plz: string
//  ort: string
//  e_mail: string
//  telefon: string
//  anspruchsteller_name: string
//  anspruchsteller_cdu: boolean
//  anspruchsteller_ansprechpartner: string
//  anspruchsteller_strasse: string
//  anspruchsteller_plz: string
//  anspruchsteller_ort: string
//  anspruchsteller_e_mail: string
//  anspruchsteller_telefon: string
//  sparte: string
//  versicherungsgesellschaft: string
//  vg_ansprechpartner: string
//  vg_strasse: string
//  vg_plz: string
//  vg_ort: string
//  versicherungsvertrag: string
//  versicherungsvertragDatum: {
//    beginn: any                         // Einen Datum-Typ gibt es nicht
//    ende: any
//  }
//  stichwort: Array<string>
//  beschreibung: {
//    value: string
//  }
//  schadensnummer: string
//  schadensdatum: string
//  schadenseingang: string
//  regulierte_schadenshohe: number
//  status: string
//  gesehen: Array<string>
//  geschlossen: any
//  notizen: Array<Notiz>
//  entityId: number
//  dokumente: Array<string>
//  cloud: boolean
//  cloud_id: string
//  cloud_password: string
//  eigenbeteiligung?: boolean
//  eigenbeteiligung_hohe?: number
//  kulanz?: boolean
//  kulanz_hohe?: number
  
}