/**
 * E-Mail-Dialog
 * Attila Németh, UBG
 * 09.10.2018
 * An Versicherung
 * 18.12.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import {Textblock} from '../../model/textblock';
import {TextblockService} from '../../services/textblock.service';
import {ContentaService} from '../../services/contenta.service';
import {UbgNextcloudFile} from '../../modules/nextcloud/model/file';
import {Schadenmeldung} from '../../model/meldung';

@Component({
  templateUrl: './form_dialog_mail_versicherung.html',
})
export class UbgFormDialogMailVersicherungComponent implements OnInit {
  
  nachrichtFocused: boolean;
  textblockLooked: boolean;
  textblockSearchString: string
  textbloecke: Array<Textblock>
  textblockService: TextblockService
  tokenLooked: boolean;
  tokenSearchString: string
  previewText: {
    show: boolean
    content: string
  }
  
  constructor(@Inject(MAT_DIALOG_DATA)
              public data: {
    empfanger: string
    betreff: string
    nachricht: string
    options: Array<{
      label: string
      mail: string
    }>
    tokens: Array<{
      name: string
      label: string
    }>
    meldung: Schadenmeldung
    cloud: Array<UbgNextcloudFile>
    attachments: Array<{
      id: string
      name: string
      selected: boolean
    }>
  }, private contenta: ContentaService) {
    this.nachrichtFocused = false;
    this.textblockLooked = false;
    this.tokenLooked = false;
    this.previewText = {
      show: false,
      content: '',
    };
    this.data.attachments = [];
  }
  
  ngOnInit() {
    console.error('Form Dialog Mail');
//    this.textblockService = new TextblockService(this.contenta);
//    this.textblockService.getAll().then((response: Array<Textblock>) => {
//      this.textbloecke = response;
//    });
//    this.data.attachments = [];
//    for (let i in this.data.meldung.dokumente) {
//      this.contenta.get('api/file/file/' + this.data.meldung.dokumente[i]).then((response: {
//      data: {
//        id: string
//        attributes: {
//          filename: string
//        }
//      }
//    }) => {
//      let attachmentData: {
//        id: string
//        name: string
//        selected: boolean
//      } = {
//        id: response.data.id,
//        name: response.data.attributes.filename,
//        selected: false,
//      };
//      for (let j in this.data.cloud) {
//        if (this.data.cloud[j].id == response.data.id) {
//          if (this.data.cloud[j].contentType !== null) {
//            attachmentData.selected = true;
//          }
//        }
//      }
//      this.data.attachments.push(attachmentData);
//    }).catch(() => {});
//    }
  };
  
  formHasErrors() {
    if (this.data.empfanger === '' || this.data.betreff === '' || this.data.nachricht === '') {
      return true;
    }
    return false;
  }
  
  lookForTextblock() {
    this.textblockLooked = true;
    this.textblockSearchString = '';
    this.nachrichtFocused = true;
  }
  
  getFilteredTextblockOptions() {
    let options = [];
    for (let i in this.textbloecke) {
      if (this.textbloecke[i].attributes.name.toLowerCase().indexOf(this.textblockSearchString.toLowerCase()) >= 0) {
        options.push(this.textbloecke[i].attributes.name);
      }
    }
    return options;
  }
  
  insertTextblock() {
    this.data.nachricht += '[textblock:' + this.textblockSearchString + ']';
    this.textblockLooked = false;
    this.textblockSearchString = '';
  }
  
  lookForToken() {
    this.tokenLooked = true;
    this.tokenSearchString = '';
  }
  
  getFilteredTokenOptions() {
    let options = [];
    for (let i in this.data.tokens) {
      if (this.data.tokens[i].name.toLowerCase().indexOf(this.tokenSearchString.toLowerCase()) >= 0) {
        options.push(this.data.tokens[i].name);
      }
    }
    return options;
  }
  
  insertToken() {
    for (let i in this.data.tokens) {
      if (this.data.tokens[i].name.toLowerCase() == this.tokenSearchString.toLowerCase()) {
        this.data.nachricht += '[notiz:' + i + ']';
        break;
      }
    }
    this.tokenLooked = false;
  }
  
  //    [textblock:Lorem Ipsum] [notiz:schadensnummer]
  
  preview() {
    if (this.previewText.show) {
      this.contenta.post('ubg/api/notiz/tokens/replace', {
        text: this.data.nachricht,
        meldung: this.data.meldung.id,
        textblock: 1,
      }).then((response: string) => {
        this.previewText.content = response;
      });
    }
    else {
      this.previewText.content = '';
    }
  }
  
}