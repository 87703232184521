/**
 * Floating Action Button
 * Attila Németh, UBG
 * 07.09.2018
 */
 
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ubg-fab',
  templateUrl: './ubg_fab.html',
  styleUrls: ['./ubg_fab.scss']
})
export class UbgFabComponent implements OnInit {
  
  @Input() label: string
  @Input() icon: string
  
  ngOnInit() {
    
  }
  
}
