/**
 * Datei-Card-Icon Component
 * Attila Németh, UBG
 * 31.10.2018
 */
 
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import {UbgFile} from '../../model/file';
import {ContentaService} from '../../../../services/contenta.service';

@Component({
  selector: 'ubg-file-card-icon',
  templateUrl: './card_icon.component.html',
  styleUrls: ['./card_icon.component.scss']
})
export class UbgFileCardIconComponent implements OnInit {
  
  @Input() fileId: string
  @Output() download = new EventEmitter()
  
  file: UbgFile
  fileThumbailBase64: string = null
  
  constructor(private contenta: ContentaService) {
    this.file = new UbgFile();
  }
  
  ngOnInit() {
    this.contenta.get('api/file/file/' + this.fileId).then((response: {
      data: UbgFile
    }) => {
      this.file = new UbgFile();
      this.file.id = response.data.id;
      this.file.attributes = response.data.attributes;
      this.file.links = response.data.links;
      this.file.loaded = true;
      const thumbnailUrl = this.file.thumbnail();
      if (thumbnailUrl !== null) {
        this.contenta.getBase64Image(thumbnailUrl).then((response: string) => {
          this.fileThumbailBase64 = response;
        }).catch(() => {
          this.fileThumbailBase64 = null;
        });
      }
    }).catch(() => {
      this.file = new UbgFile();
    });
  }
  
  open() {
    const url = this.contenta.baseUrl + this.file.attributes.uri.url;
    window.open(url);
  }
  
  downloadFile() {
    this.download.emit(this.file.attributes.filename);
    
//    this.contenta.getFileByPath(this.file.attributes.uri.url).then((response: Blob) => {
//      let fileName = this.file.attributes.filename;
//      const a = document.createElement('a');
//      document.body.appendChild(a);
//      const url = window.URL.createObjectURL(response);
//      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
//        window.navigator.msSaveOrOpenBlob(response, fileName);
//      } else {
//        a.href = url;
//        a.download = fileName;
//        a.click();
//      }
//    });
  }
  
//  fileThumbnail(): string {
//    if (this.fileThumbailBase64 === null) {
//      const thumbnailUrl = this.file.thumbnail();
//      this.contenta.getFile(thumbnailUrl).then((response) => {
//        this.fileThumbailBase64 = 'HEHE';
//        console.debug(response);
//      });
//    }
//    else {
//      return this.fileThumbailBase64;
//    }
//  }
  
}