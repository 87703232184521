/**
 * Versicherungsvertrag hinzufügen
 * Attila Németh, UBG
 * 10.09.2018
 */
 
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {MatSnackBar} from '@angular/material';

import {Versicherungsvertrag} from '../model/vvertrag';

import {VersicherungsfirmaService} from '../services/vfirma.service';
import {VersicherungsvertragService} from '../services/vvertrag.service';
import {ContentaService} from '../services/contenta.service';

@Component({
  selector: 'app-versicherungsvertrag-add',
  templateUrl: './form.html',
})
export class VersicherungsVertragAddComponent implements OnInit {

  vertrag: Versicherungsvertrag
  operation: string
  options: {
    sparte: Array<{
      id: string
      name: string
    }>
    vfirma: Array<{
      id: string
      name: string
    }>
  }
  
  constructor(private router: Router,
              private snackbar: MatSnackBar,
              private vfirma: VersicherungsfirmaService,
              private vvertrag: VersicherungsvertragService,
              private contenta: ContentaService) {
    this.operation = 'create';
    this.vertrag = {
      id: '',
      number: '',
      sparte: '',
      versicherungsgesellschaft: '',
      datum: {
        beginn: null,
        ende: null
      }
    };
    this.options = {
      sparte: [],
      vfirma: []
    };
  }
  
  ngOnInit() {
    this.loadOptionsSparte();
    this.loadOptionsVFirma();
  }
  
  loadOptionsSparte() {
    this.options.sparte = [];
    this.contenta.getJsonApi('taxonomy_term/sparte').then((response: Array<{
      id: string,
      attributes: {
        name: string
      }
    }>) => {
      for (let i in response) {
        this.options.sparte.push({
          id: response[i].id,
          name: response[i].attributes.name
        });
      }
    }).catch(() => {
      setTimeout(() => {
        this.loadOptionsSparte();
      }, 100)
    });
  }
  
  loadOptionsVFirma() {
    this.options.vfirma = [];
    this.vfirma.getAll().then((response: Array<{
      id: string
      attributes: {
        name: string
      }
    }>) => {
      for (let i in response) {
        this.options.vfirma.push({
          id: response[i].id,
          name: response[i].attributes.name
        });
      }
    }).catch(() => {
      setTimeout(() => {
        this.loadOptionsVFirma();
      }, 100);
    });
  }
  
  save() {
    this.vvertrag.create(this.vertrag).then(() => {
      this.snackbar.open('Der Vertrag wurde hinzugefügt', '', {
        duration: 3000
      });
      this.router.navigate(['versicherung/vertrag']);
    }).catch(() => {
      this.snackbar.open('Der Vertrag kann nicht hinzugefügt werden', '', {
        duration: 5000
      });
    });
  }

}