/**
 * Dashboard - Übersicht
 * Attila Németh, UBG
 * 29.10.2021
 */
 
 import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SchadenmeldungService } from 'src/app/services/meldung.service';

 @Component({
   selector: 'dashboard-overview',
   templateUrl: './dashboard-overview.component.html',
   styleUrls: ['./dashboard-overview.component.scss'],
 })
 export class DashboardOverviewComponent implements OnInit {

  @Output() change: EventEmitter<string> = new EventEmitter
  stat: any = null
  isLoading: boolean = false
   
  constructor(private meldungService: SchadenmeldungService) {}
   
  ngOnInit(): void {
    this.loadStat();
  }

  loadStat(): void {
    this.isLoading = true;
    this.meldungService.getMeldungStat().then(stat => {
      this.stat = stat;
      this.isLoading = false;
    });
  }

  filterCategory(category: string): void {
    this.change.emit(category);
  }
   
 }