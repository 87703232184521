/**
 * Editor-Komponent
 * 
 * Attila Németh, UBG
 * 04.07.2019
 * 
 * @see https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/frameworks/angular.html
 */
 
import {Component, Input, forwardRef, OnInit, ViewChild } from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {Textblock} from '../../model/textblock';
import {TextblockService} from '../../services/textblock.service';
import {ContentaService} from '../../services/contenta.service';

const noop = () => {};

@Component({
  selector: 'ubg-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UbgEditorComponent),
    multi: true
  }]
})
export class UbgEditorComponent implements ControlValueAccessor, OnInit {
  
  editorConfig = {
    minHeight: 5,
    toolbar: [
      ['bold', 'italic', 'underline', 'strikeThrough', 'superscript', 'subscript'],
      ['fontName', 'fontSize', 'color'],
      ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent'],
      ['cut', 'copy', 'delete', 'removeFormat', 'undo', 'redo'],
      ['paragraph', 'blockquote', 'removeBlockquote', 'horizontalLine', 'orderedList', 'unorderedList'],
      ['link', 'unlink', /*'image', 'video'*/]
    ],
  }
  
  @Input() set placeholder(placeholder: string) {
    this.ckeConfig.placeholder = placeholder;
  }
  @Input() tokens: Array<{
    name: string
    label: string
  }> = []
  @Input() meldungId: string
  text: string = ''
  focused: boolean = false
  textblockLooked: boolean = false
  textblockSearchString: string = ''
  textbloecke: Array<Textblock>
  textblockService: TextblockService
  tokenLooked: boolean = false
  tokenSearchString: string = ''
  
  ckEditor = ClassicEditor;
  ckeConfig = {
    language: 'de',
    placeholder: '',
    toolbar: ['heading', '|', 'bold', 'italic', '|', 'link', 'bulletedList', 'numberedList'],
    heading: {
        options: [
          {
            model: 'paragraph',
            title: 'Normal Text',
          },
          {
            model: 'address',
            title: 'Adresse',
            view: {
              name: 'p',
              classes: 'ubg-notiz-brief-address',
            },
          }
        ]
    }
  };
  @ViewChild('myckeditor') myckeditor:any;
  ckEditorPos: any
  
  constructor(private contenta: ContentaService) {
//    setInterval(() => {
//      console.debug(this.tokens);
//    }, 1000);
  }
  
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;
  
  ngOnInit() {
    this.textblockService = new TextblockService(this.contenta);
    this.textblockService.getAll().then((response: Array<Textblock>) => {
      this.textbloecke = response;
    });
  }

  get value(): string {
    return this.text;
  };

  set value(v: string) {
    if (v !== this.text) {
      this.text = v;
      this.onChangeCallback(v); 
    }
  }

  onBlur() {
    this.onTouchedCallback();
  }
  
  setFocus() {
    this.focused = true;
  }

  writeValue(value: any) {
    if (value !== this.text) {
      this.text = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  
  updateChanges() {
    this.onChangeCallback(this.text);
  }
  
  lookForTextblock() {
    this.tokenLooked = false;   
    this.textblockLooked = true;
    this.textblockSearchString = '';
  }
  
  lookForToken() {
    this.textblockLooked = false;
    this.tokenLooked = true;
    this.tokenSearchString = '';
  }
  
  getFilteredTextblockOptions() {
    let options = [];
    for (let i in this.textbloecke) {
      if (this.textbloecke[i].attributes.name.toLowerCase().indexOf(this.textblockSearchString.toLowerCase()) >= 0) {
        options.push(this.textbloecke[i].attributes.name);
      }
    }
    return options;
  }
  
  getFilteredTokenOptions() {
    let options = [];
    for (let i in this.tokens) {
      if (this.tokens[i].name.toLowerCase().indexOf(this.tokenSearchString.toLowerCase()) >= 0
        || this.tokens[i].label.toLowerCase().indexOf(this.tokenSearchString.toLowerCase()) >= 0) {
        options.push(this.tokens[i]);
      }
    }
    return options;
  }
  
  insertTextblock() {
    const textblockString: string = '[textblock:' + this.textblockSearchString + ']';
    this.textblockService.getReplacement(textblockString, this.meldungId).then((response: string) => {
      this.text += response;
      this.updateChanges();
    }).catch((error) => {
      console.error(error);
    });
    this.textblockLooked = false;
    this.textblockSearchString = '';
  }
  
  insertToken() {
    for (let i in this.tokens) {
      if (this.tokens[i].name.toLowerCase() == this.tokenSearchString.toLowerCase()) {
        const tokenString: string = '[notiz:' + this.tokens[i].name + ']';
        this.textblockService.getReplacement(tokenString, this.meldungId).then((response: string) => {
          this.insertContent(response.replace('[%%%]', location.origin));
          this.updateChanges();
        }).catch((error) => {
          console.error(error);
        });
        break;
      }
    }
    this.tokenLooked = false;
  }
  
  insertContent(content: string) {
    const viewFragment = this.myckeditor.editorInstance.data.processor.toView(content);
    const modelFragment = this.myckeditor.editorInstance.data.toModel( viewFragment );
    this.myckeditor.editorInstance.model.insertContent( modelFragment, this.myckeditor.editorInstance.model.document.selection );
  }
  
  setBlur(event: any) {
    this.ckEditorPos = this.myckeditor.editorInstance.model.document.selection.getFirstPosition();
  }

}
