/**
 * Notiz
 * Attila Németh, UBG
 * 26.09.2018
 */
 
export class Notiz {
  
  id: string
  type: string
  attributes: {
    title: string
    wiedervorlage?: string
    wv_erledigt?: string
    nachricht?: {
      value: string
    }
    text?: {
      value: string
    }
    template?: string
  }
  relationships: {
    datei?: {
      data: Array<{
        id: string
      }>
    }
    anlagen?: {
      data: Array<{
        id: string
      }>
    }
  }

  
//  id: string
//  title: string
//  type: string
//  datum: any
//  beschreibung?: string
//  relationships?: {
//    datei?: {
//      data: Array<{
//        id: string
//        type: string
//      }>
//    }
//    uid: {
//      data: {
//        id: string
//      }
//    }
//    anlagen?: {
//      data: Array<{
//        id: string
//        type: string
//      }>
//    }
//  }
//  empfanger?: string
//  betreff?: string
//  nachricht?: string
//  wiedervorlage: any                  // Datum
//  wv_erledigt: any
//  template?: string
//  text?: string
  
}