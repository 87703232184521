/**
 * Neue Notiz hinzufügen
 * 
 * Attila Németh, UBG
 * 14.04.2020
 */
 
import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';

import {NotizDialogData} from '../../aktionen.component';
import {ContentaService} from '../../../../../../../services/contenta.service';
import {NotizService} from '../../../../../../../services/notiz.service';

interface AttachmentFile {
  type: string
  id: string
  selected: boolean
}
 
@Component({
  templateUrl: 'add.component.html',
  styleUrls: ['add.component.scss'],
})
export class NotizDialogAddComponent {
  
  editorConfig = {
    minHeight: 5,
    toolbar: [
      ['bold', 'italic', 'underline'],
      ['cut', 'copy', 'delete'],
      ['undo', 'redo'],
    ],
  }
  uploadUrl: string
  httpAuthorization: string
  emailOptions: Array<{
    mail: string
    label: string
  }> = []
  absenderOptions: Array<string> = []
  tokenOptions: Array<{
    name: string
    label: string
  }> = []
  attachmentOptions: Array<AttachmentFile> = []

  constructor(private contenta: ContentaService,
    public dialogRef: MatDialogRef<NotizDialogAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NotizDialogData,
    private notizService: NotizService) {
  }
  
  ngOnInit() {
    this.uploadUrl = this.contenta.getUploadPath('ubg_notiz', 'ubg_notiz', 'datei');
    this.getToken();
    this.updateEmailOptions();
    this.updateTokenOptions();
    this.updateAttachmentOptions();
    if (this.data.notiz.type == 'ubg_notiz--e_mail' && this.data.notiz.attributes.nachricht === undefined) {
      this.data.notiz.attributes.nachricht = {
        value: '',
      };
      this.updateAbsenderOptions();
    }
    if (this.data.notiz.type == 'ubg_notiz--brief' && this.data.notiz.attributes.text === undefined) {
      this.data.notiz.attributes.text = {
        value: '',
      };
      this.updateAbsenderOptions();
    }
  }
  
  updateAttachmentOptions(): void {
    let newAttachmentOptions: Array<AttachmentFile> = [];
    for (let i in this.data.meldung.relationships.dokumente.data) {
      newAttachmentOptions.push({
        type: 'file--file',
        id: this.data.meldung.relationships.dokumente.data[i].id,
        selected: false,
      });
    }
    let counter: number = newAttachmentOptions.length;
    for (let i in newAttachmentOptions) {
      this.contenta.get('api/file/file/' + newAttachmentOptions[i].id).then((response: {
        data: AttachmentFile
      }) => {
        for (let j in newAttachmentOptions) {
          if (newAttachmentOptions[j].id == response.data.id) {
            response.data.selected = false;
            newAttachmentOptions[j] = response.data;
            counter--;
            if (counter <= 0) {
              this.attachmentOptions = newAttachmentOptions;
            }
          }
        }
      });
    }
  }
  
  updateTokenOptions(): void {
    this.notizService.getTokens().then((response: Array<{
      name: string
      label: string
    }>) => {
      this.tokenOptions = response;
    });
  }
  
  getToken() {
    this.contenta.getToken().then((response) => {
      this.httpAuthorization = 'Bearer ' + response;
      setTimeout(() => {
        this.getToken();
      }, 15000);
    }).catch((error) => {
      console.error(error);
      setTimeout(() => {
        this.getToken();
      }, 60000);
    });
  }
  
  filesUploaded(event: Array<File>) {
    let newFiles: Array<{
      id: string
      type: string
    }> = [];
    for (let i in event) {
      if (event[i]['uploaded'] !== undefined) {
        newFiles.push({
          type: 'file--file',
          id: event[i]['uploaded']['uuid'][0]['value'],
        });
      }
    }
    this.data.notiz.relationships = {
      datei: {
        data: newFiles,
      }
    };
  }
  
  updateEmailOptions(): void {
    let newOptions: Array<{
      mail: string
      label: string
    }> = [] 
    if (this.data.meldung.attributes.anspruchsteller_e_mail !== null
      && this.data.meldung.attributes.anspruchsteller_e_mail !== undefined
      && this.data.meldung.attributes.anspruchsteller_e_mail.length > 0) {
      newOptions.push({
        mail: this.data.meldung.attributes.anspruchsteller_e_mail[0],
        label: 'Anspruchsteller',
      });
    }
    if (this.data.meldung.attributes.e_mail !== null
      && this.data.meldung.attributes.e_mail.length > 0) {
      newOptions.push({
        mail: this.data.meldung.attributes.e_mail[0],
        label: this.data.meldung.attributes.gliederung,
      });
    }
    if (this.data.meldung.attributes.vg_e_mail !== null
      && this.data.meldung.attributes.vg_e_mail !== '') {
      newOptions.push({
        mail: this.data.meldung.attributes.vg_e_mail,
        label: 'Versicherung',
      });
    }
    this.emailOptions = newOptions;
  }
  
  updateAbsenderOptions(): void {
    this.notizService.getAbsender().then((response: Array<string>) => {
      this.absenderOptions = response;
    });
  }
  
  attachmentChanged(event: MatCheckboxChange, attachment: AttachmentFile): void {
    let newAttachments: Array<AttachmentFile> = [];
    for (let i in this.attachmentOptions) {
      if (this.attachmentOptions[i].selected) {
        newAttachments.push(this.attachmentOptions[i]);
      }
    }
    if (this.data.notiz.relationships === undefined) {
      this.data.notiz.relationships = {
        anlagen: {
          data: [],
        }
      };
    }
    this.data.notiz.relationships.anlagen.data = newAttachments;
  }
  
  getNotizPdf() {
    this.notizService.getBriefPdf(this.data.notiz, this.data.meldung.id).then((response: Blob) => {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(response);
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(response, 'vorschau.pdf');
      } 
      else {
        a.href = url;
        a.download = 'vorschau.pdf';
        a.click();
      }
    });
  }

}