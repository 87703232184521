/**
 * Notizendialog
 * Attila Németh, UBG
 * 26.09.2018
 */
 
import { Component, Inject, OnInit } from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

import {ContentaService} from '../../services/contenta.service';

@Component({
  selector: 'ubg-dialog-form-datei',
  templateUrl: './form_dialog_datei.html',
})
export class UbgFormDialogDateiComponent implements OnInit {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string
    files: any
  },
  private contenta: ContentaService) {
  }
  
  uploadUrl: string
  httpAuthorization: string
  
  ngOnInit() {
    this.uploadUrl = this.contenta.getUploadPath('ubg_notiz', 'ubg_notiz', 'datei');
    this.getToken();
  }
  
  getToken() {
    this.contenta.getToken().then((response) => {
      this.httpAuthorization = 'Bearer ' + response;
      setTimeout(() => {
        this.getToken();
      }, 15000);
    }).catch((error) => {
      console.error(error);
      setTimeout(() => {
        this.getToken();
      }, 60000);
    });
  }
  
  disabled() {
    if (this.data.title == '' || this.data.files === null || this.data.files === undefined) {
      return true;
    }
    else {
      return false;
    }
  }
  
  completed(e:any) {
    this.data.files = e;
  }
  
}